/*
   Counteracts the Video JS CSS used by other sites.

   Should be included into the top of PitchHub's Video CSS, so that the this set will neutralize non-pitchhub CSS.

   A lot of the "all:initial" statements are removed because they cause other problems even in cases where there is no conflicing
   CSS for VideoJS
*/

/* all: initial; */
.video-js-holder {
}

/* all: initial; */
.video-js-holder video {
}

/* all: initial; */
.video-js {
}

.video-js .vjs-slider {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-slider {
        background-image: initial;
        background-size: initial;
    }
}

/* all: initial; */
.video-js .vjs-slider:focus {
}

/* all: initial; */
.video-js .vjs-slider-handle {
}

/* all: initial; */
.video-js .vjs-progress-control:hover .vjs-slider-handle,.video-js .vjs-volume-control:hover .vjs-slider-handle {
}

/* all: initial; */
.video-js .vjs-control-bar {
}

/* all: initial; */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-control-bar {
    }
}

/* all: initial; */
.video-js.vjs-has-started .vjs-control-bar {
}

/* all: initial; */
.video-js.vjs-has-started:hover .vjs-control-bar {
}

/* all: initial; */
.video-js .vjs-control-bar {
}

/* all: initial; */
.video-js.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
}

/* all: initial; */
.video-js.vjs-controls-disabled .vjs-control-bar {
}

.video-js.vjs-using-native-controls .vjs-control-bar {
    /* all: initial; */
}

@media \0screen {
    .video-js.vjs-user-inactive.vjs-playing .vjs-control-bar :before {
        content: "";
    }
}

/* all: initial; */
.video-js .vjs-control {
}

/* all: initial; */
.video-js .vjs-control:before {
}

/* all: initial; */
.video-js .vjs-control:focus:before,.video-js .vjs-control:hover:before {
}

/* all: initial; */
.video-js .vjs-control-text {
}

.video-js .vjs-play-control {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-play-control {
        background-image: initial;
        background-size: initial;
    }
}

.video-js.vjs-playing .vjs-play-control {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js.vjs-playing .vjs-play-control {
        background-image: initial;
        background-size: initial;
    }
}

/* all: initial; */
.video-js .vjs-mute-control,.video-js .vjs-volume-menu-button {
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-mute-control,.video-js .vjs-volume-menu-button {
        background-image: initial;
        background-size: initial;
    }
}

.video-js .vjs-mute-control.vjs-vol-0,.video-js .vjs-volume-menu-button.vjs-vol-0 {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-mute-control.vjs-vol-0,.video-js .vjs-volume-menu-button.vjs-vol-0 {
        background-image: initial;
        background-size: initial;
    }
}

/* all: initial; */
.video-js .vjs-volume-control {
}

/* all: initial; */
.video-js .vjs-volume-bar {
}

/* all: initial; */
.video-js .vjs-volume-menu-button .vjs-menu-content {
}

.video-js .vjs-volume-level {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-volume-level {
        background-image: initial;
        background-size: initial;
    }
}

/* all: initial; */
.video-js .vjs-volume-handle:before {
}

/* all: initial; */
.video-js .vjs-volume-control:hover .vjs-slider-handle {
}

/* all: initial; */
.video-js .vjs-volume-menu-button .vjs-menu .vjs-menu-content {
}

.video-js .vjs-download-video {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-download-video {
        background-image: initial;
        background-size: initial;
    }
}

.video-js .vjs-progress-control {
    /* all: initial; */
    position: initial;
    left: initial;
    right: initial;
    width: initial;
    font-size: initial;
    height: initial;
    top: initial;
}

/* all: initial; */
.video-js .vjs-progress-holder {
}

.video-js .vjs-progress-holder .vjs-play-progress,.video-js .vjs-progress-holder .vjs-load-progress {
    background-image: initial;
    background-size: initial;
}

.video-js .vjs-play-progress {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-play-progress {
        background-image: initial;
        background-size: initial;
    }
}

.video-js .vjs-load-progress {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-load-progress {
        background-image: initial;
        background-size: initial;
    }
}

/* all: initial; */
.video-js .vjs-seek-handle {
}

/* all: initial; */
.video-js .vjs-seek-handle:before {
}

/* all: initial; */
.video-js .vjs-time-controls,.video-js .vjs-time-divider {
}

/* all: initial; */
.video-js .vjs-time-controls {
}

/* all: initial; */
.video-js .vjs-current-time {
}

/* all: initial; */
.video-js .vjs-duration {
}

/* all: initial; */
.video-js .vjs-remaining-time {
    display: inline;
}

/* all: initial; */
.vjs-time-divider {
}

.vjs-current-time-holder {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .vjs-current-time-holder {
        background-image: initial;
        background-size: initial;
    }
}

/* all: initial; */
.vjs-seek-handle:hover .vjs-current-time-holder {
}

/* all: initial; */
.vjs-current-time-value {
}

.video-js .vjs-fullscreen-control {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-fullscreen-control {
        background-image: initial;
        background-size: initial;
    }
}

.video-js.vjs-fullscreen video {
    background-color: black;
}

.video-js.vjs-fullscreen .vjs-fullscreen-control {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js.vjs-fullscreen .vjs-fullscreen-control {
        background-image: initial;
        background-size: initial;
    }
}

.video-js .vjs-big-play-button {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-big-play-button {
        background-image: initial;
        background-size: initial;
    }
}

.video-js .vjs-big-play-button.video-error {
    background-image: initial;
    background-size: initial;
}

/* all: initial; */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .video-js .vjs-big-play-button.video-error {
    }
}

.video-js .vjs-big-play-button:hover {
    background-image: initial;
    background-size: initial;
}

/* all: initial; */
.video-js.vjs-controls-disabled .vjs-big-play-button {
}

/* all: initial; */
.video-js.vjs-has-started .vjs-big-play-button {
}

.video-js.vjs-using-native-controls .vjs-big-play-button {
    all: initial;
}

.vjs-loading-spinner {
    background-image: initial;
    background-size: initial;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .vjs-loading-spinner {
        background-image: initial;
        background-size: initial;
    }
}

.video-js .vjs-menu-button {
    background-image: initial;
    background-size: initial;
}

/* all: initial; */
.video-js .vjs-menu {
}

/* all: initial; */
.video-js .vjs-menu-button .vjs-menu .vjs-menu-content {
}

/* all: initial; */
.video-js .vjs-menu-button:hover .vjs-menu {
}

/* all: initial; */
.video-js .vjs-menu-button ul li {
}

/* all: initial; */
.video-js .vjs-menu-button ul li.vjs-selected {
}

/* all: initial; */
.video-js .vjs-menu-button ul li:focus,.video-js .vjs-menu-button ul li:hover,.video-js .vjs-menu-button ul li.vjs-selected:focus,.video-js .vjs-menu-button ul li.vjs-selected:hover {
}

/* all: initial; */
.video-js .vjs-menu-button ul li.vjs-menu-title {
}

/* all: initial; */
.video-js .vjs-subtitles-button:before {
}

/* all: initial; */
.video-js .vjs-captions-button:before {
}

/* all: initial; */
.video-js .vjs-captions-button:focus .vjs-control-content:before,.video-js .vjs-captions-button:hover .vjs-control-content:before {
}

/* all: initial; */
.video-js {
}

/* all: initial; */
.video-js .vjs-tech {
}

/* all: initial; */
.video-js:-moz-full-screen {
}

/* all: initial; */
body.vjs-full-window {
}

/* all: initial; */
.video-js.vjs-fullscreen {
    position: absolute;
}

/* all: initial; */
.video-js:-webkit-full-screen {
}

/* all: initial; */
.video-js.vjs-fullscreen.vjs-user-inactive {
}

/* all: initial; */
.vjs-poster {
}

/* all: initial; */
.vjs-poster img {
}

.video-js.vjs-using-native-controls .vjs-poster {
    all: initial;
}

/* all: initial; */
.video-js .vjs-text-track-display {
}

/* all: initial; */
.video-js .vjs-text-track {
}

/* all: initial; */
.video-js .vjs-subtitles {
}

/* all: initial; */
.video-js .vjs-captions {
}

/* all: initial; */
.vjs-tt-cue {
}

/* all: initial; */
.video-js .vjs-hidden {
}

/* all: initial; */
.vjs-lock-showing {
}