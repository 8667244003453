.navbar-dropdown {
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.45s ease;
  z-index: 1030;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1); }
  .navbar-dropdown .navbar-brand {
    float: none;
    font-size: 0;
    padding: 1.25rem 0;
    position: relative;
    transition: padding 0.25s ease;
    white-space: nowrap; }
    .navbar-dropdown .navbar-brand::before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle; }
  .navbar-dropdown .navbar-logo,
  .navbar-dropdown .navbar-caption {
    display: inline-block;
    vertical-align: middle; }
  .navbar-dropdown .navbar-logo {
    margin-right: 0.8rem;
    transition: margin 0.3s ease-in-out; }
    .navbar-dropdown .navbar-logo img {
      height: 3.125rem;
      transition: all 0.3s ease-in-out; }
  .navbar-dropdown .mbr-table-cell {
    height: 5.625rem; }
  @media (max-width: 767px) {
    .navbar-dropdown .navbar-buttons {
      display: none; } }
  .navbar-dropdown .navbar-caption {
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 700;
    white-space: normal; }
    .navbar-dropdown .navbar-caption, .navbar-dropdown .navbar-caption:hover {
      color: inherit;
      text-decoration: none; }
  .navbar-dropdown.navbar-fixed-top {
    position: fixed; }
  .navbar-dropdown.bg-color.transparent {
    background: none !important;
    box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.1); }
  .navbar-dropdown.navbar-short {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); }
    .navbar-dropdown.navbar-short .navbar-brand {
      padding: 0.625rem 0; }
    .navbar-dropdown.navbar-short .navbar-logo {
      margin-right: 0.5rem; }
      .navbar-dropdown.navbar-short .navbar-logo img {
        height: 2.375rem; }
    .navbar-dropdown.navbar-short .mbr-table-cell {
      height: 3.625rem; }
    .navbar-dropdown.navbar-short .nav-dropdown .dropdown-menu {
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); }
  .navbar-dropdown .navbar-close {
    right: 0.825rem;
    position: fixed;
    top: 1.55rem;
    z-index: 1000; }
  .navbar-dropdown.opened {
    background: none !important;
    box-shadow: none;
    border: none; }
    .navbar-dropdown.opened .navbar-brand,
    .navbar-dropdown.opened .navbar-toggler {
      display: none; }
    .navbar-dropdown.opened .nav-dropdown {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); }
    .navbar-dropdown.opened .nav-dropdown .dropdown-menu {
      box-shadow: 0 8px 15px transparent; }
  .navbar-dropdown .hamburger-icon {
    content: "";
    width: 16px;
    -webkit-box-shadow: 0 -6px 0 1px,0 0 0 1px,0 6px 0 1px;
    -moz-box-shadow: 0 -6px 0 1px,0 0 0 1px,0 6px 0 1px;
    box-shadow: 0 -6px 0 1px,0 0 0 1px,0 6px 0 1px; }
  .navbar-dropdown .close-icon {
    position: relative;
    width: 21px;
    height: 21px;
    overflow: hidden; }
    .navbar-dropdown .close-icon::before, .navbar-dropdown .close-icon::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px; }
    .navbar-dropdown .close-icon::before {
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg); }
    .navbar-dropdown .close-icon::after {
      transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg); }

.dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem; }

.dropdown-menu .dropdown-item:focus {
  outline: 0; }

.nav-dropdown {
  display: table !important;
  font-family: "Open Sans";
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 2px;
  height: auto !important; }
  .nav-dropdown .nav-item {
    display: table-cell;
    float: none;
    vertical-align: middle;
    position: relative;
    white-space: nowrap; }
  .nav-dropdown .nav-btn {
    padding-left: 1rem; }
  .nav-dropdown .link {
    margin: 1.667em 2em;
    padding: 0;
    transition: color .2s ease-in-out; }
    .nav-dropdown .link.dropdown-toggle {
      margin-right: 2.583em; }
      .nav-dropdown .link.dropdown-toggle::after {
        margin-left: .25rem;
        border-top: 0.35em solid;
        border-right: 0.35em solid transparent;
        border-left: 0.35em solid transparent;
        border-bottom: 0; }
      .nav-dropdown .link.dropdown-toggle::after {
        display: block;
        margin-top: -0.1667em;
        position: absolute;
        right: 1.3333em;
        top: 50%; }
      .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
        margin: 0;
        padding: 1.667em 2.583em 1.667em 1.667em; }
  .nav-dropdown .link::after,
  .nav-dropdown .dropdown-item::after {
    color: inherit; }
  .nav-dropdown .btn {
    font-size: 0.85rem;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 0;
    padding-left: 1.625rem;
    padding-right: 1.625rem; }
  .nav-dropdown .dropdown-menu {
    border-radius: 0;
    border: 0;
    right: auto;
    left: 0;
    margin: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    box-shadow: 0px 10px 30px -11px rgba(0, 0, 0, 0.3);
    transition: all .45s; }
  .nav-dropdown .dropdown-submenu {
    left: 100%;
    right: auto;
    margin-left: 0.0rem;
    margin-top: -1.25rem;
    top: 0; }
  .nav-dropdown .dropdown-item {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 2;
    text-align: left;
    padding: 0.3846em 2.615em 0.3846em 1.5385em;
    position: relative;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    .nav-dropdown .dropdown-item::before {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 20px;
      height: 2px;
      width: 0;
      transition: all .2s ease-in-out; }
    .nav-dropdown .dropdown-item::after {
      margin-top: -0.3077em;
      position: absolute;
      right: 1.1538em;
      top: 50%; }
    .nav-dropdown .dropdown-item:focus, .nav-dropdown .dropdown-item:hover {
      background: none; }
      .nav-dropdown .dropdown-item:focus::before, .nav-dropdown .dropdown-item:hover::before {
        width: calc(100% - 40px); }

@media (max-width: 767px) {
  .nav-dropdown.navbar-toggleable-sm {
    bottom: 0;
    display: none;
    right: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    transform: translateX(100%);
    -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    width: 18.75rem;
    z-index: 999; } }
.nav-dropdown.navbar-toggleable-xl {
  bottom: 0;
  display: none;
  right: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  -ms-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  width: 18.75rem;
  z-index: 999; }

.nav-dropdown-sm {
  display: block !important;
  overflow-x: hidden;
  overflow: auto;
  padding-top: 3.875rem; }
  .nav-dropdown-sm::after {
    content: "";
    display: block;
    height: 3rem;
    width: 100%; }
  .nav-dropdown-sm.collapse.in ~ .navbar-close {
    display: block !important; }
  .nav-dropdown-sm.collapsing, .nav-dropdown-sm.collapse.in {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out; }
  .nav-dropdown-sm.collapsing[aria-expanded="false"] {
    transform: translateX(100%);
    -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%); }
  .nav-dropdown-sm .nav-item {
    display: block;
    margin-left: 0 !important;
    padding-left: 0; }
  .nav-dropdown-sm .link,
  .nav-dropdown-sm .dropdown-item {
    border-top: 1px dotted rgba(255, 255, 255, 0.1);
    font-size: 0.8125rem;
    line-height: 1.6;
    text-align: left;
    margin: 0 !important;
    padding: 0.875rem 2.4rem 0.875rem 1.5625rem !important;
    position: relative;
    white-space: normal; }
    .nav-dropdown-sm .link::before,
    .nav-dropdown-sm .dropdown-item::before {
      right: auto;
      left: 0;
      bottom: 0; }
    .nav-dropdown-sm .link::after,
    .nav-dropdown-sm .dropdown-item::after {
      left: auto;
      right: 1.1538em; }
    .nav-dropdown-sm .link:focus, .nav-dropdown-sm .link:hover,
    .nav-dropdown-sm .dropdown-item:focus,
    .nav-dropdown-sm .dropdown-item:hover {
      background: rgba(0, 0, 0, 0.2) !important; }
      .nav-dropdown-sm .link:focus::before, .nav-dropdown-sm .link:hover::before,
      .nav-dropdown-sm .dropdown-item:focus::before,
      .nav-dropdown-sm .dropdown-item:hover::before {
        width: 100%; }
  .nav-dropdown-sm .nav-btn {
    position: relative;
    padding: 1.5625rem 1.5625rem 0 1.5625rem; }
    .nav-dropdown-sm .nav-btn::before {
      border-top: 1px dotted rgba(255, 255, 255, 0.1);
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .nav-dropdown-sm .nav-btn + .nav-btn {
      padding-top: 0.625rem; }
      .nav-dropdown-sm .nav-btn + .nav-btn::before {
        display: none; }
  .nav-dropdown-sm .btn {
    padding: 0.625rem 0; }
  .nav-dropdown-sm .dropdown-toggle::after {
    position: absolute;
    right: 1.25rem;
    top: 50%;
    margin-top: -0.154em; }
  .nav-dropdown-sm .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0; }
  .nav-dropdown-sm .dropdown-toggle[data-toggle="dropdown-submenu"][aria-expanded="true"]::after {
    border-top: 0;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0.35em solid; }
  .nav-dropdown-sm .dropdown-menu {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    float: none;
    border-radius: 0;
    background: none; }

.is-builder .nav-dropdown.collapsing {
  transition: none !important; }

/*# sourceMappingURL=style.css.map */
