/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* Alternate Bootstrap styles, created PitchHub, for cases where bootstrap styles are needed for Video Menu, but bootstrap conflicts with the context styles */
.btn-ph {
    display: inline-block;
    font: inherit;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    /* Restated, from Bootstrap */
    margin: 2px;
    touch-action: manipulation;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    box-sizing: inherit;
    align-items: flex-start;
    text-rendering: auto;
    letter-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
}

.btn-ph:hover, .btn-ph:focus {
    text-decoration: none;
}

.btn-ph:focus, .btn-ph.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-ph.disabled, .btn-ph:disabled {
    opacity: 0.65;
}

.btn-ph:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn-ph:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
}

a.btn-ph.disabled,
fieldset:disabled a.btn-ph {
    pointer-events: none;
}

.btn-ph-primary {
    color: #fff;

    /*  Added by PH */
    background-color: var(--ph-blue);
    border-color: var(--ph-blue);

}

.btn-ph-primary:focus, .btn-ph-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-ph-primary.disabled, .btn-ph-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-ph-primary:not(:disabled):not(.disabled):active, .btn-ph-primary:not(:disabled):not(.disabled).active,
.show-ph > .btn-ph-primary.dropdown-toggle {
    color: #fff;
    background-color: (--ph-green);
    border-color: (--ph-green);
}

.btn-ph-primary:not(:disabled):not(.disabled):active:focus, .btn-ph-primary:not(:disabled):not(.disabled).active:focus,
.show-ph > .btn-ph-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-ph-secondary {
    /* Added by ph*/
    background-color: var(--ph-blue);
    border-color: var(--ph-blue);
    color: #ffffff;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13px;
    margin: 2px;
    text-indent: 0;

}

.btn-ph-secondary:hover, .btn-ph-primary:hover {
    /* Added by ph*/
    color: #fff;
    background-color: var(--ph-green);
    border-color: var(--ph-green);
    box-shadow: none; }

.btn-ph-secondary:focus, .btn-ph-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);

    /* Added by ph*/
    color: #fff;
    background-color: var(--ph-green);
    border-color: var(--ph-green);
}
.btn-ph-secondary:active {
    /* Added by ph*/
    color: #fff;
    background-color: var(--ph-green);
    border-color: var(--ph-green);
    background-image: none;
    box-shadow: none;

}

.btn-ph-secondary.disabled, .btn-ph-secondary:disabled {
    /* Added by ph*/
    color: #fff !important;
    background-color: #94ada8 !important;
    border-color: #94ada8 !important;
    opacity: 0.85; }



.btn-ph-secondary:not(:disabled):not(.disabled):active, .btn-ph-secondary:not(:disabled):not(.disabled).active,
.show-ph > .btn-ph-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.btn-ph-secondary:not(:disabled):not(.disabled):active:focus, .btn-ph-secondary:not(:disabled):not(.disabled).active:focus,
.show-ph > .btn-ph-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade-ph {
    opacity: 0;
    transition: opacity 0.15s linear;
}

.fade-ph.show-ph {
    opacity: 1;
}

.collapsing-ph {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.1rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    vertical-align: top;
}

.list-group-item:hover {
    background: #eee;
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.close-ph {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close-ph:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.close-ph:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button.close-ph {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

.modal-open-ph {
    overflow: hidden;
}

.modal-ph {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10001;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-open-ph .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog-ph {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal-ph.fade-ph .modal-dialog-ph {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.modal-ph.show-ph .modal-dialog-ph {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-dialog-centered-ph {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

.modal-content-ph {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop-ph {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop-ph.fade-ph {
    opacity: 0;
}

.modal-backdrop-ph.show-ph {
    opacity: 0.5;
}

.modal-header-ph {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-header-ph .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title-ph {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body-ph {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer-ph {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.modal-footer-ph > :not(:first-child) {
    margin-left: .25rem;
}

.modal-footer-ph > :not(:last-child) {
    margin-right: .25rem;
}

.modal-scrollbar-measure-ph {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog-ph {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-centered-ph {
        min-height: calc(100% - (1.75rem * 2));
    }
    .modal-sm-ph {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg-ph {
        max-width: 800px;
    }
}
