/* Constants */
:root{
    --ph-blue: #0A96B8;
    --ph-green: #8AB21D;
    --ph-gray: #615E66;
    --ph-table-border: #808080; /* border for tables */
    --ph-form-edit-background: #d8d8e0;
    --ph-form-show-background: #f8f8f8;

    --ph-player-controls-base: #0A96B8;
    --ph-player-controls-accent: #3Ac6e8;
    --ph-player-button-background-active: #0A96B8;
    --ph-player-button-background-inactive: slategray;
    --ph-player-button-foreground: white;
}
