/*!
 * DirectM theme (https://mobirise.com/)
 * Copyright 2016 Mobirise
 */
.clear {
  clear: both; }

.container,
.container-fluid {
  position: relative; }

.container a,
.container-fluid a {
  outline: none; }

.btn {
  margin-bottom: 0.5rem; }
  /* -- disable button indent
  .btn + .btn {
    margin-left: 1rem; }
    */
  @media (max-width: 767px) {
    .btn {
      white-space: normal; }
      .btn + .btn {
        margin-left: 0; } }

.bg-primary {
  background-color: #c0a375 !important; }

.bg-success {
  background-color: #90a878 !important; }

.bg-info {
  background-color: #7e9b9f !important; }

.bg-warning {
  background-color: #f3c649 !important; }

.bg-danger {
  background-color: #f28281 !important; }

.bg-none {
  background: none !important; }

.btn {
  border-radius: 3px;
  font-weight: 500;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

.btn-sm {
  padding: 0.625rem 0.874rem;
  font-size: 0.97rem;
  line-height: 1.5;
  border-radius: 3px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0px; }

.btn-md {
  padding: 16px 32px;
  font-size: 0.99rem;
  line-height: 1.33333;
  border-radius: 6px; }

.btn-lg {
  padding: 21px 40px;
  font-size: 0.99rem;
  line-height: 1.33333;
  border-radius: 6px; }

.btn-primary {
  background-color: #c0a375;
  border-color: #c0a375;
  box-shadow: none;
  color: #fff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #a07e49;
    border-color: #a07e49;
    box-shadow: none; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #a07e49;
    border-color: #a07e49; }
  .btn-primary:active, .btn-primary.active {
    color: #fff;
    background-color: #a07e49;
    border-color: #a07e49;
    background-image: none;
    box-shadow: none; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff !important;
    background-color: #a07e49 !important;
    border-color: #a07e49 !important;
    opacity: 0.85; }

.btn-secondary {
  background-color: #bfcecb;
  border-color: #bfcecb;
  box-shadow: none;
  color: #fff; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #94ada8;
    border-color: #94ada8;
    box-shadow: none; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #94ada8;
    border-color: #94ada8; }
  .btn-secondary:active, .btn-secondary.active {
    color: #fff;
    background-color: #94ada8;
    border-color: #94ada8;
    background-image: none;
    box-shadow: none; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff !important;
    background-color: #94ada8 !important;
    border-color: #94ada8 !important;
    opacity: 0.85; }

.btn-info {
  background-color: #7e9b9f;
  border-color: #7e9b9f;
  box-shadow: none;
  color: #fff; }
  .btn-info:hover {
    color: #fff;
    background-color: #597478;
    border-color: #597478;
    box-shadow: none; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #597478;
    border-color: #597478; }
  .btn-info:active, .btn-info.active {
    color: #fff;
    background-color: #597478;
    border-color: #597478;
    background-image: none;
    box-shadow: none; }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff !important;
    background-color: #597478 !important;
    border-color: #597478 !important;
    opacity: 0.85; }

.btn-success {
  background-color: #90a878;
  border-color: #90a878;
  box-shadow: none;
  color: #fff; }
  .btn-success:hover {
    color: #fff;
    background-color: #6a8153;
    border-color: #6a8153;
    box-shadow: none; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #6a8153;
    border-color: #6a8153; }
  .btn-success:active, .btn-success.active {
    color: #fff;
    background-color: #6a8153;
    border-color: #6a8153;
    background-image: none;
    box-shadow: none; }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff !important;
    background-color: #6a8153 !important;
    border-color: #6a8153 !important;
    opacity: 0.85; }

.btn-warning {
  background-color: #f3c649;
  border-color: #f3c649;
  box-shadow: none;
  color: #fff; }
  .btn-warning:hover {
    color: #fff;
    background-color: #e1a90f;
    border-color: #e1a90f;
    box-shadow: none; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #e1a90f;
    border-color: #e1a90f; }
  .btn-warning:active, .btn-warning.active {
    color: #fff;
    background-color: #e1a90f;
    border-color: #e1a90f;
    background-image: none;
    box-shadow: none; }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff !important;
    background-color: #e1a90f !important;
    border-color: #e1a90f !important;
    opacity: 0.85; }

.btn-danger {
  background-color: #f28281;
  border-color: #f28281;
  box-shadow: none;
  color: #fff; }
  .btn-danger:hover {
    color: #fff;
    background-color: #eb3d3c;
    border-color: #eb3d3c;
    box-shadow: none; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #eb3d3c;
    border-color: #eb3d3c; }
  .btn-danger:active, .btn-danger.active {
    color: #fff;
    background-color: #eb3d3c;
    border-color: #eb3d3c;
    background-image: none;
    box-shadow: none; }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff !important;
    background-color: #eb3d3c !important;
    border-color: #eb3d3c !important;
    opacity: 0.85; }

.btn-white {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
  color: #535353; }
  .btn-white:hover {
    color: #fff;
    background-color: #d4d4d4;
    border-color: #d4d4d4;
    box-shadow: none; }
  .btn-white:focus, .btn-white.focus {
    color: #fff;
    background-color: #d4d4d4;
    border-color: #d4d4d4; }
  .btn-white:active, .btn-white.active {
    color: #fff;
    background-color: #d4d4d4;
    border-color: #d4d4d4;
    background-image: none;
    box-shadow: none; }
  .btn-white.disabled, .btn-white:disabled {
    color: #fff !important;
    background-color: #d4d4d4 !important;
    border-color: #d4d4d4 !important;
    opacity: 0.85; }

.btn-black {
  background-color: #535353;
  border-color: #535353;
  box-shadow: none;
  color: #fff; }
  .btn-black:hover {
    color: #fff;
    background-color: #2d2d2d;
    border-color: #2d2d2d;
    box-shadow: none; }
  .btn-black:focus, .btn-black.focus {
    color: #fff;
    background-color: #2d2d2d;
    border-color: #2d2d2d; }
  .btn-black:active, .btn-black.active {
    color: #fff;
    background-color: #2d2d2d;
    border-color: #2d2d2d;
    background-image: none;
    box-shadow: none; }
  .btn-black.disabled, .btn-black:disabled {
    color: #fff !important;
    background-color: #2d2d2d !important;
    border-color: #2d2d2d !important;
    opacity: 0.85; }

.btn-primary-outline {
  background: none;
  border-color: #8e7041;
  color: #8e7041; }
  .btn-primary-outline:focus, .btn-primary-outline.focus, .btn-primary-outline:active, .btn-primary-outline.active {
    color: #fff;
    background-color: #c0a375;
    border-color: #c0a375; }
  .btn-primary-outline:hover {
    color: #fff;
    background-color: #c0a375;
    border-color: #c0a375; }
  .btn-primary-outline.disabled, .btn-primary-outline:disabled {
    color: #fff !important;
    background-color: #c0a375 !important;
    border-color: #c0a375 !important;
    opacity: 0.85; }

.btn-secondary-outline {
  background: none;
  border-color: #85a29c;
  color: #85a29c; }
  .btn-secondary-outline:focus, .btn-secondary-outline.focus, .btn-secondary-outline:active, .btn-secondary-outline.active {
    color: #fff;
    background-color: #bfcecb;
    border-color: #bfcecb; }
  .btn-secondary-outline:hover {
    color: #fff;
    background-color: #bfcecb;
    border-color: #bfcecb; }
  .btn-secondary-outline.disabled, .btn-secondary-outline:disabled {
    color: #fff !important;
    background-color: #bfcecb !important;
    border-color: #bfcecb !important;
    opacity: 0.85; }

.btn-info-outline {
  background: none;
  border-color: #4e6669;
  color: #4e6669; }
  .btn-info-outline:focus, .btn-info-outline.focus, .btn-info-outline:active, .btn-info-outline.active {
    color: #fff;
    background-color: #7e9b9f;
    border-color: #7e9b9f; }
  .btn-info-outline:hover {
    color: #fff;
    background-color: #7e9b9f;
    border-color: #7e9b9f; }
  .btn-info-outline.disabled, .btn-info-outline:disabled {
    color: #fff !important;
    background-color: #7e9b9f !important;
    border-color: #7e9b9f !important;
    opacity: 0.85; }

.btn-success-outline {
  background: none;
  border-color: #5d7149;
  color: #5d7149; }
  .btn-success-outline:focus, .btn-success-outline.focus, .btn-success-outline:active, .btn-success-outline.active {
    color: #fff;
    background-color: #90a878;
    border-color: #90a878; }
  .btn-success-outline:hover {
    color: #fff;
    background-color: #90a878;
    border-color: #90a878; }
  .btn-success-outline.disabled, .btn-success-outline:disabled {
    color: #fff !important;
    background-color: #90a878 !important;
    border-color: #90a878 !important;
    opacity: 0.85; }

.btn-warning-outline {
  background: none;
  border-color: #c9970d;
  color: #c9970d; }
  .btn-warning-outline:focus, .btn-warning-outline.focus, .btn-warning-outline:active, .btn-warning-outline.active {
    color: #fff;
    background-color: #f3c649;
    border-color: #f3c649; }
  .btn-warning-outline:hover {
    color: #fff;
    background-color: #f3c649;
    border-color: #f3c649; }
  .btn-warning-outline.disabled, .btn-warning-outline:disabled {
    color: #fff !important;
    background-color: #f3c649 !important;
    border-color: #f3c649 !important;
    opacity: 0.85; }

.btn-danger-outline {
  background: none;
  border-color: #e82625;
  color: #e82625; }
  .btn-danger-outline:focus, .btn-danger-outline.focus, .btn-danger-outline:active, .btn-danger-outline.active {
    color: #fff;
    background-color: #f28281;
    border-color: #f28281; }
  .btn-danger-outline:hover {
    color: #fff;
    background-color: #f28281;
    border-color: #f28281; }
  .btn-danger-outline.disabled, .btn-danger-outline:disabled {
    color: #fff !important;
    background-color: #f28281 !important;
    border-color: #f28281 !important;
    opacity: 0.85; }

.btn-white-outline {
  background: none;
  border-color: #fff;
  color: #fff; }
  .btn-white-outline:focus, .btn-white-outline.focus, .btn-white-outline:active, .btn-white-outline.active {
    color: #535353;
    background-color: #fff;
    border-color: #fff; }
  .btn-white-outline:hover {
    color: #535353;
    background-color: #fff;
    border-color: #fff; }
  .btn-white-outline.disabled, .btn-white-outline:disabled {
    color: #535353 !important;
    background-color: #fff !important;
    border-color: #fff !important;
    opacity: 0.85; }

.btn-black-outline {
  background: none;
  border-color: #202020;
  color: #202020; }
  .btn-black-outline:focus, .btn-black-outline.focus, .btn-black-outline:active, .btn-black-outline.active {
    color: #fff;
    background-color: #535353;
    border-color: #535353; }
  .btn-black-outline:hover {
    color: #fff;
    background-color: #535353;
    border-color: #535353; }
  .btn-black-outline.disabled, .btn-black-outline:disabled {
    color: #fff !important;
    background-color: #535353 !important;
    border-color: #535353 !important;
    opacity: 0.85; }

a.mbr-iconfont:hover {
  text-decoration: none; }

.btn-social {
  font-size: 20px;
  border-radius: 50%;
  padding: 0;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  position: relative;
  border: 1px solid #c0a375;
  color: #232323; }
  .btn-social i {
    top: 0;
    line-height: 46px;
    width: 46px; }
  .btn-social:hover {
    color: #fff;
    background: #c0a375; }
  .btn-social + .btn-social {
    margin-left: 1rem; }

p.lead,
.lead p {
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2.3125rem; }

.article .lead p, .article .lead ul, .article .lead ol, .article .lead pre, .article .lead blockquote {
  margin-bottom: 0; }

.lead {
  font-size: 1.25rem;
  font-weight: 400; }
  .lead a {
    font-family: "Lora";
    font-style: italic;
    font-weight: 400; }
    .lead a, .lead a:hover {
      color: #c0a375;
      text-decoration: none; }
  .lead h1 {
    font-size: 5.5rem;
    font-weight: 300;
    letter-spacing: -1px;
    margin-bottom: 1.875rem; }
  .lead h2 {
    font-size: 4.5rem;
    font-weight: 100;
    letter-spacing: -1px;
    margin-bottom: 1.875rem; }
  .lead h3 {
    font-size: 3.5rem;
    font-weight: 300;
    margin-bottom: 1.875rem; }
  .lead h4 {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 1.875rem; }
  .lead blockquote {
    font-family: "Lora";
    font-style: italic;
    padding: 10px 0 10px 20px;
    border-left: 4px solid #c39f76;
    font-size: 1.09rem;
    position: relative; }
  .lead ul, .lead ol, .lead pre, .lead blockquote {
    margin-bottom: 2.3125rem; }
  .lead pre {
    background: #f4f4f4;
    padding: 10px 24px;
    white-space: pre-wrap; }

.display-1 {
  font-weight: 100;
  line-height: 1.12em;
  letter-spacing: 0px; }

.display-2 {
  font-weight: 300;
  line-height: 1.17em;
  letter-spacing: 0px; }

.display-3 {
  font-weight: 100;
  line-height: 1.1em;
  letter-spacing: 0px; }

.sub-1 {
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 2px; }

.sub-2 {
  font-weight: 400;
  line-height: 1.5em; }

.text-1 {
  font-weight: 400;
  line-height: 1.7em; }

.text-2 {
  font-weight: 400;
  line-height: 1.7em; }

.modal-backdrop {
  background: -webkit-linear-gradient(left, #564740, #3a414a);
  background: -moz-linear-gradient(left, #564740, #3a414a);
  background: -o-linear-gradient(left, #564740, #3a414a);
  background: -ms-linear-gradient(left, #564740, #3a414a);
  background: linear-gradient(left, #564740, #3a414a);
  opacity: 0.94; }

.form-control {
  background-color: #f5f5f5;
  border-radius: 1.4rem;
  box-shadow: none;
  color: #565656;
  font-size: 0.875rem;
  line-height: 1.43;
  min-height: 3.5em;
  padding: 0.5em 1.07em 0.5em; }
  .form-control, .form-control:focus {
    border: 1px solid #e8e8e8; }
  .form-active .form-control:invalid {
    border-color: #f28281; }

.form-control-label {
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 0.357em;
  padding: 0; }

.alert {
  border-radius: 0;
  border: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  position: relative; }
  .alert.alert-form:after {
    background-color: inherit;
    bottom: -7px;
    content: "";
    display: block;
    height: 14px;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    transform: rotate(45deg);
    width: 14px; }

.alert-success {
  background-color: #90a878;
  color: #fff; }

.alert-info {
  background-color: #7e9b9f;
  color: #fff; }

.alert-warning {
  background-color: #f3c649;
  color: #fff; }

.alert-danger {
  background-color: #f28281;
  color: #fff; }

/*  Text Align */
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

/**
 * TYPOGRAPHY
 */
body {
  font-family: 'Raleway', sans-serif;
  color: #232323; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.display-1, .display-2, .display-3, .display-4 {
  font-family: 'Montserrat', sans-serif;
  word-break: break-word;
  word-wrap: break-word; }

input, textarea {
  font-family: "Raleway"; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition-delay: 9999s;
  transition-property: background-color, color; }

.text-primary {
  color: #c0a375 !important; }

.text-success {
  color: #90a878 !important; }

.text-info {
  color: #7e9b9f !important; }

.text-warning {
  color: #f3c649 !important; }

.text-danger {
  color: #f28281 !important; }

.text-white {
  color: #fff !important; }

.text-black {
  color: #535353 !important; }

.mbr-section {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover; }

.mbr-section-full {
  display: table;
  height: 100vh;
  padding-bottom: 0;
  padding-top: 0;
  table-layout: fixed;
  width: 100%; }
  .mbr-section-full > .mbr-table-cell {
    width: 100%; }

.mbr-section-small {
  padding-top: 60px;
  padding-bottom: 60px; }

.mbr-section-title {
  margin-top: 0; }

.mbr-section__container--first {
  padding-top: 120px;
  padding-bottom: 40px; }

.mbr-section__container--middle {
  padding-top: 0;
  padding-bottom: 40px; }

.mbr-section__container--last {
  padding-top: 0;
  padding-bottom: 120px; }

.mbr-section-sm-padding {
  padding-top: 40px;
  padding-bottom: 40px; }

.mbr-section-md-padding {
  padding-top: 90px;
  padding-bottom: 90px; }

.mbr-section-nopadding {
  padding-top: 0;
  padding-bottom: 0; }

.mbr-section-subtitle {
  display: block;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 30px; }

.mbr-section-hero::before {
  display: none !important; }

/* fonts */
.display-1 {
  font-size: 5rem; }

.display-2 {
  font-size: 3.8rem; }

.display-3 {
  font-size: 3.125rem; }

.sub-1 {
  font-size: 1.5rem; }

.sub-2 {
  font-size: 1.25rem; }

.text-1 {
  font-size: 1rem; }

.text-2 {
  font-size: 0.95rem; }

.btn {
  font-size: 1rem; }

@media (max-width: 1199px) {
  .display-1 {
    font-size: 4.75rem; }

  .display-2 {
    font-size: 3.5rem; }

  .display-3 {
    font-size: 2.625rem; }

  .sub-1 {
    font-size: 1.3rem; }

  .sub-2 {
    font-size: 1.25rem; }

  .text-1 {
    font-size: 1rem; }

  .text-2 {
    font-size: 0.95rem; }

  .btn {
    font-size: 0.99rem; } }
@media (max-width: 991px) {
  .display-1 {
    font-size: 3.75rem; }

  .display-2 {
    font-size: 3.125rem; }

  .display-3 {
    font-size: 2.625rem; }

  .sub-1 {
    font-size: 1.25rem; }

  .sub-2 {
    font-size: 1.2rem; }

  .text-1 {
    font-size: 1rem; }

  .text-2 {
    font-size: 0.875rem; }

  .btn {
    font-size: 0.99rem; } }
@media (max-width: 767px) {
  .display-1 {
    font-size: 3.3rem; }

  .display-2 {
    font-size: 2.625rem; }

  .display-3 {
    font-size: 1.875rem; }

  .sub-1 {
    font-size: 1.1rem; }

  .sub-2 {
    font-size: 1.1rem; }

  .text-1 {
    font-size: 0.97rem; }

  .text-2 {
    font-size: 0.875rem; }

  .btn {
    font-size: 0.97rem; } }
@media (max-width: 543px) {
  .display-1 {
    font-size: 3rem !important; }

  .display-2 {
    font-size: 2.625rem !important; }

  .display-3 {
    font-size: 1.875rem !important; }

  .sub-1 {
    font-size: 1.1rem !important; }

  .sub-2 {
    font-size: 1.1rem !important; }

  .text-1 {
    font-size: 0.97rem !important; }

  .text-2 {
    font-size: 0.875rem !important; }

  .btn {
    font-size: 0.97rem; } }
/* end-fonts */
@media (max-width: 767px) {
  .mbr-section {
    padding-top: 60px;
    padding-bottom: 60px; }

  .mbr-section-full {
    padding-bottom: 0;
    padding-top: 0; }

  .mbr-section__container--first {
    padding-top: 60px;
    padding-bottom: 40px; }

  .mbr-section__container--middle {
    padding-top: 0;
    padding-bottom: 40px; }

  .mbr-section__container--last {
    padding-top: 0;
    padding-bottom: 60px; }

  .mbr-section-sm-padding {
    padding-top: 40px;
    padding-bottom: 40px; }

  .mbr-section-md-padding {
    padding-top: 60px;
    padding-bottom: 60px; }

  .mbr-section-nopadding {
    padding-top: 0;
    padding-bottom: 0; }

  .mbr-section-subtitle {
    margin-bottom: 0; }

  .mbr-section-hero.mbr-after-navbar .mbr-section {
    padding-top: 120px; }
  .mbr-section-hero.mbr-section-with-arrow .mbr-section {
    padding-bottom: 120px; } }
@media (max-width: 768px) {
  .ui-sortable .app-component:first-child .mbr-section-full {
    padding-top: 90px; }

  .mbr-section-full {
    padding-top: 20px;
    padding-bottom: 20px; } }
/*-------

   Arrow

-------*/
@-webkit-keyframes floating-arrow {
  from {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); }
  65% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  to {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); } }
@keyframes floating-arrow {
  from {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); }
  65% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  to {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); } }
@-webkit-keyframes floating-arrow-up {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  25% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  75% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); } }
@keyframes floating-arrow-up {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  25% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  75% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); } }
.mbr-arrow {
  bottom: 28px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }
  @media (max-width: 991px) {
    .mbr-arrow {
      bottom: 31px; } }
  @media (max-width: 320px) {
    .mbr-arrow {
      bottom: 21px; } }
  @media all and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
    .mbr-arrow {
      bottom: 31px; } }
  .mbr-arrow a {
    background: transparent;
    border-radius: 1.4rem;
    color: #fff;
    display: inline-block;
    height: 46px;
    outline-style: none !important;
    position: relative;
    text-decoration: none;
    transition: all .3s ease-in-out;
    width: 46px; }
  .mbr-arrow i {
    display: block;
    transition: all .3s; }
    .mbr-arrow i::before {
      display: inline-block;
      font-size: 40px;
      line-height: 1;
      font-style: normal;
      position: relative;
      top: 6px; }
  .mbr-arrow-floating i {
    -webkit-animation: floating-arrow 2.6s infinite ease-in-out 0s;
    animation: floating-arrow 2.6s infinite ease-in-out 0s; }

.mbr-arrow-up {
  bottom: 25px;
  right: 90px;
  position: fixed;
  text-align: right;
  z-index: 5000; }
  @media (max-width: 991px) {
    .mbr-arrow-up {
      bottom: 31px; } }
  @media (max-width: 320px) {
    .mbr-arrow-up {
      bottom: 21px; } }
  @media all and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
    .mbr-arrow-up {
      bottom: 31px; } }
  .mbr-arrow-up a {
    background-color: rgba(130, 130, 130, 0.2);
    border-radius: 50% !important;
    border: 1px solid #fff;
    border-radius: 1.4rem;
    color: #fff;
    display: inline-block;
    height: 46px;
    outline-style: none !important;
    position: relative;
    text-decoration: none;
    transition: all .3s ease-in-out;
    width: 46px;
    cursor: pointer; }
    .mbr-arrow-up a:hover {
      background-color: rgba(130, 130, 130, 0.5); }
  .mbr-arrow-up-icon {
    display: block; }
    .mbr-arrow-up-icon::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-left: 2px solid #fff;
      border-top: 2px solid #fff;
      top: 15px;
      left: 17px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg); }
    .mbr-arrow-up-icon::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 15px;
      top: 15px;
      left: 21px;
      background-color: #fff; }

@media (max-width: 768px) {
  .mbr-arrow-up {
    right: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    bottom: 15px; } }
@media (min-width: 768px) {
  .mbr-right-padding-md-up {
    padding-right: 85px; } }

.mbr-valign-top {
  vertical-align: top; }

.form-asterisk {
  font-family: initial;
  position: absolute;
  top: -2px;
  font-weight: normal; }

.row-sm-offset {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  .row-sm-offset > * {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }

@-webkit-keyframes btnCircleLoading {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }
@keyframes btnCircleLoading {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
.btn-loader {
  -webkit-animation: btnCircleLoading .6s infinite linear;
  animation: btnCircleLoading .6s infinite linear;
  border-radius: 50%;
  border-top-color: white;
  display: none;
  left: 50%;
  position: absolute;
  top: 50%; }

.btn-loading {
  position: relative; }
  .btn-loading .btn-text {
    visibility: hidden; }
  .btn-loading .btn-loader {
    display: block; }

.hidden {
  visibility: hidden; }

.visible {
  visibility: visible; }

.hamburger-om:after {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: 8px;
  left: 0; }
.hamburger-om:before {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  top: -8px;
  left: 0; }

/*-------

   Iconfont

-------*/
/* iconfont default styling */
/* for buttons */
.mbr-iconfont.mbr-iconfont-btn,
.mbr-buttons__btn .mbr-iconfont {
  padding-right: 0.4em;
  font-size: 1.6em;
  line-height: 0.5em;
  vertical-align: text-bottom;
  position: relative;
  top: -0.12em;
  text-decoration: none; }

/* menu links */
.mbr-iconfont.mbr-iconfont-btn-parent,
.link .mbr-iconfont {
  font-size: 1.4em;
  position: relative;
  vertical-align: middle; }

/*menu logo */
.mbr-iconfont.mbr-iconfont-menu,
.mbr-iconfont.mbr-iconfont-ext__menu {
  font-size: 37px;
  text-decoration: none;
  color: #fff; }

/* features2, features3 */
.mbr-iconfont.mbr-iconfont-features2,
.mbr-iconfont.mbr-iconfont-features3 {
  font-size: 119px;
  text-decoration: none;
  text-align: center;
  display: block;
  margin-top: 0.2em;
  color: #F1C050; }

/* features4 */
.mbr-iconfont.mbr-iconfont-features4 {
  font-size: 50px;
  text-decoration: none;
  text-align: center;
  display: block;
  color: #000; }

.mbr-iconfont-features7 {
  font-size: 50px;
  text-decoration: none;
  text-align: center;
  display: block;
  color: #fff; }

/* msg-box4 */
.mbr-iconfont.mbr-iconfont-msg-box4,
.mbr-iconfont.mbr-iconfont-msg-box5 {
  font-size: 357px;
  text-decoration: none;
  color: #232323; }

/* header2 */
.mbr-iconfont.mbr-iconfont-header2 {
  font-size: 400px;
  text-decoration: none;
  color: #fff; }

/* contacts1 */
.mbr-iconfont.mbr-iconfont-contacts1 {
  font-size: 119px;
  text-decoration: none;
  color: #9C9C9C; }

/* contacts3 */
.mbr-iconfont.mbr-iconfont-logo-contacts3 {
  font-size: 58px;
  text-decoration: none;
  color: #9C9C9C;
  margin-bottom: 1.57rem; }

.mbr-iconfont-company-contacts3 {
  font-size: 22px;
  color: #cfcfcf; }

.mbr-iconfont-company-contacts5 {
  font-size: 22px;
  color: #000; }

.mbr-section-full .mbr-overlay {
  min-height: 100vh; }

.mbr-overlay {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1; }
  .mbr-overlay ~ * {
    z-index: 2; }

.mbr-table {
  display: table;
  width: 100%; }

.mbr-table-full {
  height: 100%;
  position: relative;
  width: 100%; }

.mbr-table-cell {
  display: table-cell;
  float: none;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  vertical-align: middle; }

.mbr-table-md-up {
  width: 100%; }
  .mbr-table-md-up .mbr-table-cell {
    display: block; }
  .mbr-table-md-up .mbr-table-cell + .mbr-table-cell {
    padding-top: 40px; }
  @media (min-width: 768px) {
    .mbr-table-md-up {
      display: table; }
      .mbr-table-md-up .mbr-table-cell {
        display: table-cell; }
      .mbr-table-md-up .mbr-table-cell + .mbr-table-cell {
        padding-top: 0; } }

.mbr-section-full .mbr-background-video,
.mbr-section-full .mbr-background-video-preview {
  min-height: 100vh; }

.mbr-background-video,
.mbr-background-video-preview {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.mbr-background-video-preview {
  z-index: 0 !important; }

.mbr-figure {
  display: block;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .mbr-figure img, .mbr-figure iframe {
    display: block;
    width: 100%; }
  .mbr-figure .mbr-figure-caption {
    font-family: Montserrat;
    padding: 1.5rem 40px;
    text-align: center;
    width: 100%; }
  .mbr-figure .mbr-figure-caption-over {
    background: -moz-linear-gradient(left, rgba(67, 76, 99, 0.85), rgba(188, 155, 114, 0.85)) !important;
    background: -ms-linear-gradient(left, rgba(67, 76, 99, 0.85), rgba(188, 155, 114, 0.85)) !important;
    background: -o-linear-gradient(left, rgba(67, 76, 99, 0.85), rgba(188, 155, 114, 0.85)) !important;
    background: -webkit-linear-gradient(left, rgba(67, 76, 99, 0.85), rgba(188, 155, 114, 0.85)) !important;
    background: linear-gradient(left, rgba(67, 76, 99, 0.85), rgba(188, 155, 114, 0.85)) !important;
    bottom: 0;
    position: absolute; }

.menu1 .navbar-dropdown .mbr-table-cell {
  transition: height 0.3s; }
.menu1 .navbar-dropdown.navbar-short .navbar-logo img {
  height: 2.375rem !important; }
.menu1 .logo {
  width: 100%; }
  @media (min-width: 768px) {
    .menu1 .logo {
      width: 20%; } }
.menu1 .navbar-nav {
  width: auto; }
  @media (max-width: 767px) {
    .menu1 .navbar-nav {
      max-width: 300px;
      width: 300px; } }
  .menu1 .navbar-nav.show-buttons {
    width: 100%; }
    @media (max-width: 767px) {
      .menu1 .navbar-nav.show-buttons {
        max-width: 300px; } }
.menu1 .navbar-brand {
  padding: 0.25rem 0; }
.menu1 .nav-btn {
  width: 100%; }
  .menu1 .nav-btn .btn {
    width: auto;
    float: right;
    font-family: 'Libre Franklin', serif;
    letter-spacing: 1px;
    font-size: 13px;
    text-transform: uppercase;
    padding: 10px 30px; }
    @media (max-width: 767px) {
      .menu1 .nav-btn .btn {
        width: 80%;
        float: none;
        margin: 0 auto 20px; } }
.menu1 .navbar-dropdown .navbar-caption {
  font-family: "Oxygen";
  color: #333; }
.menu1 .nav-link {
  font-size: 13px; }
.menu1 .navbar-logo img {
  height: 4.125rem; }
.menu1 .menu-substrate {
  display: block;
  position: relative;
  height: 90px;
  width: 100%;
  background: transparent;
  z-index: -1;
  transition: all .3s; }
.menu1 .navbar.navbar-short + .menu-substrate {
  height: 60px; }

@media (min-width: 900px) {
  .menu1 .container-fluid {
    padding-right: 35px;
    padding-left: 35px; } }
.navbar-toggler.collapsed .hum-top, .hum-middle, .hum-bottom {
  position: absolute;
  content: "";
  right: 0;
  width: 20px;
  height: 3px; }

.navbar-toggler.collapsed .hum-top {
  top: -1px; }

.navbar-toggler.collapsed .hum-middle {
  top: 6px; }

.navbar-toggler.collapsed .hum-bottom {
  bottom: 0; }

.navbar-close .close-icon {
  display: block; }

.navbar-close.collapsed .close-icon {
  display: none; }

.navbar-toggler {
  position: relative; }

.header1 .mbr-table-cell {
  vertical-align: bottom; }
.header1 .heading {
  margin-bottom: 120px;
  margin-top: 20px; }
  @media (max-width: 1199px) {
    .header1 .heading {
      margin-bottom: 60px; } }
  .header1 .heading .mbr-section-title {
    color: #fff;
    margin-bottom: 0.5em; }
  .header1 .heading .mbr-section-subtitle {
    color: #e4e5e7;
    margin-bottom: 3em; }
.header1 .card {
  border: none;
  background: transparent;
  margin-bottom: 80px; }
  .header1 .card-box {
    padding-right: 0; }
  .header1 .card-img {
    text-align: center;
    padding: 0; }
    .header1 .card-img .mbr-iconfont {
      line-height: 66px;
      width: 70px;
      height: 70px;
      border: 2px solid #fff;
      border-radius: 70px;
      display: inline-block; }
  .header1 .card .mbr-section-title,
  .header1 .card .mbr-section-subtitle {
    color: #fff;
    margin-bottom: .5em;
    margin-top: 0; }
  .header1 .card .mbr-section-text {
    color: #e4e5e7;
    margin-bottom: .5em;
    margin-top: 0; }
.header1.header2 .heading .mbr-section-subtitle,
.header1.header2 .heading .mbr-section-title,
.header1.header2 .heading .mbr-section-text {
  color: #222; }
.header1.header2 .card .mbr-section-subtitle,
.header1.header2 .card .mbr-section-title,
.header1.header2 .card .mbr-section-text {
  color: #222; }
.header1.header2 .mbr-arrow a i {
  color: #fff !important; }

.header3 {
  background-size: cover !important; }
  .header3 .mbr-arrow a {
    color: #666666; }
  .header3 .mbr-section-title {
    margin-bottom: 0.5em; }
  .header3 .mbr-section-subtitle {
    margin-bottom: 3em; }

@media (min-width: 768px) {
  .header3 .mbr-right-padding-md-up {
    padding-right: 40px; } }
@media (max-width: 768px) {
  .header3 .image-size {
    width: 100% !important;
    padding: 15px !important; }
  .header3 .mbr-figure img {
    width: auto;
    max-width: 100%;
    margin: auto; } }
.header4 p {
  color: #fff;
  margin-top: 0 !important; }
.header4 .mbr-section-title {
  color: #fff;
  margin-bottom: 0.5em; }
  @media (max-width: 1199px) {
    .header4 .mbr-section-title {
      margin-bottom: 1rem; } }
.header4 .mbr-section-subtitle {
  color: #fff;
  margin-bottom: 3em; }
  @media (max-width: 1199px) {
    .header4 .mbr-section-subtitle {
      margin-bottom: 1em; } }

.header5 p {
  margin-top: 0 !important; }
.header5 .heading-text {
  margin-bottom: 50px !important; }
.header5 h1 {
  margin-bottom: 40px !important; }

.header6 .container {
  color: #fff; }
.header6 .mbr-arrow a {
  color: #fff; }
.header6 p {
  margin-bottom: 1.8rem; }
.header6 a.btn {
  margin-bottom: 1rem; }
.header6 .col-md-5 {
  width: auto; }

.header7 .container {
  color: #fff; }

.header8 .mbr-section-title {
  color: #fff;
  text-align: center; }
.header8 .mbr-section-subtitle {
  color: #fff;
  text-align: center; }
.header8 .image-size {
  margin: auto; }
.header8 .mbr-figure {
  margin-bottom: 3rem;
  margin-top: 2rem; }
.header8 h3 {
  margin-bottom: 1rem; }
.header8 a.btn {
  margin-bottom: 2rem; }

@media (max-width: 768px) {
  .header8 .mbr-figure {
    margin-bottom: 1.5rem; } }
@media (max-width: 450px) {
  .header8 .mbr-figure {
    width: 100% !important; } }
.header10 {
  overflow: hidden;
  position: relative; }
  .header10 .mbr-section-title,
  .header10 .mbr-section-subtitle,
  .header10 .mbr-section-text {
    color: #fff; }
  .header10 .bg-icon {
    z-index: 0;
    position: absolute;
    top: 50%;
    right: 50%;
    margin-top: -20vh;
    margin-right: -20vh; }
    @media (max-width: 991px) {
      .header10 .bg-icon {
        margin-top: 0;
        top: 10%; } }
    @media (max-width: 767px) {
      .header10 .bg-icon {
        right: 0; } }
    .header10 .bg-icon .mbr-iconfont {
      font-size: 40vh;
      font-weight: 100;
      color: #c8c8c8;
      opacity: .2; }
  .header10 .heading {
    margin-bottom: 1rem; }
    .header10 .heading-title, .header10 .heading-text {
      margin-bottom: 1em; }

.msg-box1 .mbr-section-subtitle {
  font-size: 25px;
  font-family: "Oxygen"; }
.msg-box1 ul {
  padding-left: 20px;
  list-style: none; }
  .msg-box1 ul li:before {
    content: '';
    display: block;
    position: absolute;
    margin-top: 7px;
    left: 10px;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background: #ffdd2d; }
.msg-box1 .span-title {
  margin-bottom: 25px; }
.msg-box1 .list {
  margin-bottom: 50px; }
.msg-box1 .round-block {
  width: 266px;
  height: 266px;
  position: absolute;
  bottom: 0;
  text-align: center;
  z-index: 1;
  border-radius: 50%;
  display: table;
  transition: box-shadow 1s;
  cursor: default; }
  .msg-box1 .round-block:hover {
    box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 1s; }
.msg-box1 .round-text {
  font-size: 2rem;
  line-height: 1.5em;
  color: #ffffff; }
.msg-box1 h1 {
  margin-bottom: 40px; }
.msg-box1 .wraper {
  display: table-cell;
  vertical-align: middle; }

/* MEDIA */
@media (max-width: 991px) {
  .msg-box1 .round-block {
    width: 200px;
    height: 200px; }
    .msg-box1 .round-block p {
      font-size: 1.5rem; } }
@media (max-width: 400px) {
  .msg-box1 .round-block {
    width: 100px;
    height: 100px; }
    .msg-box1 .round-block p {
      font-size: 1rem; } }
@media (max-width: 768px) {
  .msg-box1 ul li:before {
    left: 15px; } }
.msg-box2 .mbr-figure a {
  font-size: 200px; }
.msg-box2 .main-container {
  width: 55%;
  display: inline-block;
  padding: 50px; }
  .msg-box2 .main-container h1, .msg-box2 .main-container .icons-block {
    margin-bottom: 1.2rem; }
  .msg-box2 .main-container .icons-item {
    text-align: center; }
  .msg-box2 .main-container .card-img {
    margin-bottom: 1rem;
    height: 70px;
    border-width: 2px;
    border-style: solid;
    width: 70px;
    text-align: center;
    display: inline-block;
    border-radius: 50%; }
    .msg-box2 .main-container .card-img span {
      font-size: 30px;
      text-decoration: none;
      text-align: center;
      display: block;
      line-height: 66px; }
    .msg-box2 .main-container .card-img a {
      font-size: 35px;
      text-decoration: none;
      text-align: center;
      display: block;
      line-height: 66px; }
.msg-box2 .wraper {
  border-radius: 6px;
  background-size: cover;
  margin-left: 15px;
  margin-right: 15px; }

/* MEDIA */
@media (max-width: 991px) {
  .msg-box2 .main-container {
    width: 70%; } }
@media (max-width: 768px) {
  .msg-box2 .main-container {
    width: 100%; }
  .msg-box2 .icons-item {
    margin-bottom: 20px; } }
@media (max-width: 500px) {
  .msg-box2 .main-container {
    width: 100% !important; } }
@media (max-width: 400px) {
  .msg-box2 .main-container {
    padding: 25px; } }
.msg-box3 .content {
  width: auto; }
.msg-box3 .mbr-figure {
  display: table-cell; }
  .msg-box3 .mbr-figure a {
    font-size: 200px; }
.msg-box3 .wraper {
  padding: 40px;
  border-radius: 6px;
  display: inline-block;
  width: 100%;
  position: relative; }
.msg-box3 .row {
  text-align: center; }
.msg-box3 .content {
  padding-left: 25px; }
  .msg-box3 .content h1, .msg-box3 .content .icons-block {
    margin-bottom: 1.2rem; }
  .msg-box3 .content .icons-item {
    text-align: center; }
  .msg-box3 .content .card-img {
    margin-bottom: 1rem;
    height: 70px;
    border-width: 2px;
    border-style: solid;
    width: 70px;
    text-align: center;
    display: inline-block;
    border-radius: 50%; }
    .msg-box3 .content .card-img span {
      font-size: 30px;
      text-decoration: none;
      text-align: center;
      display: block;
      line-height: 66px; }
    .msg-box3 .content .card-img a {
      font-size: 35px;
      text-decoration: none;
      text-align: center;
      display: block;
      line-height: 66px; }

/* MEDIA */
@media (max-width: 1200px) {
  .msg-box3 .icons-item {
    width: 100%; } }
@media (max-width: 768px) {
  .msg-box3 .col-md-6 {
    width: 100%;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px; }
  .msg-box3 .mbr-figure {
    width: 100% !important; } }
@media (max-width: 600px) {
  .msg-box3 .wraper {
    padding: 0px !important;
    margin-left: 15px;
    margin-right: 15px;
    width: auto !important; } }
.msg-box4 .content {
  width: auto; }
.msg-box4 h1, .msg-box4 p, .msg-box4 a {
  transition: color 0.26s; }
.msg-box4 .card-img {
  transition: border-color 0.26s; }
.msg-box4 .gradient-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: red;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: 6px;
  transition: opacity 0.3s; }
  .msg-box4 .gradient-overlay:hover {
    opacity: 1;
    transition: opacity 0.3s; }
.msg-box4 .wraper:hover .gradient-overlay {
  opacity: 1; }
.msg-box4 .wraper:hover .content h1, .msg-box4 .wraper:hover .content p, .msg-box4 .wraper:hover .content a {
  color: #fff !important;
  transition: color 0.26s; }
.msg-box4 .wraper:hover .content .card-img {
  border-color: #fff !important;
  transition: border-color 0.26s; }

.features1 .container {
  overflow: hidden; }
.features1 .container-slide .mbr-section-title,
.features1 .container-slide .mbr-section-text,
.features1 .container-slide .mbr-section-btn {
  width: 100%; }
.features1 .container-slide .media {
  width: 100%;
  height: 50vh;
  margin-top: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; }
.features1 .container-slide img {
  width: 100%; }
.features1 .carousel-control {
  background: transparent;
  width: 40px;
  height: 40px;
  top: 40%; }
  .features1 .carousel-control.left {
    left: 0; }
  .features1 .carousel-control.right {
    right: 0; }
  .features1 .carousel-control .mbri-left,
  .features1 .carousel-control .mbri-right {
    font-size: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .3s;
    color: rgba(0, 0, 0, 0.4); }
  .features1 .carousel-control:hover .mbri-left,
  .features1 .carousel-control:hover .mbri-right {
    color: rgba(0, 0, 0, 0.9); }
  @media (max-width: 767px) {
    .features1 .carousel-control {
      display: none; } }
.features1 .carousel-indicators {
  display: block;
  position: relative;
  margin: 20px auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0; }
  .features1 .carousel-indicators li {
    margin: 5px;
    background: transparent;
    border: 1px solid #dddfe0;
    transition: all .3s;
    width: 10px;
    height: 10px; }

.features2 .mbr-section-btn {
  text-align: center; }
.features2 .heading-text {
  text-align: center; }
.features2 h1 {
  text-align: center; }
.features2.mbr-section {
  padding: 40px 0; }
.features2 .heading {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .features2 .heading {
      margin-bottom: 25px; } }
  .features2 .heading-title {
    font-weight: 100; }
.features2 .card {
  border-radius: 0;
  border: 1px solid #dddfe0;
  padding: 1rem;
  box-shadow: 3px 3px 3px -1px rgba(0, 0, 0, 0.21); }
  @media (max-width: 1199px) {
    .features2 .card {
      padding: .5rem; } }
  @media (max-width: 767px) {
    .features2 .card {
      padding: 1rem 0;
      margin: .5rem -.9rem; } }
  .features2 .card-title {
    font-weight: 600; }
  .features2 .card-img {
    text-align: left;
    padding: 1rem; }
    @media (max-width: 991px) {
      .features2 .card-img {
        text-align: center;
        padding: 0 .5rem; } }
    .features2 .card-img .mbr-iconfont {
      line-height: 68px;
      width: 70px;
      height: 70px;
      border: 1px solid #fff;
      border-radius: 70px;
      display: inline-block;
      text-align: center;
      transition: all .3s; }
  .features2 .card .mbr-section-title,
  .features2 .card .mbr-section-subtitle {
    margin-bottom: 0.8rem; }
  .features2 .card .mbr-section-text {
    margin-bottom: 1.3rem; }

.features3 h1 {
  margin-bottom: 0 !important; }
.features3 .container-fluid {
  max-width: 1290px; }
.features3.mbr-section {
  padding: 40px 0; }
.features3 .heading {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .features3 .heading {
      margin-bottom: 25px; } }
  .features3 .heading-title {
    margin-bottom: 20px; }
.features3 .features {
  border: 1px solid #eee;
  padding-top: 80px; }
  @media (max-width: 1199px) {
    .features3 .features {
      padding-top: 40px; } }
  @media (max-width: 767px) {
    .features3 .features {
      padding-top: 1em; } }
  .features3 .features-title {
    letter-spacing: 0;
    padding-bottom: 1em;
    border-bottom: 1px solid #eee; }
    @media (max-width: 1199px) {
      .features3 .features-title {
        margin-bottom: 1em; } }
.features3 .card {
  border: none;
  margin-bottom: 80px;
  background: transparent; }
  @media (max-width: 1199px) {
    .features3 .card {
      padding: .5rem;
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .features3 .card {
      padding: 0;
      margin: 0 -.9rem 20px; } }
  .features3 .card-img {
    text-align: right;
    padding: .5rem; }
    .features3 .card-img .mbr-iconfont {
      line-height: 68px;
      width: 70px;
      height: 70px;
      border: 2px solid #fff;
      border-radius: 70px;
      display: inline-block;
      text-align: center; }
  .features3 .card .card-text {
    margin-bottom: .5em;
    color: #999; }
  .features3 .card .card-box-inner {
    margin: .5rem 0;
    height: 80px;
    display: table-cell;
    vertical-align: middle; }
    .features3 .card .card-box-inner h4 {
      display: inline; }

@media (min-width: 901px) {
  .features3 .features {
    padding-right: 50px;
    padding-left: 50px; } }
.features5 .heading {
  margin-bottom: 1rem; }
  .features5 .heading-title, .features5 .heading-text {
    margin-bottom: 1em; }
.features5 .card {
  padding: 1.5rem .5rem;
  border-radius: 6px;
  background: transparent; }
  .features5 .card-img {
    width: 85px; }
    .features5 .card-img .mbr-iconfont {
      line-height: 81px;
      width: 85px;
      height: 85px;
      border: 2px solid #f60;
      border-radius: 85px;
      display: inline-block;
      text-align: center;
      transition: all .3s; }
  .features5 .card-box {
    width: 100%; }
  .features5 .card-title {
    margin-bottom: 0;
    transition: color .3s; }
  .features5 .card-overlay {
    position: absolute;
    top: 0;
    bottom: .75rem;
    left: .9375rem;
    right: .9375rem;
    border-radius: 6px;
    opacity: 0;
    transition: all .3s; }

.features6 .heading {
  margin-bottom: 1rem; }
  .features6 .heading-title, .features6 .heading-text {
    margin-bottom: 1em; }
.features6 .card {
  padding: 1.5rem .5rem;
  border-radius: 6px;
  background: transparent; }
  .features6 .card-img {
    width: 85px; }
    .features6 .card-img .mbr-iconfont {
      line-height: 81px;
      width: 85px;
      height: 85px;
      border: 2px solid #f60;
      border-radius: 85px;
      display: inline-block;
      text-align: center; }
  .features6 .card-box {
    width: 100%; }
  .features6 .card-title {
    margin-bottom: 0; }

.features7 .heading {
  margin-bottom: 1rem; }
  .features7 .heading-title {
    margin-bottom: 1em;
    font-size: 2rem;
    line-height: 1em; }
    @media (min-width: 768px) {
      .features7 .heading-title {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
      .features7 .heading-title {
        font-size: 3rem; } }
  .features7 .heading-text {
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 1px; }
.features7 .card {
  padding: 0;
  border: none;
  background: transparent; }
  .features7 .card-img {
    width: 40%;
    border-radius: 6px;
    padding-left: 0;
    vertical-align: top; }
    @media (max-width: 767px) {
      .features7 .card-img {
        display: block;
        width: 100%;
        padding-right: 0;
        margin-bottom: 1rem; } }
    .features7 .card-img img {
      width: 100%;
      border-radius: 6px; }
  .features7 .card-box {
    width: 100%;
    vertical-align: top; }
  .features7 .card-title {
    margin-bottom: 0;
    line-height: 1.5em; }
    @media (max-width: 767px) {
      .features7 .card-title {
        margin-bottom: .5rem; } }
  .features7 .card-text {
    line-height: 1.5em; }

.features8 .heading {
  margin-bottom: 1rem; }
  .features8 .heading-title {
    margin-bottom: 1em;
    font-size: 2rem;
    line-height: 1em; }
    @media (min-width: 768px) {
      .features8 .heading-title {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
      .features8 .heading-title {
        font-size: 3rem; } }
  .features8 .heading-text {
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 1px; }
.features8 .card {
  padding: 0;
  border: none;
  margin-bottom: 1rem;
  background: transparent; }
  .features8 .card-img {
    width: 90px;
    border-radius: 6px;
    padding-left: 0;
    vertical-align: top; }
    @media (max-width: 767px) {
      .features8 .card-img {
        width: 78px; } }
    .features8 .card-img img {
      width: 90px;
      border-radius: 6px; }
      @media (max-width: 767px) {
        .features8 .card-img img {
          width: 78px; } }
    .features8 .card-img .mbr-iconfont {
      line-height: 90px;
      font-size: 90px;
      width: 90px;
      height: 90px;
      display: inline-block;
      text-align: center; }
      @media (max-width: 767px) {
        .features8 .card-img .mbr-iconfont {
          line-height: 78px;
          width: 78px;
          height: 78px;
          font-size: 78px; } }
  .features8 .card-box {
    width: 100%;
    vertical-align: top; }
  .features8 .card-title {
    margin-bottom: 0;
    line-height: 1.5em; }
    @media (max-width: 767px) {
      .features8 .card-title {
        margin-bottom: .5rem; } }
  .features8 .card-text {
    line-height: 1.5em; }

.features10 .heading {
  margin-bottom: 1rem; }
  .features10 .heading-title, .features10 .heading-text {
    margin-bottom: 1em; }
.features10 .card {
  border: none;
  background: transparent;
  margin-bottom: 2rem; }
  .features10 .card-img {
    width: 85px; }
    .features10 .card-img .mbr-iconfont {
      line-height: 81px;
      width: 85px;
      height: 85px;
      border: 2px solid #f60;
      border-radius: 85px;
      display: inline-block;
      text-align: center; }
  .features10 .card-box {
    width: 100%; }
  .features10 .card-title {
    margin-bottom: 0; }

.features12 .mbr-section-btn {
  text-align: center; }
.features12 .heading-text {
  text-align: center; }
.features12 h1 {
  text-align: center; }
.features12.mbr-section {
  padding: 40px 0; }
.features12 .heading {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .features12 .heading {
      margin-bottom: 25px; } }
  .features12 .heading-title {
    font-weight: 100; }
.features12 .card {
  border-radius: 0;
  background: transparent;
  border: none;
  text-align: center;
  padding: 1rem; }
  @media (max-width: 1199px) {
    .features12 .card {
      padding: .5rem; } }
  @media (max-width: 767px) {
    .features12 .card {
      padding: 1rem 0;
      margin: .5rem -.9rem; } }
  .features12 .card-title {
    font-weight: 600; }
  .features12 .card-img {
    text-align: center;
    padding-bottom: 1rem; }
    @media (max-width: 991px) {
      .features12 .card-img {
        text-align: center;
        padding: 0 .5rem; } }
    .features12 .card-img .mbr-iconfont {
      width: 70px;
      height: 70px;
      border: 1px solid #fff;
      border-radius: 70px;
      display: inline-block;
      text-align: center;
      transition: all .3s; }
      .features12 .card-img .mbr-iconfont span {
        display: table-cell;
        font-size: 24px;
        line-height: 1em;
        width: 70px;
        height: 70px;
        text-align: center;
        vertical-align: middle; }
        .features12 .card-img .mbr-iconfont span p {
          display: inline; }
  .features12 .card .mbr-section-title,
  .features12 .card .mbr-section-subtitle {
    margin-bottom: 0.8rem; }
  .features12 .card .mbr-section-text {
    margin-bottom: 1.3rem; }

.features13 .mbr-section-btn {
  text-align: center; }
.features13 .heading-text {
  text-align: center; }
.features13 h1 {
  text-align: center; }
.features13.mbr-section {
  padding: 40px 0; }
.features13 .heading {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .features13 .heading {
      margin-bottom: 25px; } }
  .features13 .heading-title {
    font-weight: 100; }
.features13 .card {
  border-radius: 0;
  background: transparent;
  border: none;
  text-align: center;
  padding: 1rem; }
  @media (max-width: 1199px) {
    .features13 .card {
      padding: .5rem; } }
  @media (max-width: 767px) {
    .features13 .card {
      padding: 1rem 0;
      margin: .5rem -.9rem; } }
  .features13 .card-title {
    font-weight: 600; }
  .features13 .card-img {
    text-align: center;
    padding-bottom: 1rem; }
    @media (max-width: 991px) {
      .features13 .card-img {
        text-align: center;
        padding: 0 .5rem; } }
    .features13 .card-img .mbr-iconfont {
      line-height: 191px;
      width: 195px;
      height: 195px;
      border: 2px solid #fff;
      border-radius: 195px;
      display: inline-block;
      font-size: 120px;
      text-align: center;
      transition: all .3s; }
      @media (max-width: 991px) {
        .features13 .card-img .mbr-iconfont {
          line-height: 68px;
          width: 70px;
          height: 70px;
          border: 1px solid #fff;
          font-size: 40px; } }
  .features13 .card .mbr-section-title,
  .features13 .card .mbr-section-subtitle {
    margin-bottom: 0.8rem; }
  .features13 .card .mbr-section-text {
    margin-bottom: 1.3rem; }

.features14 .mbr-section-btn {
  text-align: center; }
.features14 .heading-text {
  text-align: center; }
.features14 h1 {
  text-align: center; }
.features14.mbr-section {
  padding: 40px 0; }
.features14 .heading {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .features14 .heading {
      margin-bottom: 25px; } }
  .features14 .heading-title {
    font-weight: 100; }
.features14 .card {
  border-radius: 0;
  background: transparent;
  border: none;
  text-align: center;
  padding: 1rem; }
  @media (max-width: 1199px) {
    .features14 .card {
      padding: .5rem; } }
  @media (max-width: 767px) {
    .features14 .card {
      padding: 1rem 0;
      margin: .5rem -.9rem; } }
  .features14 .card-title {
    font-weight: 600; }
  .features14 .card-img {
    text-align: center;
    padding-bottom: 1rem; }
    @media (max-width: 991px) {
      .features14 .card-img {
        text-align: center;
        padding: 0 .5rem; } }
    .features14 .card-img .mbr-iconfont {
      width: 195px;
      height: 195px;
      margin: 0 auto;
      border: 2px solid #fff;
      border-radius: 195px;
      display: inline-block; }
      .features14 .card-img .mbr-iconfont span {
        display: table-cell;
        font-size: 60px;
        line-height: 1em;
        width: 195px;
        height: 195px;
        text-align: center;
        vertical-align: middle; }
        .features14 .card-img .mbr-iconfont span p {
          display: inline; }
      @media (max-width: 991px) {
        .features14 .card-img .mbr-iconfont {
          width: 70px;
          height: 70px;
          border: 1px solid #fff; }
          .features14 .card-img .mbr-iconfont span {
            font-size: 22px;
            width: 70px;
            height: 70px; } }
  .features14 .card .mbr-section-title,
  .features14 .card .mbr-section-subtitle {
    margin-bottom: 0.8rem; }
  .features14 .card .mbr-section-text {
    margin-bottom: 1.3rem; }

.features15 .mbr-section-btn {
  text-align: center; }
.features15 .heading-text {
  text-align: center; }
.features15 h1 {
  text-align: center; }
.features15.mbr-section {
  padding: 40px 0; }
.features15 .heading {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .features15 .heading {
      margin-bottom: 25px; } }
  .features15 .heading-title {
    font-weight: 100; }
.features15 .card {
  border-radius: 0;
  background: transparent;
  border: none;
  text-align: center;
  padding: 1rem; }
  @media (max-width: 1199px) {
    .features15 .card {
      padding: .5rem; } }
  @media (max-width: 767px) {
    .features15 .card {
      padding: 1rem 0;
      margin: .5rem -.9rem; } }
  .features15 .card-title {
    font-weight: 600; }
  .features15 .card-img {
    text-align: center;
    padding-bottom: 1rem; }
    @media (max-width: 991px) {
      .features15 .card-img {
        text-align: center;
        padding: 0 .5rem; } }
    .features15 .card-img .mbr-iconfont {
      line-height: 191px;
      width: 195px;
      height: 195px;
      border: 2px solid #fff;
      border-radius: 195px;
      display: inline-block;
      font-size: 120px;
      text-align: center;
      transition: all .3s;
      color: #fff; }
      @media (max-width: 991px) {
        .features15 .card-img .mbr-iconfont {
          line-height: 68px;
          width: 70px;
          height: 70px;
          border: 1px solid #fff;
          font-size: 40px; } }
  .features15 .card .mbr-section-title,
  .features15 .card .mbr-section-subtitle {
    margin-bottom: 0.8rem; }
  .features15 .card .mbr-section-text {
    margin-bottom: 1.3rem; }

.features16 .mbr-section-btn {
  text-align: center; }
.features16 .heading-text {
  text-align: center; }
.features16 h1 {
  text-align: center; }
.features16.mbr-section {
  padding: 40px 0; }
.features16 .heading {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .features16 .heading {
      margin-bottom: 25px; } }
  .features16 .heading-title {
    font-weight: 100; }
.features16 .card {
  border-radius: 0;
  background: transparent;
  border: none;
  text-align: center;
  padding: 1rem; }
  @media (max-width: 1199px) {
    .features16 .card {
      padding: .5rem; } }
  @media (max-width: 767px) {
    .features16 .card {
      padding: 1rem 0;
      margin: .5rem -.9rem; } }
  .features16 .card-title {
    font-weight: 600; }
  .features16 .card-img {
    text-align: center;
    padding-bottom: 1rem; }
    @media (max-width: 991px) {
      .features16 .card-img {
        text-align: center;
        padding: 0 .5rem; } }
    .features16 .card-img .mbr-iconfont {
      width: 195px;
      height: 195px;
      border: 2px solid #fff;
      border-radius: 195px;
      display: inline-block;
      transition: all .3s; }
      .features16 .card-img .mbr-iconfont span {
        display: table-cell;
        font-size: 60px;
        line-height: 1em;
        width: 195px;
        height: 195px;
        text-align: center;
        vertical-align: middle; }
        .features16 .card-img .mbr-iconfont span p {
          color: #fff;
          display: inline; }
      @media (max-width: 991px) {
        .features16 .card-img .mbr-iconfont {
          width: 70px;
          height: 70px;
          border: 1px solid #fff; }
          .features16 .card-img .mbr-iconfont span {
            font-size: 22px;
            width: 70px;
            height: 70px; } }
  .features16 .card .mbr-section-title,
  .features16 .card .mbr-section-subtitle {
    margin-bottom: 0.8rem; }
  .features16 .card .mbr-section-text {
    margin-bottom: 1.3rem; }

.features17 {
  overflow: hidden;
  position: relative; }
  .features17 .mbr-section-title,
  .features17 .mbr-section-subtitle,
  .features17 .mbr-section-text {
    color: #fff; }
  .features17 .bg-icon {
    z-index: 0;
    position: absolute;
    top: 50%;
    right: 10%;
    margin-top: -20vh; }
    @media (max-width: 991px) {
      .features17 .bg-icon {
        margin-top: 0;
        top: 10%; } }
    @media (max-width: 767px) {
      .features17 .bg-icon {
        right: 0; } }
    .features17 .bg-icon .mbr-iconfont {
      font-size: 40vh;
      font-weight: 100;
      color: rgba(200, 200, 200, 0.2); }
  .features17 .heading {
    margin-bottom: 1rem; }
    .features17 .heading-title, .features17 .heading-text {
      margin-bottom: 1em; }
  .features17 .card {
    border: none;
    background: transparent; }
    .features17 .card-img {
      width: 65px; }
      .features17 .card-img .mbr-iconfont {
        line-height: 61px;
        width: 65px;
        height: 65px;
        border: 2px solid #f60;
        border-radius: 80px;
        display: inline-block;
        text-align: center; }
    .features17 .card-box {
      width: 100%; }
    .features17 .card-title {
      margin-bottom: 0; }

.is-builder .features17 .bg-icon {
  z-index: 5; }

.features18 {
  overflow: hidden;
  position: relative; }
  .features18 .mbr-section-title,
  .features18 .mbr-section-subtitle,
  .features18 .mbr-section-text {
    color: #fff; }
  .features18 .bg-icon {
    z-index: 0;
    position: absolute;
    top: 50%;
    right: 10%;
    margin-top: -20vh; }
    @media (max-width: 991px) {
      .features18 .bg-icon {
        margin-top: 0;
        top: 10%; } }
    @media (max-width: 767px) {
      .features18 .bg-icon {
        right: 0; } }
    .features18 .bg-icon .mbr-iconfont {
      font-size: 40vh;
      font-weight: 100;
      color: rgba(200, 200, 200, 0.2); }
  .features18 .heading {
    margin-bottom: 1rem; }
    .features18 .heading-title, .features18 .heading-text {
      margin-bottom: 1em; }
  .features18 .card {
    border: none;
    background: transparent; }
    .features18 .card-img {
      width: 65px; }
      .features18 .card-img .mbr-iconfont {
        width: 65px;
        height: 65px;
        border: 2px solid #f60;
        border-radius: 80px;
        display: inline-block;
        text-align: center; }
        .features18 .card-img .mbr-iconfont span {
          display: table-cell;
          font-size: 22px;
          line-height: 1em;
          width: 65px;
          height: 65px;
          text-align: center;
          vertical-align: middle; }
          .features18 .card-img .mbr-iconfont span p {
            color: #fff;
            display: inline; }
    .features18 .card-box {
      width: 100%; }
    .features18 .card-title {
      margin-bottom: 0; }

.is-builder .features18 .bg-icon {
  z-index: 5; }

.features19 .mbr-section-btn {
  text-align: center; }
.features19 .heading-text {
  text-align: center; }
.features19 h1 {
  text-align: center; }
.features19.mbr-section {
  padding: 40px 0; }
.features19 .heading {
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .features19 .heading {
      margin-bottom: 25px; } }
  .features19 .heading-title {
    font-weight: 100; }
@media (max-width: 767px) {
  .features19 .mbr-cards-col {
    padding-left: 2rem; } }
.features19 .card {
  border: none;
  background: transparent;
  position: relative; }
  .features19 .card-img {
    position: absolute;
    text-align: right;
    left: -30px; }
    .features19 .card-img-inner {
      height: 80px;
      display: table-cell;
      vertical-align: middle; }
    .features19 .card-img .mbr-iconfont {
      position: relative;
      width: 18px;
      height: 18px;
      border: 2px solid #fff;
      border-radius: 18px;
      display: inline-block;
      text-align: center;
      vertical-align: -3px; }
      .features19 .card-img .mbr-iconfont:before {
        position: absolute;
        top: -3px;
        right: -5px;
        font-weight: bold; }
  .features19 .card .card-text {
    margin-bottom: .5em;
    color: #999; }
  .features19 .card .card-box-inner {
    height: 80px;
    display: table-cell;
    vertical-align: middle; }
    .features19 .card .card-box-inner h4 {
      display: inline; }

.features21 {
  overflow: hidden;
  position: relative; }
  .features21 .mbr-section-title,
  .features21 .mbr-section-subtitle,
  .features21 .mbr-section-text {
    color: #222; }
  .features21 .mbr-section-subtitle {
    letter-spacing: 0;
    margin-bottom: 1rem; }
  .features21 .heading {
    margin-bottom: 1rem; }
    .features21 .heading-title, .features21 .heading-text {
      margin-bottom: 1em; }
  .features21 .card {
    border: none;
    background: transparent;
    margin-bottom: 3rem; }
    .features21 .card-img {
      width: 125px; }
      @media (max-width: 991px) {
        .features21 .card-img {
          width: 65px; } }
      .features21 .card-img .mbr-iconfont {
        line-height: 121px;
        width: 125px;
        height: 125px;
        border: 2px solid #f60;
        border-radius: 125px;
        display: inline-block;
        text-align: center;
        font-size: 65px;
        font-weight: lighter; }
        @media (max-width: 991px) {
          .features21 .card-img .mbr-iconfont {
            line-height: 61px;
            width: 65px;
            height: 65px;
            border-radius: 65px;
            font-size: 24px; } }
    .features21 .card-box {
      width: 100%; }

.features22 {
  overflow: hidden;
  position: relative; }
  .features22 .mbr-section-title,
  .features22 .mbr-section-subtitle,
  .features22 .mbr-section-text {
    color: #222; }
  .features22 .heading {
    margin-bottom: 1rem; }
    .features22 .heading-title, .features22 .heading-text {
      margin-bottom: 1em; }
  .features22 .mbr-cards-col {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    padding: .5rem; }
    .features22 .mbr-cards-col:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .features22 .card {
    border: none;
    margin-bottom: 0;
    padding: 1rem 0;
    border-radius: 0;
    background: transparent; }
    .features22 .card-wrap {
      padding: 1.5rem; }
    .features22 .card-img {
      vertical-align: top;
      width: 65px; }
      .features22 .card-img .mbr-iconfont {
        line-height: 61px;
        width: 65px;
        height: 65px;
        border: 2px solid #f60;
        border-radius: 80px;
        font-size: 30px;
        font-weight: 100;
        display: inline-block;
        text-align: center; }
    .features22 .card-box {
      width: 100%; }
    .features22 .card-title {
      letter-spacing: 0; }

.tabs1 .elements-content .col-md-10 {
  text-align: center; }
.tabs1 ul {
  text-align: center;
  display: inline-block;
  margin-bottom: 30px;
  border-bottom: 1px solid #dddfe0; }
  .tabs1 ul li {
    float: none !important; }
    .tabs1 ul li:hover a {
      border: none;
      color: #333 !important; }
      .tabs1 ul li:hover a:active {
        border: none; }
    .tabs1 ul li a {
      color: #74a3c7;
      border: 0px !important; }
    .tabs1 ul li a.active {
      color: #333;
      border: 0;
      border-bottom: 4px solid !important;
      border-radius: 0px;
      background-color: transparent !important; }
.tabs1 .content {
  display: table-cell; }
.tabs1 .wraper {
  display: table;
  vertical-align: middle;
  text-align: left;
  padding-left: 20px; }
.tabs1 .tab-pane.active .mbr-figure {
  animation: tabs-slide 0.4s ease-in-out;
  -webkit-animation: tabs-slide 0.4s ease-in-out; }
.tabs1 .tab-list {
  list-style: none;
  padding-left: 30px;
  text-align: left; }
.tabs1 .wraper p {
  padding-top: 12px;
  margin-bottom: 25px; }
.tabs1 .tab-list-wraper ul {
  border: none; }
  .tabs1 .tab-list-wraper ul li:before {
    content: '';
    display: block;
    position: absolute;
    margin-top: 7px;
    left: 43px;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background: #ffdd2d; }
.tabs1 ul.nav-tabs {
  margin-bottom: 60px; }

@keyframes tabs-slide {
  from {
    opacity: 0;
    margin-left: -50px; }
  to {
    opacity: 1;
    margin-left: 0; } }
@-webkit-keyframes tabs-slide {
  from {
    opacity: 0;
    margin-left: -50px; }
  to {
    opacity: 1;
    margin-left: 0; } }
.tabs2 ul.nav-tabs {
  margin-bottom: 60px; }
.tabs2 .wraper {
  padding-right: 20px;
  text-align: center; }

.tabs3 .mbr-section-title {
  text-align: center;
  margin-bottom: 50px; }
.tabs3 .mbr-section-subtitle {
  margin-bottom: 1.7rem; }
.tabs3 .wraper p {
  margin-bottom: 1.8rem; }
.tabs3 ul.nav-tabs {
  border: none;
  text-align: center;
  padding-top: 30px; }
  .tabs3 ul.nav-tabs li {
    float: none; }
.tabs3 .nav-tabs .nav-link.active {
  border: 5px solid #5aac98; }
.tabs3 .nav-tabs .nav-link.active:hover {
  border: 5px solid #5aac98; }
.tabs3 a.nav-link {
  margin: 5px;
  background: transparent;
  border: 1px solid #dddfe0;
  transition: all .3s;
  width: 10px;
  height: 10px;
  display: inline-block;
  padding: 0px;
  border-radius: 50%; }
.tabs3 a.nav-link.active {
  border: 5px solid #5aac98; }

.accordion1 h1 {
  margin-bottom: 40px; }
.accordion1 .colorBG {
  background-color: rgba(246, 246, 246, 0.6);
  transition: background 0.6s; }
  .accordion1 .colorBG .panel-title a:after {
    transform: rotate(408deg) !important;
    -webkit-transform: rotate(408deg) !important; }
.accordion1 .accordion-group {
  border: 1px solid #dddfe0;
  border-radius: 5px; }
  .accordion1 .accordion-group .panel-title {
    margin-bottom: 0; }
    .accordion1 .accordion-group .panel-title a {
      display: block;
      position: relative;
      padding: 20px 22px; }
      .accordion1 .accordion-group .panel-title a:after {
        width: 5px;
        height: 5px;
        border-left: 1px solid;
        border-top: 1px solid;
        -webkit-transform: rotate(225deg);
        -o-transform: rotate(225deg);
        transform: rotate(225deg);
        -webkit-transform-origin: center;
        -o-transform-origin: center;
        transform-origin: center;
        -webkit-transition: -webkit-transform .3s ease;
        transition: -webkit-transform .3s ease;
        -o-transition: -o-transform .3s ease;
        transition: transform .3s ease;
        transition: transform .3s ease,-webkit-transform .3s ease,-o-transform .3s ease;
        content: '';
        display: block;
        position: absolute;
        right: 20px;
        top: 38px; }
  .accordion1 .accordion-group .panel-body {
    padding-left: 22px;
    padding-right: 22px; }
    .accordion1 .accordion-group .panel-body p {
      margin-bottom: 0;
      padding-bottom: 16px; }
  .accordion1 .accordion-group .panel-default {
    border: none;
    border-bottom: 1px solid #dddfe0; }
    .accordion1 .accordion-group .panel-default:last-child {
      border-bottom: none; }
  .accordion1 .accordion-group .panel-heading a {
    line-height: 1.1;
    font-size: 1.8rem;
    color: #666666;
    font-weight: 300; }
    .accordion1 .accordion-group .panel-heading a:hover {
      text-decoration: none; }
    .accordion1 .accordion-group .panel-heading a:focus {
      text-decoration: none; }

.toggle1 h1 {
  margin-bottom: 40px; }
.toggle1 .colorBG {
  background-color: rgba(246, 246, 246, 0.6);
  transition: background 0.6s; }
  .toggle1 .colorBG .panel-title a:after {
    transform: rotate(408deg) !important;
    -webkit-transform: rotate(408deg) !important; }
.toggle1 .accordion-group {
  border: 1px solid #dddfe0;
  border-radius: 5px; }
  .toggle1 .accordion-group .panel-title {
    margin-bottom: 0; }
    .toggle1 .accordion-group .panel-title a {
      display: block;
      position: relative;
      padding: 20px 22px; }
      .toggle1 .accordion-group .panel-title a:after {
        width: 5px;
        height: 5px;
        border-left: 1px solid;
        border-top: 1px solid;
        -webkit-transform: rotate(225deg);
        -o-transform: rotate(225deg);
        transform: rotate(225deg);
        -webkit-transform-origin: center;
        -o-transform-origin: center;
        transform-origin: center;
        -webkit-transition: -webkit-transform .3s ease;
        transition: -webkit-transform .3s ease;
        -o-transition: -o-transform .3s ease;
        transition: transform .3s ease;
        transition: transform .3s ease,-webkit-transform .3s ease,-o-transform .3s ease;
        content: '';
        display: block;
        position: absolute;
        right: 20px;
        top: 38px; }
  .toggle1 .accordion-group .panel-body {
    padding-left: 22px;
    padding-right: 22px; }
    .toggle1 .accordion-group .panel-body p {
      margin-bottom: 0;
      padding-bottom: 16px; }
  .toggle1 .accordion-group .panel-default {
    border: none;
    border-bottom: 1px solid #dddfe0; }
    .toggle1 .accordion-group .panel-default:last-child {
      border-bottom: none; }
.toggle1 .toggle-heading a {
  line-height: 1.1;
  font-size: 1.8rem;
  color: #666666;
  font-weight: 300; }
  .toggle1 .toggle-heading a:hover {
    text-decoration: none; }
  .toggle1 .toggle-heading a:focus {
    text-decoration: none; }

.pricingtable1 .text-1 {
  line-height: 1; }
.pricingtable1 .heading-title {
  margin-bottom: 1em; }
.pricingtable1 .heading-text {
  color: #999;
  font-weight: 300;
  margin-bottom: 1em; }
.pricingtable1 .plan {
  background: transparent;
  width: 100%;
  empty-cells: hide; }
  .pricingtable1 .plan-wrap {
    overflow-x: auto;
    margin: 0; }
  .pricingtable1 .plan-title {
    color: #fff;
    font-size: 20px; }
    .pricingtable1 .plan-title.firstType {
      color: #222; }
    @media (min-width: 768px) {
      .pricingtable1 .plan-title {
        font-size: 25px; } }
    @media (min-width: 992px) {
      .pricingtable1 .plan-title {
        font-size: 30px; } }
  .pricingtable1 .plan-text {
    font-size: 16px; }
    @media (min-width: 768px) {
      .pricingtable1 .plan-text {
        font-size: 18px; } }
    @media (min-width: 992px) {
      .pricingtable1 .plan-text {
        font-size: 20px; } }
    .pricingtable1 .plan-text.price {
      line-height: 1; }
  .pricingtable1 .plan tr:nth-child(2) {
    height: 150px; }
  .pricingtable1 .plan tr:last-child {
    height: 150px; }
  .pricingtable1 .plan tr th {
    height: 100px;
    min-width: 200px;
    vertical-align: middle;
    text-align: center;
    padding: 1rem 2rem;
    margin-bottom: 1rem; }
    .pricingtable1 .plan tr th:first-child {
      text-align: left;
      background: rgba(200, 200, 200, 0.2);
      width: 200px; }
    .pricingtable1 .plan tr th + th {
      border-left: 1px solid rgba(244, 244, 244, 0.2); }
    .pricingtable1 .plan tr th h3 {
      font-weight: 100;
      display: inline; }
  .pricingtable1 .plan tr td {
    height: 50px;
    vertical-align: middle;
    text-align: center;
    padding: .5rem 2rem; }
    .pricingtable1 .plan tr td:first-child {
      text-align: left;
      color: #999;
      background: rgba(200, 200, 200, 0.2); }
    .pricingtable1 .plan tr td + td {
      border-left: 1px solid rgba(200, 200, 200, 0.2); }
    .pricingtable1 .plan tr td .plan-text {
      display: inline; }
      .pricingtable1 .plan tr td .plan-text .price {
        font-size: 3.2em;
        color: #494949; }

.pricingtable2 .text-1 {
  line-height: 1em; }
.pricingtable2 h3 {
  margin-bottom: 25px; }
.pricingtable2 .plan-btn {
  padding-top: 25px; }
.pricingtable2 .heading-title {
  margin-bottom: 1em; }
.pricingtable2 .heading-text {
  color: #999;
  font-weight: 300;
  margin-bottom: 1em; }
.pricingtable2 .plan-wrap {
  margin-bottom: 20px; }
  @media (max-width: 1199px) {
    .pricingtable2 .plan-wrap {
      border: 1px solid rgba(200, 200, 200, 0.4); } }
  .pricingtable2 .plan-wrap.default {
    background-color: rgba(228, 228, 228, 0.2); }
  .pricingtable2 .plan-wrap + .plan-wrap {
    border-left: 1px solid rgba(200, 200, 200, 0.4); }
  .pricingtable2 .plan-wrap .btn {
    width: 80%;
    margin: auto;
    margin-bottom: 20px; }
.pricingtable2 .plan-price {
  padding-bottom: 16px; }
.pricingtable2 .plan-price, .pricingtable2 .plan-btn {
  margin: 0; }
.pricingtable2 .plan-title {
  padding: 1rem;
  text-align: center;
  font-weight: 100;
  margin-left: -.9375rem;
  margin-right: -.9375rem;
  color: #fff;
  font-size: 20px; }
  @media (min-width: 768px) {
    .pricingtable2 .plan-title {
      font-size: 25px; } }
  @media (min-width: 992px) {
    .pricingtable2 .plan-title {
      font-size: 30px; } }
.pricingtable2 .plan p {
  text-align: center; }
.pricingtable2 .plan-text {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .pricingtable2 .plan-text {
      font-size: 18px; } }
  @media (min-width: 992px) {
    .pricingtable2 .plan-text {
      font-size: 20px; } }
  .pricingtable2 .plan-text.price {
    padding: 0;
    font-size: 4em;
    color: #494949; }
  .pricingtable2 .plan-text.currency {
    padding: 0; }

.pricing-table3 .mbr-section-title {
  font-size: 80px; }
.pricing-table3 .list-group-item {
  border: none; }
.pricing-table3 .main-row {
  margin: auto; }
.pricing-table3 .mbr-plan-btn {
  padding-top: 12px; }
.pricing-table3 a.btn {
  margin-bottom: 24px;
  width: 100%; }
.pricing-table3 .card {
  padding-right: 24px;
  padding-left: 24px;
  margin-top: 15px;
  transition: box-shadow 0.3s;
  -webkit-transition: box-shadow 0.3s; }
  .pricing-table3 .card:hover {
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.3s;
    -webkit-transition: box-shadow 0.3s; }
.pricing-table3 .mbr-price-table {
  padding-right: 30px;
  padding-left: 30px; }
.pricing-table3 .mbr-price-figure {
  font-size: 76px;
  line-height: 1;
  display: block; }
.pricing-table3 .mbr-price-term {
  font-size: 17px; }
.pricing-table3 .mbr-plan-price-desc {
  font-size: 17px;
  display: block;
  margin-top: 20px; }
.pricing-table3 .mbr-plan-header {
  margin-left: -24px;
  margin-right: -24px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #fff; }
.pricing-table3 .mbr-plan-body {
  padding-top: 16px; }
.pricing-table3 .list-group-item {
  padding: 1rem 1.25rem; }

@media (max-width: 450px) {
  .pricing-table3 .mbr-price-table {
    padding-left: 0;
    padding-right: 0; } }
@media (max-width: 768px) {
  .pricing-table3 .card {
    max-width: 500px;
    margin: auto; } }
.content1 p, .content1 blockquote, .content2 p, .content2 blockquote, .content3 p, .content3 blockquote, .content4 p, .content4 blockquote {
  font-size: 1rem;
  color: #4a4a4a;
  margin-bottom: 0 !important; }

.content2 blockquote {
  padding: 22px 22px 22px 40px;
  background-color: rgba(226, 226, 226, 0.2);
  color: #828282; }

.content3 p {
  margin-top: 0 !important; }

.content4 p {
  padding: 20px; }

.form1.mbr-section {
  padding: 40px 0; }
.form1 .heading-title, .form1 .heading-text {
  margin-bottom: 1em; }
.form1 .form {
  border: 1px solid #efeff0;
  padding: 2rem 1rem; }
  .form1 .form-title {
    margin-bottom: 2rem; }
    @media (max-width: 991px) {
      .form1 .form-title {
        margin-bottom: 1rem; } }
  .form1 .form-wrap > div {
    margin: 0 0 1rem 0;
    padding: 0; }
  .form1 .form-group {
    margin: 0; }
  .form1 .form-separator {
    display: block;
    position: relative;
    width: 100%;
    height: 1px;
    background: #efeff0;
    margin-top: 1rem;
    margin-bottom: 2rem; }
  .form1 .form .form-control-label {
    display: none; }
  .form1 .form input, .form1 .form textarea {
    padding: 1rem;
    color: #222;
    border: 1px solid #c7c9cc;
    background: #fff;
    border-radius: 3px; }
  .form1 .form .btn {
    margin-bottom: 0; }

@media (min-width: 1200px) {
  .form1 .container {
    max-width: 960px; } }
.form2 h5 {
  padding-top: 30px;
  padding-left: 30px;
  margin-bottom: 0px; }
.form2 h1 {
  margin-bottom: 30px;
  padding-left: 30px;
  font-size: 22px;
  color: #fff; }
.form2 form {
  padding-top: 30px;
  border-radius: 6px; }
.form2 textarea {
  margin-bottom: 14px; }
.form2 input, .form2 textarea {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
  height: 66px;
  background: #eef2f7;
  border: 1px solid #eef2f7;
  border-radius: 6px;
  border-collapse: separate;
  color: #333;
  text-align: left;
  cursor: text; }
.form2 input::-webkit-input-placeholder {
  color: #9299a2; }
.form2 input::-webkit-input-placeholder {
  color: #9299a2; }
.form2 input::-moz-placeholder {
  color: #9299a2; }
.form2 input:-ms-input-placeholder {
  color: #9299a2; }
.form2 input:-moz-placeholder {
  color: #9299a2; }
.form2 textarea {
  height: 180px; }
.form2 button {
  margin-bottom: 0; }
.form2 .form-button-wrap {
  margin-top: 16px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px; }
.form2 .display-2 {
  text-align: center; }
.form2 .alert {
  margin-bottom: 0; }
.form2 .form-group {
  margin-bottom: 30px; }
.form2 .form {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #dddfe0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px; }
.form2 .heading {
  background-color: #3e4757;
  margin-left: 0;
  margin-right: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px; }
  .form2 .heading h1 {
    margin-top: 30px; }
  .form2 .heading p {
    padding-left: 30px;
    padding-right: 30px;
    color: #fff; }
.form2 .checkbox {
  display: inline-block;
  width: 64%;
  vertical-align: middle;
  margin-bottom: 0; }
.form2 .button-block {
  display: inline-block;
  width: 35%; }
.form2 .checkbox-title {
  padding: 5px 10px 5px 0px;
  font-size: 14px;
  line-height: 1.4;
  display: block;
  width: 88%;
  float: right; }
.form2 input[type="checkbox"] {
  display: none; }
.form2 label {
  position: relative;
  display: block;
  float: left;
  width: 8%;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: #dee2e6; }
.form2 .checked:after {
  content: "";
  position: absolute;
  top: 4px;
  right: 10px;
  width: 3px;
  height: 21px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }
.form2 .checked:before {
  content: "";
  position: absolute;
  top: 12px;
  right: 20px;
  width: 3px;
  height: 10px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

@media (max-width: 400px) {
  .form2 h1 {
    font-size: 18px; } }
@media (min-width: 1200px) {
  .form2 .container {
    max-width: 960px; } }
@media (max-width: 992px) {
  .form2 .checkbox {
    width: 100%; }
  .form2 .button-block {
    width: 100%; } }
@media (max-width: 380px) {
  .form2 .checkbox {
    display: inline;
    width: auto;
    float: none; }
  .form2 .button-block {
    display: inline;
    width: auto;
    float: none; }
  .form2 .button-block {
    display: block;
    width: 100%; } }
.form3 h5 {
  padding-top: 30px;
  padding-left: 30px;
  margin-bottom: 0px; }
.form3 h1 {
  margin-bottom: 30px;
  padding-left: 30px;
  font-size: 22px;
  color: #fff; }
.form3 form {
  padding-top: 30px;
  border-radius: 6px; }
.form3 textarea {
  margin-bottom: 14px; }
.form3 input, .form3 textarea {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
  height: 66px;
  background: #eef2f7;
  border: 1px solid #eef2f7;
  border-radius: 6px;
  border-collapse: separate;
  color: #333;
  text-align: left;
  cursor: text; }
.form3 input::-webkit-input-placeholder {
  color: #9299a2; }
.form3 input::-webkit-input-placeholder {
  color: #9299a2; }
.form3 input::-moz-placeholder {
  color: #9299a2; }
.form3 input:-ms-input-placeholder {
  color: #9299a2; }
.form3 input:-moz-placeholder {
  color: #9299a2; }
.form3 textarea {
  height: 180px; }
.form3 button {
  margin-bottom: 0; }
.form3 .form-button-wrap {
  margin-top: 16px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px; }
.form3 .display-2 {
  text-align: center; }
.form3 .alert {
  margin-bottom: 0; }
.form3 .form-group {
  margin-bottom: 30px; }
.form3 .form {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #dddfe0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px; }
.form3 .heading {
  background-color: #3e4757;
  margin-left: 0;
  margin-right: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px; }
  .form3 .heading h1 {
    margin-top: 30px; }
  .form3 .heading p {
    padding-left: 30px;
    padding-right: 30px;
    color: #fff; }
.form3 .checkbox {
  display: inline-block;
  width: 64%;
  vertical-align: middle;
  margin-bottom: 0; }
.form3 .button-block {
  display: inline-block;
  width: 35%; }
.form3 .checkbox-title {
  padding: 5px 10px 5px 0px;
  font-size: 14px;
  line-height: 1.4;
  display: block;
  width: 88%;
  float: right; }
.form3 input[type="checkbox"] {
  display: none; }
.form3 label {
  position: relative;
  display: block;
  float: left;
  width: 8%;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: #dee2e6; }
.form3 .checked:after {
  content: "";
  position: absolute;
  top: 4px;
  right: 10px;
  width: 3px;
  height: 21px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }
.form3 .checked:before {
  content: "";
  position: absolute;
  top: 12px;
  right: 20px;
  width: 3px;
  height: 10px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

@media (max-width: 400px) {
  .form3 h1 {
    font-size: 18px; } }
@media (min-width: 1200px) {
  .form3 .container {
    max-width: 960px; } }
@media (max-width: 992px) {
  .form3 .checkbox {
    width: 100%; }
  .form3 .button-block {
    width: 100%; } }
@media (max-width: 380px) {
  .form3 .checkbox {
    display: inline;
    width: auto;
    float: none; }
  .form3 .button-block {
    display: inline;
    width: auto;
    float: none; }
  .form3 .button-block {
    display: block;
    width: 100%; } }
.subscribe1 h1 {
  margin-bottom: 30px;
  font-size: 50px; }
.subscribe1 .form-icon, .subscribe1 .form-control-label {
  display: none; }
.subscribe1 .mbr-section-subtitle {
  margin-bottom: 1.62rem; }
.subscribe1 .mbr-table-cell {
  display: table-cell; }
  .subscribe1 .mbr-table-cell.padding-right-0 {
    padding-right: 0; }
.subscribe1 .btn {
  margin-bottom: 0px; }
  .subscribe1 .btn .btn-text {
    line-height: 1;
    padding: 0; }
.subscribe1 .form-group {
  margin-bottom: 0;
  margin-right: 20px; }
.subscribe1 input, .subscribe1 textarea {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%;
  height: 66px;
  background: #eef2f7;
  border: 1px solid #eef2f7;
  border-radius: 6px;
  border-collapse: separate;
  color: #333;
  text-align: left;
  cursor: text; }
.subscribe1 input::-webkit-input-placeholder {
  color: #9299a2; }
.subscribe1 input::-webkit-input-placeholder {
  color: #9299a2; }
.subscribe1 input::-moz-placeholder {
  color: #9299a2; }
.subscribe1 input:-ms-input-placeholder {
  color: #9299a2; }
.subscribe1 input:-moz-placeholder {
  color: #9299a2; }

@media (max-width: 400px) {
  .subscribe1 .form-group {
    margin-right: 15px; }
  .subscribe1 .form-block {
    display: block;
    margin-bottom: 10px; }
  .subscribe1 .button-block {
    display: block;
    padding-left: 15px;
    padding-right: 15px; }
    .subscribe1 .button-block button, .subscribe1 .button-block a {
      width: 100%;
      margin-right: 0;
      margin-left: 0; } }
.mbr-map {
  width: 100%;
  min-height: 300px;
  height: 300px; }
  .mbr-map.boxed {
    border-radius: 6px; }
    .mbr-map.boxed iframe {
      border-radius: 6px; }
  .mbr-map iframe {
    width: 100%;
    min-height: 300px;
    height: 300px; }

.footer1 .card {
  border: none;
  background: transparent;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .footer1 .card-title {
    margin-bottom: 1rem; }

.footer2 .footer-header {
  font-weight: 100; }
.footer2 .footer-list {
  color: #f60;
  margin-bottom: 1rem; }
  .footer2 .footer-list ul {
    display: block;
    width: 100%;
    margin: 0 auto; }
    .footer2 .footer-list ul li {
      display: inline-block; }
      .footer2 .footer-list ul li:after {
        content: '\2022';
        color: inherit;
        display: inline-block;
        padding: 0 1.5rem;
        font-size: 1.5em;
        vertical-align: -4px; }
        @media (max-width: 543px) {
          .footer2 .footer-list ul li:after {
            content: ''; } }
      .footer2 .footer-list ul li:last-child:after {
        content: ''; }
      .footer2 .footer-list ul li.last:after {
        content: ''; }
.footer2 .btn-social {
  margin: 0 .4rem .5rem;
  width: 45px;
  height: 45px;
  line-height: 43px; }
  .footer2 .btn-social i {
    width: 43px;
    line-height: 43px; }

.footer3 .number {
  font-size: 30px;
  line-height: 1em;
  text-align: center;
  margin-bottom: 1rem; }
.footer3 .title {
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: #999;
  margin-bottom: .5rem; }

.share1.share2 .number {
  font-size: 20px;
  line-height: 48px; }
  @media (min-width: 768px) {
    .share1.share2 .number {
      font-size: 23px; } }
  @media (min-width: 992px) {
    .share1.share2 .number {
      font-size: 25px; } }
  @media (min-width: 1200px) {
    .share1.share2 .number {
      font-size: 30px; } }
.share1.share2 .mbr-section-btn {
  width: 100%; }
  .share1.share2 .mbr-section-btn .btn {
    width: 100%;
    margin: 0 auto;
    height: 48px;
    line-height: 48px;
    padding: 0;
    margin-bottom: 1rem; }

/*-------

   Slider

-------*/
.mbr-slider .carousel-inner > .active,
.mbr-slider .carousel-inner > .next,
.mbr-slider .carousel-inner > .prev {
  display: table;
  width: 100%; }

.mbr-slider .carousel-control {
  position: absolute;
  width: 51px;
  height: 51px;
  top: 50%;
  margin-top: -25px;
  line-height: 45px;
  border-radius: 50%;
  color: inherit;
  opacity: 1;
  text-shadow: none;
  z-index: 5;
  color: #fff;
  background: none;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s; }

.mbr-gallery .mbr-slider .carousel-control {
  position: fixed; }
  @media (max-width: 991px) {
    .mbr-gallery .mbr-slider .carousel-control {
      bottom: 2.5rem;
      margin-top: 0;
      top: auto;
      z-index: 17; } }

.mbr-gallery .mbr-slider .carousel-inner > .active {
  display: block; }

.mbr-slider .carousel-control.left {
  left: 0;
  margin-left: 2.5rem; }

.mbr-slider .carousel-control.right {
  right: 0;
  margin-right: 2.5rem; }

.mbr-slider .carousel-control .icon-next,
.mbr-slider .carousel-control .icon-prev {
  margin-top: -18px;
  font-size: 40px;
  line-height: 27px; }

.mbr-slider .carousel-control:hover {
  color: #fff;
  opacity: 1; }

.mbr-slider .carousel-indicators {
  position: absolute;
  bottom: 0;
  margin-bottom: 1.5rem !important; }
  @media (max-width: 543px) {
    .mbr-slider .carousel-indicators {
      display: none; } }

.mbr-gallery .mbr-slider .carousel-indicators {
  position: fixed;
  margin-bottom: 2.5rem !important; }
  @media (max-width: 991px) {
    .mbr-gallery .mbr-slider .carousel-indicators {
      margin-bottom: 3.625rem !important;
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

.mbr-slider .carousel-indicators li,
.mbr-slider .carousel-indicators .active {
  width: 15px;
  height: 15px;
  margin: 3px;
  background: #1b1b1b;
  border: 0;
  opacity: 1;
  box-shadow: 0 0 10px rgba(33, 33, 33, 0.3); }

.mbr-slider .carousel-indicators .active {
  background: #fff; }

@media (max-width: 767px) {
  .mbr-slider .carousel-control {
    top: auto;
    bottom: 20px; }

  .mbr-slider > .container .carousel-control {
    margin-bottom: 0px; } }
/* boxed slider */
.mbr-slider > .boxed-slider {
  position: relative;
  padding: 93px 0; }

.mbr-slider > .boxed-slider > div {
  position: relative; }

.mbr-slider > .container img {
  width: 100%; }

.mbr-slider > .container .carousel-control img {
  width: auto; }

.mbr-slider > .container img + .row {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2; }

.mbr-slider .mbr-section {
  padding: 0;
  background-attachment: scroll; }

.mbr-slider .mbr-table-cell {
  padding: 0; }

.mbr-slider > .container .carousel-indicators {
  margin-bottom: 3px; }

/* article slider */
.mbr-slider > .article-slider .mbr-section,
.mbr-slider > .article-slider .mbr-section .mbr-table-cell {
  padding-top: 0;
  padding-bottom: 0; }

.mbr-slider p {
  color: #ffffff; }
.mbr-slider .carousel-indicators li {
  background-color: #e3e3e3; }

.carousel-control .mbri-left, .carousel-control .mbri-right {
  font-size: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .3s;
  color: rgba(255, 255, 255, 0.8); }

/*-------

   Gallery

-------*/
.mbr-gallery .mbr-gallery-item {
  position: relative;
  display: inline-block;
  width: 25%; }
  @media (max-width: 768px) {
    .mbr-gallery .mbr-gallery-item {
      width: 50%; } }
  @media (max-width: 400px) {
    .mbr-gallery .mbr-gallery-item {
      width: 100%; } }

.mbr-gallery .mbr-gallery-item img {
  width: 100%;
  opacity: 1;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out; }

.mbr-gallery .mbr-gallery-item > a:hover img {
  opacity: 1; }

.mbr-gallery .mbr-gallery-item > a {
  background: #fff;
  display: block;
  outline: none;
  position: relative; }
  .mbr-gallery .mbr-gallery-item > a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(left, #554346, #45505b) !important;
    background: -moz-linear-gradient(left, #554346, #45505b) !important;
    background: -o-linear-gradient(left, #554346, #45505b) !important;
    background: -ms-linear-gradient(left, #554346, #45505b) !important;
    background: linear-gradient(left, #554346, #45505b) !important;
    opacity: 0;
    -webkit-transition: .2s opacity ease-in-out;
    transition: .2s opacity ease-in-out; }

.mbr-gallery .mbr-gallery-item .icon {
  -webkit-transform: translateX(-50%) translateY(-50%);
  -webkit-transition: .2s opacity ease-in-out;
  color: #000;
  font-size: 30px;
  height: 69px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: .2s opacity ease-in-out;
  width: 69px; }
  .mbr-gallery .mbr-gallery-item .icon::before, .mbr-gallery .mbr-gallery-item .icon::after {
    content: "";
    display: block;
    position: absolute;
    height: 69px;
    width: 1px;
    margin-left: 34.5px;
    background-color: #fff; }
  .mbr-gallery .mbr-gallery-item .icon::after {
    width: 69px;
    height: 1px;
    margin-left: 0;
    margin-top: 34.5px; }

.mbr-gallery .mbr-gallery-item > a:hover .icon {
  opacity: 1; }

.mbr-gallery .mbr-gallery-item > a:hover::before {
  opacity: 0.9; }

.mbr-gallery-title {
  display: block;
  width: 100%;
  margin: 0;
  padding: 1rem 0 1rem 1rem;
  color: #222;
  text-align: left; }

.mbr-gallery .mbr-gallery-item > a:hover .mbr-gallery-title {
  background: transparent !important; }

/* remove spacing */
.mbr-gallery .mbr-gallery-row.no-gutter {
  margin: 0; }

.mbr-gallery .mbr-gallery-row.no-gutter .mbr-gallery-item {
  padding: 0; }

/* container */
.mbr-gallery .container.mbr-gallery-layout-default {
  padding: 93px 0; }

/* fix horizontal scrollbar */
.mbr-gallery .mbr-gallery-layout-default,
.mbr-gallery .mbr-gallery-layout-article {
  overflow: hidden; }

/* lightbox */
.mbr-gallery .modal {
  position: fixed;
  overflow: hidden;
  padding-right: 0 !important; }

.mbr-gallery .modal-content {
  border-radius: 0; }

.mbr-gallery .modal-body {
  padding: 0; }

.mbr-gallery .modal-body img {
  width: auto; }

.mbr-gallery .modal .close {
  position: fixed;
  background: transparent;
  opacity: 1;
  font-size: 55px;
  font-weight: 300;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: #fff;
  top: 2.5rem;
  right: 2.5rem;
  line-height: 61px;
  border: 3px solid transparent;
  text-align: center;
  text-shadow: none;
  z-index: 5;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease; }

.mbr-gallery .modal .close:hover {
  opacity: 1;
  background: #000;
  color: #fff; }

.mbr-gallery .modal.in .modal-dialog {
  margin: 0 auto; }

/* modal back color opacity */
.modal-backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80); }

@media (max-width: 768px) {
  .mbr-gallery .carousel-indicators,
  .mbr-gallery .carousel-control,
  .mbr-gallery .modal .close {
    position: fixed; } }
/* fix fade in effect */
.mbr-gallery .modal.fade .modal-dialog {
  -webkit-transition: margin-top 0.3s ease-out;
  -moz-transition: margin-top 0.3s ease-out;
  -o-transition: margin-top 0.3s ease-out;
  transition: margin-top 0.3s ease-out; }

.mbr-gallery .modal.in .modal-dialog,
.mbr-gallery .modal.fade .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none; }

/*add*/
.mbr-gallery .icon {
  z-index: 100 !important; }

.mbr-gallery .mbr-gallery-item > div:hover .mbri-search {
  opacity: 1; }

.mbr-gallery .mbri-search {
  color: white;
  opacity: 0;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 27px);
  font-size: 3rem;
  transition: .2s opacity ease-in-out;
  z-index: 5; }

.mbr-gallery .mbri-search:after {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  border-radius: 50%;
  border: 3px solid #fff;
  width: 90px;
  height: 90px; }

.mbr-gallery .mbri-search:before {
  color: white;
  position: absolute; }

.mbr-gallery .mbr-gallery-item {
  cursor: pointer; }

.mbr-gallery .item-overlay {
  position: absolute;
  width: 60px;
  font-size: 60px;
  z-index: 10;
  height: 60px;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  cursor: pointer;
  z-index: 5; }

.mbr-gallery .mbr-gallery-item > div::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out; }

.mbr-gallery .mbr-gallery-item:hover {
  border-radius: 5px; }

.mbr-gallery .mbr-gallery-item:hover > div::before {
  opacity: 0.5; }

.mbr-gallery-row {
  padding-left: 0px;
  padding-right: 0px; }

.mbr-gallery-item__hided {
  position: absolute !important;
  width: 0px !important;
  height: 0px;
  left: 0 !important;
  padding: 0 !important; }

.mbr-gallery-item__hided img {
  display: none; }

.mbr-gallery-item__hided span {
  display: none; }

.mbr-gallery-filter {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center; }

.mbr-gallery-filter.gallery-filter__bg li {
  color: white; }

.mbr-gallery-filter.gallery-filter__bg .active {
  color: black;
  background-color: white; }

.mbr-gallery-filter ul {
  padding-left: 0px;
  display: inline-block;
  list-style: none;
  margin-bottom: 0px;
  width: 100%; }

.mbr-gallery-filter li {
  font-size: 17px !important;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  padding: 5px 12px;
  margin: 5px 5px;
  transition: all 0.3s ease-out;
  letter-spacing: 2px;
  border: none !important;
  text-transform: none; }
  .mbr-gallery-filter li:hover {
    background-color: transparent !important;
    color: #333 !important; }
  .mbr-gallery-filter li.active {
    background-color: transparent !important;
    color: #333 !important;
    border: 1px solid #dddfe0 !important;
    border-radius: 6px !important; }

.mbr-gallery-item > div {
  position: relative; }

.mbr-gallery-item--p1 {
  padding: 1rem; }
  .mbr-gallery-item--p1 .mbr-gallery-title {
    padding-bottom: 0; }

.mbr-gallery-item--p2 {
  padding: 2rem; }
  .mbr-gallery-item--p2 .mbr-gallery-title {
    padding-bottom: 0; }

.mbr-gallery-item--p3 {
  padding: 3rem; }
  .mbr-gallery-item--p3 .mbr-gallery-title {
    padding-bottom: 0; }

.mbr-gallery-item--p4 {
  padding: 4rem; }
  .mbr-gallery-item--p4 .mbr-gallery-title {
    padding-bottom: 0; }

.mbr-gallery-item--p5 {
  padding: 5rem; }
  .mbr-gallery-item--p5 .mbr-gallery-title {
    padding-bottom: 0; }

.mbr-gallery-item--p6 {
  padding: 6rem; }
  .mbr-gallery-item--p6 .mbr-gallery-title {
    padding-bottom: 0; }

.mbr-gallery .mbr-gallery-item--p6, .mbr-gallery .mbr-gallery-item--p5, .mbr-gallery .mbr-gallery-item--p4 {
  width: 50%; }

@media (max-width: 992px) {
  .mbr-gallery-item--p1 {
    padding: 1rem; }

  .mbr-gallery-item--p2 {
    padding: 2rem; }

  .mbr-gallery-item--p3 {
    padding: 3rem; }

  .mbr-gallery-item--p4 {
    padding: 3rem; }

  .mbr-gallery-item--p5 {
    padding: 3rem; }

  .mbr-gallery-item--p6 {
    padding: 3rem; } }
@media (max-width: 992px) and (min-width: 400px) {
  .mbr-gallery .mbr-gallery-item--p6, .mbr-gallery .mbr-gallery-item--p5, .mbr-gallery .mbr-gallery-item--p4, .mbr-gallery .mbr-gallery-item--p3, .mbr-gallery .mbr-gallery-item--p2 {
    width: 50%; } }
@media (max-width: 400px) {
  .mbr-gallery .mbr-gallery-item--p6, .mbr-gallery .mbr-gallery-item--p5, .mbr-gallery .mbr-gallery-item--p4, .mbr-gallery-item--p3 {
    width: 100%; } }
.mbr-gallery-filter ul li {
  margin: 5px; }

.mbr-gallery .mbri-search:after {
  border: 2px solid #fff; }

.content5 .mbr-section-title + .mbr-section-subtitle {
  margin-top: 0; }

.image3 figcaption {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: auto !important;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #333; }

@media (max-width: 768px) {
  .image3 figcaption {
    width: 90% !important;
    margin-left: 5%;
    margin-right: 5%;
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    left: 0;
    padding: 10px 20px !important; } }
.content8 .mbr-section-text p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.7em;
  color: #4a4a4a; }
.content8 .mbr-table-md-up .mbr-table-cell + .mbr-table-cell {
  padding-top: 0; }
.content8 .image-size {
  padding-left: 20px !important;
  padding-left: 20px !important; }

@media (max-width: 768px) {
  .content8 .image-size {
    width: 100% !important;
    padding: 15px !important; }
  .content8 .mbr-figure img {
    width: auto;
    max-width: 100%;
    margin: auto; } }
.counters1 {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .counters1 .content {
    text-align: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
    @media (max-width: 767px) {
      .counters1 .content {
        border: none; } }
    .counters1 .content:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.1); }
      @media (max-width: 767px) {
        .counters1 .content:last-child {
          border: none; } }
  .counters1 .count {
    display: block;
    text-align: center;
    font-size: 70px;
    font-weight: 100;
    margin-bottom: 30px; }

.counters2 {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .counters2 .content {
    text-align: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
    @media (max-width: 767px) {
      .counters2 .content {
        border: none; } }
    .counters2 .content:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.1); }
      @media (max-width: 767px) {
        .counters2 .content:last-child {
          border: none; } }
  .counters2 .count {
    display: inline-block;
    text-align: center;
    font-size: 70px;
    line-height: 70px;
    font-weight: 100;
    margin-bottom: 30px;
    vertical-align: bottom; }
  .counters2 .currency {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    vertical-align: 65px;
    color: #999; }

.table1 .mbr-section-title {
  text-align: center; }
.table1 .scroll {
  overflow-x: auto; }
.table1 .dataTables_scrollBody {
  overflow-y: hidden !important; }
.table1 .table.dataTable thead > tr > th {
  transition: background .5s, color .5s, border-color .5s; }
  .table1 .table.dataTable thead > tr > th:hover {
    background-color: #333;
    color: #fff !important;
    border-color: #333;
    transition: background .35s, color .35s, border-color .35s; }
  .table1 .table.dataTable thead > tr > th.sorting_asc, .table1 .table.dataTable thead > tr > th.sorting_desc {
    background-color: #333;
    color: #fff !important;
    border-color: #333; }
.table1 div.dataTables_wrapper div.dataTables_info {
  white-space: normal; }
.table1 div.dataTables_wrapper .dataTables_filter {
  margin-bottom: 14px; }
  .table1 div.dataTables_wrapper .dataTables_filter p {
    display: inline-block; }
.table1 .table-wrapper .info p {
  display: inline-block; }

.testimonials1 .mbr-author-img a img {
  width: 80px;
  height: 80px; }
.testimonials1 p {
  color: #515151; }
.testimonials1 .boxed-slider {
  padding-top: 0;
  padding-bottom: 0; }
.testimonials1 .mbr-author-img {
  display: inline-block;
  vertical-align: top; }
.testimonials1 .card {
  border: none;
  background-color: transparent; }
.testimonials1 .mbr-author {
  border-top: 0;
  background-color: transparent; }
.testimonials1 .author {
  display: inline-block;
  margin-left: 20px;
  margin-top: 9px; }
  .testimonials1 .author .mbr-author-name {
    font-size: 21px;
    line-height: 1; }
  .testimonials1 .author small {
    font-size: 13px;
    line-height: 1; }
.testimonials1 .carousel-control.right {
  margin-right: 0; }
.testimonials1 .carousel-control .mbri-left, .testimonials1 .carousel-control .mbri-right {
  font-size: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .3s;
  color: rgba(0, 0, 0, 0.4); }
.testimonials1 .carousel-control.left {
  margin-left: 0; }
.testimonials1 .card-block p {
  padding-right: 30px;
  padding-left: 30px; }
.testimonials1 .carousel-indicators {
  display: block;
  position: relative;
  margin: 20px auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0; }
  .testimonials1 .carousel-indicators li {
    box-shadow: none !important;
    margin: 5px;
    background: transparent;
    border: 1px solid #dddfe0;
    transition: all .3s;
    width: 10px;
    height: 10px; }
    .testimonials1 .carousel-indicators li.active {
      height: 10px;
      width: 10px;
      margin: 5px; }

/*# sourceMappingURL=style.css.map */
.engine {
	position: absolute;
	text-indent: -2400px;
	text-align: center;
	padding: 0;
	top: 0;
	left: -2400px;
}