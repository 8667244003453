@font-face {
	font-family: 'iconsMind';
	src:url('fonts/icons-mind.eot?-rdmvgc');
	src:url('fonts/icons-mind.eot?#iefix-rdmvgc') format('embedded-opentype'),
		url('fonts/icons-mind.woff?-rdmvgc') format('woff'),
		url('fonts/icons-mind.ttf?-rdmvgc') format('truetype'),
		url('fonts/icons-mind.svg?-rdmvgc#icons-mind') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="imind-"], [class*=" imind-"] {
	font-family: 'iconsMind' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.imind-a-z:before {
	content: "\e600";
}
.imind-aa:before {
	content: "\e601";
}
.imind-add-bag:before {
	content: "\e602";
}
.imind-add-basket:before {
	content: "\e603";
}
.imind-add-cart:before {
	content: "\e604";
}
.imind-add-file:before {
	content: "\e605";
}
.imind-add-spaceafterparagraph:before {
	content: "\e606";
}
.imind-add-spacebeforeparagraph:before {
	content: "\e607";
}
.imind-add-user:before {
	content: "\e608";
}
.imind-add-userstar:before {
	content: "\e609";
}
.imind-add-window:before {
	content: "\e60a";
}
.imind-add:before {
	content: "\e60b";
}
.imind-address-book:before {
	content: "\e60c";
}
.imind-address-book2:before {
	content: "\e60d";
}
.imind-administrator:before {
	content: "\e60e";
}
.imind-aerobics-2:before {
	content: "\e60f";
}
.imind-aerobics-3:before {
	content: "\e610";
}
.imind-aerobics:before {
	content: "\e611";
}
.imind-affiliate:before {
	content: "\e612";
}
.imind-aim:before {
	content: "\e613";
}
.imind-air-balloon:before {
	content: "\e614";
}
.imind-airbrush:before {
	content: "\e615";
}
.imind-airship:before {
	content: "\e616";
}
.imind-alarm-clock:before {
	content: "\e617";
}
.imind-alarm-clock2:before {
	content: "\e618";
}
.imind-alarm:before {
	content: "\e619";
}
.imind-alien-2:before {
	content: "\e61a";
}
.imind-alien:before {
	content: "\e61b";
}
.imind-aligator:before {
	content: "\e61c";
}
.imind-align-center:before {
	content: "\e61d";
}
.imind-align-justifyall:before {
	content: "\e61e";
}
.imind-align-justifycenter:before {
	content: "\e61f";
}
.imind-align-justifyleft:before {
	content: "\e620";
}
.imind-align-justifyright:before {
	content: "\e621";
}
.imind-align-left:before {
	content: "\e622";
}
.imind-align-right:before {
	content: "\e623";
}
.imind-alpha:before {
	content: "\e624";
}
.imind-ambulance:before {
	content: "\e625";
}
.imind-amx:before {
	content: "\e626";
}
.imind-anchor-2:before {
	content: "\e627";
}
.imind-anchor:before {
	content: "\e628";
}
.imind-android-store:before {
	content: "\e629";
}
.imind-android:before {
	content: "\e62a";
}
.imind-angel-smiley:before {
	content: "\e62b";
}
.imind-angel:before {
	content: "\e62c";
}
.imind-angry:before {
	content: "\e62d";
}
.imind-apple-bite:before {
	content: "\e62e";
}
.imind-apple-store:before {
	content: "\e62f";
}
.imind-apple:before {
	content: "\e630";
}
.imind-approved-window:before {
	content: "\e631";
}
.imind-aquarius-2:before {
	content: "\e632";
}
.imind-aquarius:before {
	content: "\e633";
}
.imind-archery-2:before {
	content: "\e634";
}
.imind-archery:before {
	content: "\e635";
}
.imind-argentina:before {
	content: "\e636";
}
.imind-aries-2:before {
	content: "\e637";
}
.imind-aries:before {
	content: "\e638";
}
.imind-army-key:before {
	content: "\e639";
}
.imind-arrow-around:before {
	content: "\e63a";
}
.imind-arrow-back3:before {
	content: "\e63b";
}
.imind-arrow-back:before {
	content: "\e63c";
}
.imind-arrow-back2:before {
	content: "\e63d";
}
.imind-arrow-barrier:before {
	content: "\e63e";
}
.imind-arrow-circle:before {
	content: "\e63f";
}
.imind-arrow-cross:before {
	content: "\e640";
}
.imind-arrow-down:before {
	content: "\e641";
}
.imind-arrow-down2:before {
	content: "\e642";
}
.imind-arrow-down3:before {
	content: "\e643";
}
.imind-arrow-downincircle:before {
	content: "\e644";
}
.imind-arrow-fork:before {
	content: "\e645";
}
.imind-arrow-forward:before {
	content: "\e646";
}
.imind-arrow-forward2:before {
	content: "\e647";
}
.imind-arrow-from:before {
	content: "\e648";
}
.imind-arrow-inside:before {
	content: "\e649";
}
.imind-arrow-inside45:before {
	content: "\e64a";
}
.imind-arrow-insidegap:before {
	content: "\e64b";
}
.imind-arrow-insidegap45:before {
	content: "\e64c";
}
.imind-arrow-into:before {
	content: "\e64d";
}
.imind-arrow-join:before {
	content: "\e64e";
}
.imind-arrow-junction:before {
	content: "\e64f";
}
.imind-arrow-left:before {
	content: "\e650";
}
.imind-arrow-left2:before {
	content: "\e651";
}
.imind-arrow-leftincircle:before {
	content: "\e652";
}
.imind-arrow-loop:before {
	content: "\e653";
}
.imind-arrow-merge:before {
	content: "\e654";
}
.imind-arrow-mix:before {
	content: "\e655";
}
.imind-arrow-next:before {
	content: "\e656";
}
.imind-arrow-outleft:before {
	content: "\e657";
}
.imind-arrow-outright:before {
	content: "\e658";
}
.imind-arrow-outside:before {
	content: "\e659";
}
.imind-arrow-outside45:before {
	content: "\e65a";
}
.imind-arrow-outsidegap:before {
	content: "\e65b";
}
.imind-arrow-outsidegap45:before {
	content: "\e65c";
}
.imind-arrow-over:before {
	content: "\e65d";
}
.imind-arrow-refresh:before {
	content: "\e65e";
}
.imind-arrow-refresh2:before {
	content: "\e65f";
}
.imind-arrow-right:before {
	content: "\e660";
}
.imind-arrow-right2:before {
	content: "\e661";
}
.imind-arrow-rightincircle:before {
	content: "\e662";
}
.imind-arrow-shuffle:before {
	content: "\e663";
}
.imind-arrow-squiggly:before {
	content: "\e664";
}
.imind-arrow-through:before {
	content: "\e665";
}
.imind-arrow-to:before {
	content: "\e666";
}
.imind-arrow-turnleft:before {
	content: "\e667";
}
.imind-arrow-turnright:before {
	content: "\e668";
}
.imind-arrow-up:before {
	content: "\e669";
}
.imind-arrow-up2:before {
	content: "\e66a";
}
.imind-arrow-up3:before {
	content: "\e66b";
}
.imind-arrow-upincircle:before {
	content: "\e66c";
}
.imind-arrow-xleft:before {
	content: "\e66d";
}
.imind-arrow-xright:before {
	content: "\e66e";
}
.imind-ask:before {
	content: "\e66f";
}
.imind-assistant:before {
	content: "\e670";
}
.imind-astronaut:before {
	content: "\e671";
}
.imind-at-sign:before {
	content: "\e672";
}
.imind-atm:before {
	content: "\e673";
}
.imind-atom:before {
	content: "\e674";
}
.imind-audio:before {
	content: "\e675";
}
.imind-auto-flash:before {
	content: "\e676";
}
.imind-autumn:before {
	content: "\e677";
}
.imind-baby-clothes:before {
	content: "\e678";
}
.imind-baby-clothes2:before {
	content: "\e679";
}
.imind-baby-cry:before {
	content: "\e67a";
}
.imind-baby:before {
	content: "\e67b";
}
.imind-back2:before {
	content: "\e67c";
}
.imind-back-media:before {
	content: "\e67d";
}
.imind-back-music:before {
	content: "\e67e";
}
.imind-back:before {
	content: "\e67f";
}
.imind-background:before {
	content: "\e680";
}
.imind-bacteria:before {
	content: "\e681";
}
.imind-bag-coins:before {
	content: "\e682";
}
.imind-bag-items:before {
	content: "\e683";
}
.imind-bag-quantity:before {
	content: "\e684";
}
.imind-bag:before {
	content: "\e685";
}
.imind-bakelite:before {
	content: "\e686";
}
.imind-ballet-shoes:before {
	content: "\e687";
}
.imind-balloon:before {
	content: "\e688";
}
.imind-banana:before {
	content: "\e689";
}
.imind-band-aid:before {
	content: "\e68a";
}
.imind-bank:before {
	content: "\e68b";
}
.imind-bar-chart:before {
	content: "\e68c";
}
.imind-bar-chart2:before {
	content: "\e68d";
}
.imind-bar-chart3:before {
	content: "\e68e";
}
.imind-bar-chart4:before {
	content: "\e68f";
}
.imind-bar-chart5:before {
	content: "\e690";
}
.imind-bar-code:before {
	content: "\e691";
}
.imind-barricade-2:before {
	content: "\e692";
}
.imind-barricade:before {
	content: "\e693";
}
.imind-baseball:before {
	content: "\e694";
}
.imind-basket-ball:before {
	content: "\e695";
}
.imind-basket-coins:before {
	content: "\e696";
}
.imind-basket-items:before {
	content: "\e697";
}
.imind-basket-quantity:before {
	content: "\e698";
}
.imind-bat-2:before {
	content: "\e699";
}
.imind-bat:before {
	content: "\e69a";
}
.imind-bathrobe:before {
	content: "\e69b";
}
.imind-batman-mask:before {
	content: "\e69c";
}
.imind-battery-0:before {
	content: "\e69d";
}
.imind-battery-25:before {
	content: "\e69e";
}
.imind-battery-50:before {
	content: "\e69f";
}
.imind-battery-75:before {
	content: "\e6a0";
}
.imind-battery-100:before {
	content: "\e6a1";
}
.imind-battery-charge:before {
	content: "\e6a2";
}
.imind-bear:before {
	content: "\e6a3";
}
.imind-beard-2:before {
	content: "\e6a4";
}
.imind-beard-3:before {
	content: "\e6a5";
}
.imind-beard:before {
	content: "\e6a6";
}
.imind-bebo:before {
	content: "\e6a7";
}
.imind-bee:before {
	content: "\e6a8";
}
.imind-beer-glass:before {
	content: "\e6a9";
}
.imind-beer:before {
	content: "\e6aa";
}
.imind-bell-2:before {
	content: "\e6ab";
}
.imind-bell:before {
	content: "\e6ac";
}
.imind-belt-2:before {
	content: "\e6ad";
}
.imind-belt-3:before {
	content: "\e6ae";
}
.imind-belt:before {
	content: "\e6af";
}
.imind-berlin-tower:before {
	content: "\e6b0";
}
.imind-beta:before {
	content: "\e6b1";
}
.imind-betvibes:before {
	content: "\e6b2";
}
.imind-bicycle-2:before {
	content: "\e6b3";
}
.imind-bicycle-3:before {
	content: "\e6b4";
}
.imind-bicycle:before {
	content: "\e6b5";
}
.imind-big-bang:before {
	content: "\e6b6";
}
.imind-big-data:before {
	content: "\e6b7";
}
.imind-bike-helmet:before {
	content: "\e6b8";
}
.imind-bikini:before {
	content: "\e6b9";
}
.imind-bilk-bottle2:before {
	content: "\e6ba";
}
.imind-billing:before {
	content: "\e6bb";
}
.imind-bing:before {
	content: "\e6bc";
}
.imind-binocular:before {
	content: "\e6bd";
}
.imind-bio-hazard:before {
	content: "\e6be";
}
.imind-biotech:before {
	content: "\e6bf";
}
.imind-bird-deliveringletter:before {
	content: "\e6c0";
}
.imind-bird:before {
	content: "\e6c1";
}
.imind-birthday-cake:before {
	content: "\e6c2";
}
.imind-bisexual:before {
	content: "\e6c3";
}
.imind-bishop:before {
	content: "\e6c4";
}
.imind-bitcoin:before {
	content: "\e6c5";
}
.imind-black-cat:before {
	content: "\e6c6";
}
.imind-blackboard:before {
	content: "\e6c7";
}
.imind-blinklist:before {
	content: "\e6c8";
}
.imind-block-cloud:before {
	content: "\e6c9";
}
.imind-block-window:before {
	content: "\e6ca";
}
.imind-blogger:before {
	content: "\e6cb";
}
.imind-blood:before {
	content: "\e6cc";
}
.imind-blouse:before {
	content: "\e6cd";
}
.imind-blueprint:before {
	content: "\e6ce";
}
.imind-board:before {
	content: "\e6cf";
}
.imind-bodybuilding:before {
	content: "\e6d0";
}
.imind-bold-text:before {
	content: "\e6d1";
}
.imind-bone:before {
	content: "\e6d2";
}
.imind-bones:before {
	content: "\e6d3";
}
.imind-book:before {
	content: "\e6d4";
}
.imind-bookmark:before {
	content: "\e6d5";
}
.imind-books-2:before {
	content: "\e6d6";
}
.imind-books:before {
	content: "\e6d7";
}
.imind-boom:before {
	content: "\e6d8";
}
.imind-boot-2:before {
	content: "\e6d9";
}
.imind-boot:before {
	content: "\e6da";
}
.imind-bottom-totop:before {
	content: "\e6db";
}
.imind-bow-2:before {
	content: "\e6dc";
}
.imind-bow-3:before {
	content: "\e6dd";
}
.imind-bow-4:before {
	content: "\e6de";
}
.imind-bow-5:before {
	content: "\e6df";
}
.imind-bow-6:before {
	content: "\e6e0";
}
.imind-bow:before {
	content: "\e6e1";
}
.imind-bowling-2:before {
	content: "\e6e2";
}
.imind-bowling:before {
	content: "\e6e3";
}
.imind-box2:before {
	content: "\e6e4";
}
.imind-box-close:before {
	content: "\e6e5";
}
.imind-box-full:before {
	content: "\e6e6";
}
.imind-box-open:before {
	content: "\e6e7";
}
.imind-box-withfolders:before {
	content: "\e6e8";
}
.imind-box:before {
	content: "\e6e9";
}
.imind-boy:before {
	content: "\e6ea";
}
.imind-bra:before {
	content: "\e6eb";
}
.imind-brain-2:before {
	content: "\e6ec";
}
.imind-brain-3:before {
	content: "\e6ed";
}
.imind-brain:before {
	content: "\e6ee";
}
.imind-brazil:before {
	content: "\e6ef";
}
.imind-bread-2:before {
	content: "\e6f0";
}
.imind-bread:before {
	content: "\e6f1";
}
.imind-bridge:before {
	content: "\e6f2";
}
.imind-brightkite:before {
	content: "\e6f3";
}
.imind-broke-link2:before {
	content: "\e6f4";
}
.imind-broken-link:before {
	content: "\e6f5";
}
.imind-broom:before {
	content: "\e6f6";
}
.imind-brush:before {
	content: "\e6f7";
}
.imind-bucket:before {
	content: "\e6f8";
}
.imind-bug:before {
	content: "\e6f9";
}
.imind-building:before {
	content: "\e6fa";
}
.imind-bulleted-list:before {
	content: "\e6fb";
}
.imind-bus-2:before {
	content: "\e6fc";
}
.imind-bus:before {
	content: "\e6fd";
}
.imind-business-man:before {
	content: "\e6fe";
}
.imind-business-manwoman:before {
	content: "\e6ff";
}
.imind-business-mens:before {
	content: "\e700";
}
.imind-business-woman:before {
	content: "\e701";
}
.imind-butterfly:before {
	content: "\e702";
}
.imind-button:before {
	content: "\e703";
}
.imind-cable-car:before {
	content: "\e704";
}
.imind-cake:before {
	content: "\e705";
}
.imind-calculator-2:before {
	content: "\e706";
}
.imind-calculator-3:before {
	content: "\e707";
}
.imind-calculator:before {
	content: "\e708";
}
.imind-calendar-2:before {
	content: "\e709";
}
.imind-calendar-3:before {
	content: "\e70a";
}
.imind-calendar-4:before {
	content: "\e70b";
}
.imind-calendar-clock:before {
	content: "\e70c";
}
.imind-calendar:before {
	content: "\e70d";
}
.imind-camel:before {
	content: "\e70e";
}
.imind-camera-2:before {
	content: "\e70f";
}
.imind-camera-3:before {
	content: "\e710";
}
.imind-camera-4:before {
	content: "\e711";
}
.imind-camera-5:before {
	content: "\e712";
}
.imind-camera-back:before {
	content: "\e713";
}
.imind-camera:before {
	content: "\e714";
}
.imind-can-2:before {
	content: "\e715";
}
.imind-can:before {
	content: "\e716";
}
.imind-canada:before {
	content: "\e717";
}
.imind-cancer-2:before {
	content: "\e718";
}
.imind-cancer-3:before {
	content: "\e719";
}
.imind-cancer:before {
	content: "\e71a";
}
.imind-candle:before {
	content: "\e71b";
}
.imind-candy-cane:before {
	content: "\e71c";
}
.imind-candy:before {
	content: "\e71d";
}
.imind-cannon:before {
	content: "\e71e";
}
.imind-cap-2:before {
	content: "\e71f";
}
.imind-cap-3:before {
	content: "\e720";
}
.imind-cap-smiley:before {
	content: "\e721";
}
.imind-cap:before {
	content: "\e722";
}
.imind-capricorn-2:before {
	content: "\e723";
}
.imind-capricorn:before {
	content: "\e724";
}
.imind-car-2:before {
	content: "\e725";
}
.imind-car-3:before {
	content: "\e726";
}
.imind-car-coins:before {
	content: "\e727";
}
.imind-car-items:before {
	content: "\e728";
}
.imind-car-wheel:before {
	content: "\e729";
}
.imind-car:before {
	content: "\e72a";
}
.imind-cardigan:before {
	content: "\e72b";
}
.imind-cardiovascular:before {
	content: "\e72c";
}
.imind-cart-quantity:before {
	content: "\e72d";
}
.imind-casette-tape:before {
	content: "\e72e";
}
.imind-cash-register:before {
	content: "\e72f";
}
.imind-cash-register2:before {
	content: "\e730";
}
.imind-castle:before {
	content: "\e731";
}
.imind-cat:before {
	content: "\e732";
}
.imind-cathedral:before {
	content: "\e733";
}
.imind-cauldron:before {
	content: "\e734";
}
.imind-cd-2:before {
	content: "\e735";
}
.imind-cd-cover:before {
	content: "\e736";
}
.imind-cd:before {
	content: "\e737";
}
.imind-cello:before {
	content: "\e738";
}
.imind-celsius:before {
	content: "\e739";
}
.imind-chacked-flag:before {
	content: "\e73a";
}
.imind-chair:before {
	content: "\e73b";
}
.imind-charger:before {
	content: "\e73c";
}
.imind-check-2:before {
	content: "\e73d";
}
.imind-check:before {
	content: "\e73e";
}
.imind-checked-user:before {
	content: "\e73f";
}
.imind-checkmate:before {
	content: "\e740";
}
.imind-checkout-bag:before {
	content: "\e741";
}
.imind-checkout-basket:before {
	content: "\e742";
}
.imind-checkout:before {
	content: "\e743";
}
.imind-cheese:before {
	content: "\e744";
}
.imind-cheetah:before {
	content: "\e745";
}
.imind-chef-hat:before {
	content: "\e746";
}
.imind-chef-hat2:before {
	content: "\e747";
}
.imind-chef:before {
	content: "\e748";
}
.imind-chemical-2:before {
	content: "\e749";
}
.imind-chemical-3:before {
	content: "\e74a";
}
.imind-chemical-4:before {
	content: "\e74b";
}
.imind-chemical-5:before {
	content: "\e74c";
}
.imind-chemical:before {
	content: "\e74d";
}
.imind-chess-board:before {
	content: "\e74e";
}
.imind-chess:before {
	content: "\e74f";
}
.imind-chicken:before {
	content: "\e750";
}
.imind-chile:before {
	content: "\e751";
}
.imind-chimney:before {
	content: "\e752";
}
.imind-china:before {
	content: "\e753";
}
.imind-chinese-temple:before {
	content: "\e754";
}
.imind-chip:before {
	content: "\e755";
}
.imind-chopsticks-2:before {
	content: "\e756";
}
.imind-chopsticks:before {
	content: "\e757";
}
.imind-christmas-ball:before {
	content: "\e758";
}
.imind-christmas-bell:before {
	content: "\e759";
}
.imind-christmas-candle:before {
	content: "\e75a";
}
.imind-christmas-hat:before {
	content: "\e75b";
}
.imind-christmas-sleigh:before {
	content: "\e75c";
}
.imind-christmas-snowman:before {
	content: "\e75d";
}
.imind-christmas-sock:before {
	content: "\e75e";
}
.imind-christmas-tree:before {
	content: "\e75f";
}
.imind-christmas:before {
	content: "\e760";
}
.imind-chrome:before {
	content: "\e761";
}
.imind-chrysler-building:before {
	content: "\e762";
}
.imind-cinema:before {
	content: "\e763";
}
.imind-circular-point:before {
	content: "\e764";
}
.imind-city-hall:before {
	content: "\e765";
}
.imind-clamp:before {
	content: "\e766";
}
.imind-clapperboard-close:before {
	content: "\e767";
}
.imind-clapperboard-open:before {
	content: "\e768";
}
.imind-claps:before {
	content: "\e769";
}
.imind-clef:before {
	content: "\e76a";
}
.imind-clinic:before {
	content: "\e76b";
}
.imind-clock-2:before {
	content: "\e76c";
}
.imind-clock-3:before {
	content: "\e76d";
}
.imind-clock-4:before {
	content: "\e76e";
}
.imind-clock-back:before {
	content: "\e76f";
}
.imind-clock-forward:before {
	content: "\e770";
}
.imind-clock:before {
	content: "\e771";
}
.imind-close-window:before {
	content: "\e772";
}
.imind-close:before {
	content: "\e773";
}
.imind-clothing-store:before {
	content: "\e774";
}
.imind-cloud--:before {
	content: "\e775";
}
.imind-cloud-:before {
	content: "\e776";
}
.imind-cloud-camera:before {
	content: "\e777";
}
.imind-cloud-computer:before {
	content: "\e778";
}
.imind-cloud-email:before {
	content: "\e779";
}
.imind-cloud-hail:before {
	content: "\e77a";
}
.imind-cloud-laptop:before {
	content: "\e77b";
}
.imind-cloud-lock:before {
	content: "\e77c";
}
.imind-cloud-moon:before {
	content: "\e77d";
}
.imind-cloud-music:before {
	content: "\e77e";
}
.imind-cloud-picture:before {
	content: "\e77f";
}
.imind-cloud-rain:before {
	content: "\e780";
}
.imind-cloud-remove:before {
	content: "\e781";
}
.imind-cloud-secure:before {
	content: "\e782";
}
.imind-cloud-settings:before {
	content: "\e783";
}
.imind-cloud-smartphone:before {
	content: "\e784";
}
.imind-cloud-snow:before {
	content: "\e785";
}
.imind-cloud-sun:before {
	content: "\e786";
}
.imind-cloud-tablet:before {
	content: "\e787";
}
.imind-cloud-video:before {
	content: "\e788";
}
.imind-cloud-weather:before {
	content: "\e789";
}
.imind-cloud:before {
	content: "\e78a";
}
.imind-clouds-weather:before {
	content: "\e78b";
}
.imind-clouds:before {
	content: "\e78c";
}
.imind-clown:before {
	content: "\e78d";
}
.imind-cmyk:before {
	content: "\e78e";
}
.imind-coat:before {
	content: "\e78f";
}
.imind-cocktail:before {
	content: "\e790";
}
.imind-coconut:before {
	content: "\e791";
}
.imind-code-window:before {
	content: "\e792";
}
.imind-coding:before {
	content: "\e793";
}
.imind-coffee-2:before {
	content: "\e794";
}
.imind-coffee-bean:before {
	content: "\e795";
}
.imind-coffee-machine:before {
	content: "\e796";
}
.imind-coffee-togo:before {
	content: "\e797";
}
.imind-coffee:before {
	content: "\e798";
}
.imind-coffin:before {
	content: "\e799";
}
.imind-coin:before {
	content: "\e79a";
}
.imind-coins-2:before {
	content: "\e79b";
}
.imind-coins-3:before {
	content: "\e79c";
}
.imind-coins:before {
	content: "\e79d";
}
.imind-colombia:before {
	content: "\e79e";
}
.imind-colosseum:before {
	content: "\e79f";
}
.imind-column-2:before {
	content: "\e7a0";
}
.imind-column-3:before {
	content: "\e7a1";
}
.imind-column:before {
	content: "\e7a2";
}
.imind-comb-2:before {
	content: "\e7a3";
}
.imind-comb:before {
	content: "\e7a4";
}
.imind-communication-tower:before {
	content: "\e7a5";
}
.imind-communication-tower2:before {
	content: "\e7a6";
}
.imind-compass-2:before {
	content: "\e7a7";
}
.imind-compass-3:before {
	content: "\e7a8";
}
.imind-compass-4:before {
	content: "\e7a9";
}
.imind-compass-rose:before {
	content: "\e7aa";
}
.imind-compass:before {
	content: "\e7ab";
}
.imind-computer-2:before {
	content: "\e7ac";
}
.imind-computer-3:before {
	content: "\e7ad";
}
.imind-computer-secure:before {
	content: "\e7ae";
}
.imind-computer:before {
	content: "\e7af";
}
.imind-conference:before {
	content: "\e7b0";
}
.imind-confused:before {
	content: "\e7b1";
}
.imind-conservation:before {
	content: "\e7b2";
}
.imind-consulting:before {
	content: "\e7b3";
}
.imind-contrast:before {
	content: "\e7b4";
}
.imind-control-2:before {
	content: "\e7b5";
}
.imind-control:before {
	content: "\e7b6";
}
.imind-cookie-man:before {
	content: "\e7b7";
}
.imind-cookies:before {
	content: "\e7b8";
}
.imind-cool-guy:before {
	content: "\e7b9";
}
.imind-cool:before {
	content: "\e7ba";
}
.imind-copyright:before {
	content: "\e7bb";
}
.imind-costume:before {
	content: "\e7bc";
}
.imind-couple-sign:before {
	content: "\e7bd";
}
.imind-cow:before {
	content: "\e7be";
}
.imind-cpu:before {
	content: "\e7bf";
}
.imind-crane:before {
	content: "\e7c0";
}
.imind-cranium:before {
	content: "\e7c1";
}
.imind-credit-card:before {
	content: "\e7c2";
}
.imind-credit-card2:before {
	content: "\e7c3";
}
.imind-credit-card3:before {
	content: "\e7c4";
}
.imind-cricket:before {
	content: "\e7c5";
}
.imind-criminal:before {
	content: "\e7c6";
}
.imind-croissant:before {
	content: "\e7c7";
}
.imind-crop-2:before {
	content: "\e7c8";
}
.imind-crop-3:before {
	content: "\e7c9";
}
.imind-crown-2:before {
	content: "\e7ca";
}
.imind-crown:before {
	content: "\e7cb";
}
.imind-crying:before {
	content: "\e7cc";
}
.imind-cube-molecule:before {
	content: "\e7cd";
}
.imind-cube-molecule2:before {
	content: "\e7ce";
}
.imind-cupcake:before {
	content: "\e7cf";
}
.imind-cursor-click:before {
	content: "\e7d0";
}
.imind-cursor-click2:before {
	content: "\e7d1";
}
.imind-cursor-move:before {
	content: "\e7d2";
}
.imind-cursor-move2:before {
	content: "\e7d3";
}
.imind-cursor-select:before {
	content: "\e7d4";
}
.imind-cursor:before {
	content: "\e7d5";
}
.imind-d-eyeglasses:before {
	content: "\e7d6";
}
.imind-d-eyeglasses2:before {
	content: "\e7d7";
}
.imind-dam:before {
	content: "\e7d8";
}
.imind-danemark:before {
	content: "\e7d9";
}
.imind-danger-2:before {
	content: "\e7da";
}
.imind-danger:before {
	content: "\e7db";
}
.imind-dashboard:before {
	content: "\e7dc";
}
.imind-data-backup:before {
	content: "\e7dd";
}
.imind-data-block:before {
	content: "\e7de";
}
.imind-data-center:before {
	content: "\e7df";
}
.imind-data-clock:before {
	content: "\e7e0";
}
.imind-data-cloud:before {
	content: "\e7e1";
}
.imind-data-compress:before {
	content: "\e7e2";
}
.imind-data-copy:before {
	content: "\e7e3";
}
.imind-data-download:before {
	content: "\e7e4";
}
.imind-data-financial:before {
	content: "\e7e5";
}
.imind-data-key:before {
	content: "\e7e6";
}
.imind-data-lock:before {
	content: "\e7e7";
}
.imind-data-network:before {
	content: "\e7e8";
}
.imind-data-password:before {
	content: "\e7e9";
}
.imind-data-power:before {
	content: "\e7ea";
}
.imind-data-refresh:before {
	content: "\e7eb";
}
.imind-data-save:before {
	content: "\e7ec";
}
.imind-data-search:before {
	content: "\e7ed";
}
.imind-data-security:before {
	content: "\e7ee";
}
.imind-data-settings:before {
	content: "\e7ef";
}
.imind-data-sharing:before {
	content: "\e7f0";
}
.imind-data-shield:before {
	content: "\e7f1";
}
.imind-data-signal:before {
	content: "\e7f2";
}
.imind-data-storage:before {
	content: "\e7f3";
}
.imind-data-stream:before {
	content: "\e7f4";
}
.imind-data-transfer:before {
	content: "\e7f5";
}
.imind-data-unlock:before {
	content: "\e7f6";
}
.imind-data-upload:before {
	content: "\e7f7";
}
.imind-data-yes:before {
	content: "\e7f8";
}
.imind-data:before {
	content: "\e7f9";
}
.imind-david-star:before {
	content: "\e7fa";
}
.imind-daylight:before {
	content: "\e7fb";
}
.imind-death:before {
	content: "\e7fc";
}
.imind-debian:before {
	content: "\e7fd";
}
.imind-dec:before {
	content: "\e7fe";
}
.imind-decrase-inedit:before {
	content: "\e7ff";
}
.imind-deer-2:before {
	content: "\e800";
}
.imind-deer:before {
	content: "\e801";
}
.imind-delete-file:before {
	content: "\e802";
}
.imind-delete-window:before {
	content: "\e803";
}
.imind-delicious:before {
	content: "\e804";
}
.imind-depression:before {
	content: "\e805";
}
.imind-deviantart:before {
	content: "\e806";
}
.imind-device-syncwithcloud:before {
	content: "\e807";
}
.imind-diamond:before {
	content: "\e808";
}
.imind-dice-2:before {
	content: "\e809";
}
.imind-dice:before {
	content: "\e80a";
}
.imind-digg:before {
	content: "\e80b";
}
.imind-digital-drawing:before {
	content: "\e80c";
}
.imind-diigo:before {
	content: "\e80d";
}
.imind-dinosaur:before {
	content: "\e80e";
}
.imind-diploma-2:before {
	content: "\e80f";
}
.imind-diploma:before {
	content: "\e810";
}
.imind-direction-east:before {
	content: "\e811";
}
.imind-direction-north:before {
	content: "\e812";
}
.imind-direction-south:before {
	content: "\e813";
}
.imind-direction-west:before {
	content: "\e814";
}
.imind-director:before {
	content: "\e815";
}
.imind-disk:before {
	content: "\e816";
}
.imind-dj:before {
	content: "\e817";
}
.imind-dna-2:before {
	content: "\e818";
}
.imind-dna-helix:before {
	content: "\e819";
}
.imind-dna:before {
	content: "\e81a";
}
.imind-doctor:before {
	content: "\e81b";
}
.imind-dog:before {
	content: "\e81c";
}
.imind-dollar-sign:before {
	content: "\e81d";
}
.imind-dollar-sign2:before {
	content: "\e81e";
}
.imind-dollar:before {
	content: "\e81f";
}
.imind-dolphin:before {
	content: "\e820";
}
.imind-domino:before {
	content: "\e821";
}
.imind-door-hanger:before {
	content: "\e822";
}
.imind-door:before {
	content: "\e823";
}
.imind-doplr:before {
	content: "\e824";
}
.imind-double-circle:before {
	content: "\e825";
}
.imind-double-tap:before {
	content: "\e826";
}
.imind-doughnut:before {
	content: "\e827";
}
.imind-dove:before {
	content: "\e828";
}
.imind-down-2:before {
	content: "\e829";
}
.imind-down-3:before {
	content: "\e82a";
}
.imind-down-4:before {
	content: "\e82b";
}
.imind-down:before {
	content: "\e82c";
}
.imind-download-2:before {
	content: "\e82d";
}
.imind-download-fromcloud:before {
	content: "\e82e";
}
.imind-download-window:before {
	content: "\e82f";
}
.imind-download:before {
	content: "\e830";
}
.imind-downward:before {
	content: "\e831";
}
.imind-drag-down:before {
	content: "\e832";
}
.imind-drag-left:before {
	content: "\e833";
}
.imind-drag-right:before {
	content: "\e834";
}
.imind-drag-up:before {
	content: "\e835";
}
.imind-drag:before {
	content: "\e836";
}
.imind-dress:before {
	content: "\e837";
}
.imind-drill-2:before {
	content: "\e838";
}
.imind-drill:before {
	content: "\e839";
}
.imind-drop:before {
	content: "\e83a";
}
.imind-dropbox:before {
	content: "\e83b";
}
.imind-drum:before {
	content: "\e83c";
}
.imind-dry:before {
	content: "\e83d";
}
.imind-duck:before {
	content: "\e83e";
}
.imind-dumbbell:before {
	content: "\e83f";
}
.imind-duplicate-layer:before {
	content: "\e840";
}
.imind-duplicate-window:before {
	content: "\e841";
}
.imind-dvd:before {
	content: "\e842";
}
.imind-eagle:before {
	content: "\e843";
}
.imind-ear:before {
	content: "\e844";
}
.imind-earphones-2:before {
	content: "\e845";
}
.imind-earphones:before {
	content: "\e846";
}
.imind-eci-icon:before {
	content: "\e847";
}
.imind-edit-map:before {
	content: "\e848";
}
.imind-edit:before {
	content: "\e849";
}
.imind-eggs:before {
	content: "\e84a";
}
.imind-egypt:before {
	content: "\e84b";
}
.imind-eifel-tower:before {
	content: "\e84c";
}
.imind-eject-2:before {
	content: "\e84d";
}
.imind-eject:before {
	content: "\e84e";
}
.imind-el-castillo:before {
	content: "\e84f";
}
.imind-elbow:before {
	content: "\e850";
}
.imind-electric-guitar:before {
	content: "\e851";
}
.imind-electricity:before {
	content: "\e852";
}
.imind-elephant:before {
	content: "\e853";
}
.imind-email:before {
	content: "\e854";
}
.imind-embassy:before {
	content: "\e855";
}
.imind-empire-statebuilding:before {
	content: "\e856";
}
.imind-empty-box:before {
	content: "\e857";
}
.imind-end2:before {
	content: "\e858";
}
.imind-end-2:before {
	content: "\e859";
}
.imind-end:before {
	content: "\e85a";
}
.imind-endways:before {
	content: "\e85b";
}
.imind-engineering:before {
	content: "\e85c";
}
.imind-envelope-2:before {
	content: "\e85d";
}
.imind-envelope:before {
	content: "\e85e";
}
.imind-environmental-2:before {
	content: "\e85f";
}
.imind-environmental-3:before {
	content: "\e860";
}
.imind-environmental:before {
	content: "\e861";
}
.imind-equalizer:before {
	content: "\e862";
}
.imind-eraser-2:before {
	content: "\e863";
}
.imind-eraser-3:before {
	content: "\e864";
}
.imind-eraser:before {
	content: "\e865";
}
.imind-error-404window:before {
	content: "\e866";
}
.imind-euro-sign:before {
	content: "\e867";
}
.imind-euro-sign2:before {
	content: "\e868";
}
.imind-euro:before {
	content: "\e869";
}
.imind-evernote:before {
	content: "\e86a";
}
.imind-evil:before {
	content: "\e86b";
}
.imind-explode:before {
	content: "\e86c";
}
.imind-eye-2:before {
	content: "\e86d";
}
.imind-eye-blind:before {
	content: "\e86e";
}
.imind-eye-invisible:before {
	content: "\e86f";
}
.imind-eye-scan:before {
	content: "\e870";
}
.imind-eye-visible:before {
	content: "\e871";
}
.imind-eye:before {
	content: "\e872";
}
.imind-eyebrow-2:before {
	content: "\e873";
}
.imind-eyebrow-3:before {
	content: "\e874";
}
.imind-eyebrow:before {
	content: "\e875";
}
.imind-eyeglasses-smiley:before {
	content: "\e876";
}
.imind-eyeglasses-smiley2:before {
	content: "\e877";
}
.imind-face-style:before {
	content: "\e878";
}
.imind-face-style2:before {
	content: "\e879";
}
.imind-face-style3:before {
	content: "\e87a";
}
.imind-face-style4:before {
	content: "\e87b";
}
.imind-face-style5:before {
	content: "\e87c";
}
.imind-face-style6:before {
	content: "\e87d";
}
.imind-facebook-2:before {
	content: "\e87e";
}
.imind-facebook:before {
	content: "\e87f";
}
.imind-factory-2:before {
	content: "\e880";
}
.imind-factory:before {
	content: "\e881";
}
.imind-fahrenheit:before {
	content: "\e882";
}
.imind-family-sign:before {
	content: "\e883";
}
.imind-fan:before {
	content: "\e884";
}
.imind-farmer:before {
	content: "\e885";
}
.imind-fashion:before {
	content: "\e886";
}
.imind-favorite-window:before {
	content: "\e887";
}
.imind-fax:before {
	content: "\e888";
}
.imind-feather:before {
	content: "\e889";
}
.imind-feedburner:before {
	content: "\e88a";
}
.imind-female-2:before {
	content: "\e88b";
}
.imind-female-sign:before {
	content: "\e88c";
}
.imind-female:before {
	content: "\e88d";
}
.imind-file-block:before {
	content: "\e88e";
}
.imind-file-bookmark:before {
	content: "\e88f";
}
.imind-file-chart:before {
	content: "\e890";
}
.imind-file-clipboard:before {
	content: "\e891";
}
.imind-file-clipboardfiletext:before {
	content: "\e892";
}
.imind-file-clipboardtextimage:before {
	content: "\e893";
}
.imind-file-cloud:before {
	content: "\e894";
}
.imind-file-copy:before {
	content: "\e895";
}
.imind-file-copy2:before {
	content: "\e896";
}
.imind-file-csv:before {
	content: "\e897";
}
.imind-file-download:before {
	content: "\e898";
}
.imind-file-edit:before {
	content: "\e899";
}
.imind-file-excel:before {
	content: "\e89a";
}
.imind-file-favorite:before {
	content: "\e89b";
}
.imind-file-fire:before {
	content: "\e89c";
}
.imind-file-graph:before {
	content: "\e89d";
}
.imind-file-hide:before {
	content: "\e89e";
}
.imind-file-horizontal:before {
	content: "\e89f";
}
.imind-file-horizontaltext:before {
	content: "\e8a0";
}
.imind-file-html:before {
	content: "\e8a1";
}
.imind-file-jpg:before {
	content: "\e8a2";
}
.imind-file-link:before {
	content: "\e8a3";
}
.imind-file-loading:before {
	content: "\e8a4";
}
.imind-file-lock:before {
	content: "\e8a5";
}
.imind-file-love:before {
	content: "\e8a6";
}
.imind-file-music:before {
	content: "\e8a7";
}
.imind-file-network:before {
	content: "\e8a8";
}
.imind-file-pictures:before {
	content: "\e8a9";
}
.imind-file-pie:before {
	content: "\e8aa";
}
.imind-file-presentation:before {
	content: "\e8ab";
}
.imind-file-refresh:before {
	content: "\e8ac";
}
.imind-file-search:before {
	content: "\e8ad";
}
.imind-file-settings:before {
	content: "\e8ae";
}
.imind-file-share:before {
	content: "\e8af";
}
.imind-file-textimage:before {
	content: "\e8b0";
}
.imind-file-trash:before {
	content: "\e8b1";
}
.imind-file-txt:before {
	content: "\e8b2";
}
.imind-file-upload:before {
	content: "\e8b3";
}
.imind-file-video:before {
	content: "\e8b4";
}
.imind-file-word:before {
	content: "\e8b5";
}
.imind-file-zip:before {
	content: "\e8b6";
}
.imind-file:before {
	content: "\e8b7";
}
.imind-files:before {
	content: "\e8b8";
}
.imind-film-board:before {
	content: "\e8b9";
}
.imind-film-cartridge:before {
	content: "\e8ba";
}
.imind-film-strip:before {
	content: "\e8bb";
}
.imind-film-video:before {
	content: "\e8bc";
}
.imind-film:before {
	content: "\e8bd";
}
.imind-filter-2:before {
	content: "\e8be";
}
.imind-filter:before {
	content: "\e8bf";
}
.imind-financial:before {
	content: "\e8c0";
}
.imind-find-user:before {
	content: "\e8c1";
}
.imind-finger-dragfoursides:before {
	content: "\e8c2";
}
.imind-finger-dragtwosides:before {
	content: "\e8c3";
}
.imind-finger-print:before {
	content: "\e8c4";
}
.imind-finger:before {
	content: "\e8c5";
}
.imind-fingerprint-2:before {
	content: "\e8c6";
}
.imind-fingerprint:before {
	content: "\e8c7";
}
.imind-fire-flame:before {
	content: "\e8c8";
}
.imind-fire-flame2:before {
	content: "\e8c9";
}
.imind-fire-hydrant:before {
	content: "\e8ca";
}
.imind-fire-staion:before {
	content: "\e8cb";
}
.imind-firefox:before {
	content: "\e8cc";
}
.imind-firewall:before {
	content: "\e8cd";
}
.imind-first-aid:before {
	content: "\e8ce";
}
.imind-first:before {
	content: "\e8cf";
}
.imind-fish-food:before {
	content: "\e8d0";
}
.imind-fish:before {
	content: "\e8d1";
}
.imind-fit-to:before {
	content: "\e8d2";
}
.imind-fit-to2:before {
	content: "\e8d3";
}
.imind-five-fingers:before {
	content: "\e8d4";
}
.imind-five-fingersdrag:before {
	content: "\e8d5";
}
.imind-five-fingersdrag2:before {
	content: "\e8d6";
}
.imind-five-fingerstouch:before {
	content: "\e8d7";
}
.imind-flag-2:before {
	content: "\e8d8";
}
.imind-flag-3:before {
	content: "\e8d9";
}
.imind-flag-4:before {
	content: "\e8da";
}
.imind-flag-5:before {
	content: "\e8db";
}
.imind-flag-6:before {
	content: "\e8dc";
}
.imind-flag:before {
	content: "\e8dd";
}
.imind-flamingo:before {
	content: "\e8de";
}
.imind-flash-2:before {
	content: "\e8df";
}
.imind-flash-video:before {
	content: "\e8e0";
}
.imind-flash:before {
	content: "\e8e1";
}
.imind-flashlight:before {
	content: "\e8e2";
}
.imind-flask-2:before {
	content: "\e8e3";
}
.imind-flask:before {
	content: "\e8e4";
}
.imind-flick:before {
	content: "\e8e5";
}
.imind-flickr:before {
	content: "\e8e6";
}
.imind-flowerpot:before {
	content: "\e8e7";
}
.imind-fluorescent:before {
	content: "\e8e8";
}
.imind-fog-day:before {
	content: "\e8e9";
}
.imind-fog-night:before {
	content: "\e8ea";
}
.imind-folder-add:before {
	content: "\e8eb";
}
.imind-folder-archive:before {
	content: "\e8ec";
}
.imind-folder-binder:before {
	content: "\e8ed";
}
.imind-folder-binder2:before {
	content: "\e8ee";
}
.imind-folder-block:before {
	content: "\e8ef";
}
.imind-folder-bookmark:before {
	content: "\e8f0";
}
.imind-folder-close:before {
	content: "\e8f1";
}
.imind-folder-cloud:before {
	content: "\e8f2";
}
.imind-folder-delete:before {
	content: "\e8f3";
}
.imind-folder-download:before {
	content: "\e8f4";
}
.imind-folder-edit:before {
	content: "\e8f5";
}
.imind-folder-favorite:before {
	content: "\e8f6";
}
.imind-folder-fire:before {
	content: "\e8f7";
}
.imind-folder-hide:before {
	content: "\e8f8";
}
.imind-folder-link:before {
	content: "\e8f9";
}
.imind-folder-loading:before {
	content: "\e8fa";
}
.imind-folder-lock:before {
	content: "\e8fb";
}
.imind-folder-love:before {
	content: "\e8fc";
}
.imind-folder-music:before {
	content: "\e8fd";
}
.imind-folder-network:before {
	content: "\e8fe";
}
.imind-folder-open:before {
	content: "\e8ff";
}
.imind-folder-open2:before {
	content: "\e900";
}
.imind-folder-organizing:before {
	content: "\e901";
}
.imind-folder-pictures:before {
	content: "\e902";
}
.imind-folder-refresh:before {
	content: "\e903";
}
.imind-folder-remove-:before {
	content: "\e904";
}
.imind-folder-search:before {
	content: "\e905";
}
.imind-folder-settings:before {
	content: "\e906";
}
.imind-folder-share:before {
	content: "\e907";
}
.imind-folder-trash:before {
	content: "\e908";
}
.imind-folder-upload:before {
	content: "\e909";
}
.imind-folder-video:before {
	content: "\e90a";
}
.imind-folder-withdocument:before {
	content: "\e90b";
}
.imind-folder-zip:before {
	content: "\e90c";
}
.imind-folder:before {
	content: "\e90d";
}
.imind-folders:before {
	content: "\e90e";
}
.imind-font-color:before {
	content: "\e90f";
}
.imind-font-name:before {
	content: "\e910";
}
.imind-font-size:before {
	content: "\e911";
}
.imind-font-style:before {
	content: "\e912";
}
.imind-font-stylesubscript:before {
	content: "\e913";
}
.imind-font-stylesuperscript:before {
	content: "\e914";
}
.imind-font-window:before {
	content: "\e915";
}
.imind-foot-2:before {
	content: "\e916";
}
.imind-foot:before {
	content: "\e917";
}
.imind-football-2:before {
	content: "\e918";
}
.imind-football:before {
	content: "\e919";
}
.imind-footprint-2:before {
	content: "\e91a";
}
.imind-footprint-3:before {
	content: "\e91b";
}
.imind-footprint:before {
	content: "\e91c";
}
.imind-forest:before {
	content: "\e91d";
}
.imind-fork:before {
	content: "\e91e";
}
.imind-formspring:before {
	content: "\e91f";
}
.imind-formula:before {
	content: "\e920";
}
.imind-forsquare:before {
	content: "\e921";
}
.imind-forward:before {
	content: "\e922";
}
.imind-fountain-pen:before {
	content: "\e923";
}
.imind-four-fingers:before {
	content: "\e924";
}
.imind-four-fingersdrag:before {
	content: "\e925";
}
.imind-four-fingersdrag2:before {
	content: "\e926";
}
.imind-four-fingerstouch:before {
	content: "\e927";
}
.imind-fox:before {
	content: "\e928";
}
.imind-frankenstein:before {
	content: "\e929";
}
.imind-french-fries:before {
	content: "\e92a";
}
.imind-friendfeed:before {
	content: "\e92b";
}
.imind-friendster:before {
	content: "\e92c";
}
.imind-frog:before {
	content: "\e92d";
}
.imind-fruits:before {
	content: "\e92e";
}
.imind-fuel:before {
	content: "\e92f";
}
.imind-full-bag:before {
	content: "\e930";
}
.imind-full-basket:before {
	content: "\e931";
}
.imind-full-cart:before {
	content: "\e932";
}
.imind-full-moon:before {
	content: "\e933";
}
.imind-full-screen:before {
	content: "\e934";
}
.imind-full-screen2:before {
	content: "\e935";
}
.imind-full-view:before {
	content: "\e936";
}
.imind-full-view2:before {
	content: "\e937";
}
.imind-full-viewwindow:before {
	content: "\e938";
}
.imind-function:before {
	content: "\e939";
}
.imind-funky:before {
	content: "\e93a";
}
.imind-funny-bicycle:before {
	content: "\e93b";
}
.imind-furl:before {
	content: "\e93c";
}
.imind-gamepad-2:before {
	content: "\e93d";
}
.imind-gamepad:before {
	content: "\e93e";
}
.imind-gas-pump:before {
	content: "\e93f";
}
.imind-gaugage-2:before {
	content: "\e940";
}
.imind-gaugage:before {
	content: "\e941";
}
.imind-gay:before {
	content: "\e942";
}
.imind-gear-2:before {
	content: "\e943";
}
.imind-gear:before {
	content: "\e944";
}
.imind-gears-2:before {
	content: "\e945";
}
.imind-gears:before {
	content: "\e946";
}
.imind-geek-2:before {
	content: "\e947";
}
.imind-geek:before {
	content: "\e948";
}
.imind-gemini-2:before {
	content: "\e949";
}
.imind-gemini:before {
	content: "\e94a";
}
.imind-genius:before {
	content: "\e94b";
}
.imind-gentleman:before {
	content: "\e94c";
}
.imind-geo--:before {
	content: "\e94d";
}
.imind-geo-:before {
	content: "\e94e";
}
.imind-geo-close:before {
	content: "\e94f";
}
.imind-geo-love:before {
	content: "\e950";
}
.imind-geo-number:before {
	content: "\e951";
}
.imind-geo-star:before {
	content: "\e952";
}
.imind-geo:before {
	content: "\e953";
}
.imind-geo2--:before {
	content: "\e954";
}
.imind-geo2-:before {
	content: "\e955";
}
.imind-geo2-close:before {
	content: "\e956";
}
.imind-geo2-love:before {
	content: "\e957";
}
.imind-geo2-number:before {
	content: "\e958";
}
.imind-geo2-star:before {
	content: "\e959";
}
.imind-geo2:before {
	content: "\e95a";
}
.imind-geo3--:before {
	content: "\e95b";
}
.imind-geo3-:before {
	content: "\e95c";
}
.imind-geo3-close:before {
	content: "\e95d";
}
.imind-geo3-love:before {
	content: "\e95e";
}
.imind-geo3-number:before {
	content: "\e95f";
}
.imind-geo3-star:before {
	content: "\e960";
}
.imind-geo3:before {
	content: "\e961";
}
.imind-gey:before {
	content: "\e962";
}
.imind-gift-box:before {
	content: "\e963";
}
.imind-giraffe:before {
	content: "\e964";
}
.imind-girl:before {
	content: "\e965";
}
.imind-glass-water:before {
	content: "\e966";
}
.imind-glasses-2:before {
	content: "\e967";
}
.imind-glasses-3:before {
	content: "\e968";
}
.imind-glasses:before {
	content: "\e969";
}
.imind-global-position:before {
	content: "\e96a";
}
.imind-globe-2:before {
	content: "\e96b";
}
.imind-globe:before {
	content: "\e96c";
}
.imind-gloves:before {
	content: "\e96d";
}
.imind-go-bottom:before {
	content: "\e96e";
}
.imind-go-top:before {
	content: "\e96f";
}
.imind-goggles:before {
	content: "\e970";
}
.imind-golf-2:before {
	content: "\e971";
}
.imind-golf:before {
	content: "\e972";
}
.imind-google-buzz:before {
	content: "\e973";
}
.imind-google-drive:before {
	content: "\e974";
}
.imind-google-play:before {
	content: "\e975";
}
.imind-google-plus:before {
	content: "\e976";
}
.imind-google:before {
	content: "\e977";
}
.imind-gopro:before {
	content: "\e978";
}
.imind-gorilla:before {
	content: "\e979";
}
.imind-gowalla:before {
	content: "\e97a";
}
.imind-grave:before {
	content: "\e97b";
}
.imind-graveyard:before {
	content: "\e97c";
}
.imind-greece:before {
	content: "\e97d";
}
.imind-green-energy:before {
	content: "\e97e";
}
.imind-green-house:before {
	content: "\e97f";
}
.imind-guitar:before {
	content: "\e980";
}
.imind-gun-2:before {
	content: "\e981";
}
.imind-gun-3:before {
	content: "\e982";
}
.imind-gun:before {
	content: "\e983";
}
.imind-gymnastics:before {
	content: "\e984";
}
.imind-hair-2:before {
	content: "\e985";
}
.imind-hair-3:before {
	content: "\e986";
}
.imind-hair-4:before {
	content: "\e987";
}
.imind-hair:before {
	content: "\e988";
}
.imind-half-moon:before {
	content: "\e989";
}
.imind-halloween-halfmoon:before {
	content: "\e98a";
}
.imind-halloween-moon:before {
	content: "\e98b";
}
.imind-hamburger:before {
	content: "\e98c";
}
.imind-hammer:before {
	content: "\e98d";
}
.imind-hand-touch:before {
	content: "\e98e";
}
.imind-hand-touch2:before {
	content: "\e98f";
}
.imind-hand-touchsmartphone:before {
	content: "\e990";
}
.imind-hand:before {
	content: "\e991";
}
.imind-hands:before {
	content: "\e992";
}
.imind-handshake:before {
	content: "\e993";
}
.imind-hanger:before {
	content: "\e994";
}
.imind-happy:before {
	content: "\e995";
}
.imind-hat-2:before {
	content: "\e996";
}
.imind-hat:before {
	content: "\e997";
}
.imind-haunted-house:before {
	content: "\e998";
}
.imind-hd-video:before {
	content: "\e999";
}
.imind-hd:before {
	content: "\e99a";
}
.imind-hdd:before {
	content: "\e99b";
}
.imind-headphone:before {
	content: "\e99c";
}
.imind-headphones:before {
	content: "\e99d";
}
.imind-headset:before {
	content: "\e99e";
}
.imind-heart-2:before {
	content: "\e99f";
}
.imind-heart:before {
	content: "\e9a0";
}
.imind-heels-2:before {
	content: "\e9a1";
}
.imind-heels:before {
	content: "\e9a2";
}
.imind-height-window:before {
	content: "\e9a3";
}
.imind-helicopter-2:before {
	content: "\e9a4";
}
.imind-helicopter:before {
	content: "\e9a5";
}
.imind-helix-2:before {
	content: "\e9a6";
}
.imind-hello:before {
	content: "\e9a7";
}
.imind-helmet-2:before {
	content: "\e9a8";
}
.imind-helmet-3:before {
	content: "\e9a9";
}
.imind-helmet:before {
	content: "\e9aa";
}
.imind-hipo:before {
	content: "\e9ab";
}
.imind-hipster-glasses:before {
	content: "\e9ac";
}
.imind-hipster-glasses2:before {
	content: "\e9ad";
}
.imind-hipster-glasses3:before {
	content: "\e9ae";
}
.imind-hipster-headphones:before {
	content: "\e9af";
}
.imind-hipster-men:before {
	content: "\e9b0";
}
.imind-hipster-men2:before {
	content: "\e9b1";
}
.imind-hipster-men3:before {
	content: "\e9b2";
}
.imind-hipster-sunglasses:before {
	content: "\e9b3";
}
.imind-hipster-sunglasses2:before {
	content: "\e9b4";
}
.imind-hipster-sunglasses3:before {
	content: "\e9b5";
}
.imind-hokey:before {
	content: "\e9b6";
}
.imind-holly:before {
	content: "\e9b7";
}
.imind-home-2:before {
	content: "\e9b8";
}
.imind-home-3:before {
	content: "\e9b9";
}
.imind-home-4:before {
	content: "\e9ba";
}
.imind-home-5:before {
	content: "\e9bb";
}
.imind-home-window:before {
	content: "\e9bc";
}
.imind-home:before {
	content: "\e9bd";
}
.imind-homosexual:before {
	content: "\e9be";
}
.imind-honey:before {
	content: "\e9bf";
}
.imind-hong-kong:before {
	content: "\e9c0";
}
.imind-hoodie:before {
	content: "\e9c1";
}
.imind-horror:before {
	content: "\e9c2";
}
.imind-horse:before {
	content: "\e9c3";
}
.imind-hospital-2:before {
	content: "\e9c4";
}
.imind-hospital:before {
	content: "\e9c5";
}
.imind-host:before {
	content: "\e9c6";
}
.imind-hot-dog:before {
	content: "\e9c7";
}
.imind-hotel:before {
	content: "\e9c8";
}
.imind-hour:before {
	content: "\e9c9";
}
.imind-hub:before {
	content: "\e9ca";
}
.imind-humor:before {
	content: "\e9cb";
}
.imind-hurt:before {
	content: "\e9cc";
}
.imind-ice-cream:before {
	content: "\e9cd";
}
.imind-icq:before {
	content: "\e9ce";
}
.imind-id-2:before {
	content: "\e9cf";
}
.imind-id-3:before {
	content: "\e9d0";
}
.imind-id-card:before {
	content: "\e9d1";
}
.imind-idea-2:before {
	content: "\e9d2";
}
.imind-idea-3:before {
	content: "\e9d3";
}
.imind-idea-4:before {
	content: "\e9d4";
}
.imind-idea-5:before {
	content: "\e9d5";
}
.imind-idea:before {
	content: "\e9d6";
}
.imind-identification-badge:before {
	content: "\e9d7";
}
.imind-imdb:before {
	content: "\e9d8";
}
.imind-inbox-empty:before {
	content: "\e9d9";
}
.imind-inbox-forward:before {
	content: "\e9da";
}
.imind-inbox-full:before {
	content: "\e9db";
}
.imind-inbox-into:before {
	content: "\e9dc";
}
.imind-inbox-out:before {
	content: "\e9dd";
}
.imind-inbox-reply:before {
	content: "\e9de";
}
.imind-inbox:before {
	content: "\e9df";
}
.imind-increase-inedit:before {
	content: "\e9e0";
}
.imind-indent-firstline:before {
	content: "\e9e1";
}
.imind-indent-leftmargin:before {
	content: "\e9e2";
}
.imind-indent-rightmargin:before {
	content: "\e9e3";
}
.imind-india:before {
	content: "\e9e4";
}
.imind-info-window:before {
	content: "\e9e5";
}
.imind-information:before {
	content: "\e9e6";
}
.imind-inifity:before {
	content: "\e9e7";
}
.imind-instagram:before {
	content: "\e9e8";
}
.imind-internet-2:before {
	content: "\e9e9";
}
.imind-internet-explorer:before {
	content: "\e9ea";
}
.imind-internet-smiley:before {
	content: "\e9eb";
}
.imind-internet:before {
	content: "\e9ec";
}
.imind-ios-apple:before {
	content: "\e9ed";
}
.imind-israel:before {
	content: "\e9ee";
}
.imind-italic-text:before {
	content: "\e9ef";
}
.imind-jacket-2:before {
	content: "\e9f0";
}
.imind-jacket:before {
	content: "\e9f1";
}
.imind-jamaica:before {
	content: "\e9f2";
}
.imind-japan:before {
	content: "\e9f3";
}
.imind-japanese-gate:before {
	content: "\e9f4";
}
.imind-jeans:before {
	content: "\e9f5";
}
.imind-jeep-2:before {
	content: "\e9f6";
}
.imind-jeep:before {
	content: "\e9f7";
}
.imind-jet:before {
	content: "\e9f8";
}
.imind-joystick:before {
	content: "\e9f9";
}
.imind-juice:before {
	content: "\e9fa";
}
.imind-jump-rope:before {
	content: "\e9fb";
}
.imind-kangoroo:before {
	content: "\e9fc";
}
.imind-kenya:before {
	content: "\e9fd";
}
.imind-key-2:before {
	content: "\e9fe";
}
.imind-key-3:before {
	content: "\e9ff";
}
.imind-key-lock:before {
	content: "\ea00";
}
.imind-key:before {
	content: "\ea01";
}
.imind-keyboard:before {
	content: "\ea02";
}
.imind-keyboard3:before {
	content: "\ea03";
}
.imind-keypad:before {
	content: "\ea04";
}
.imind-king-2:before {
	content: "\ea05";
}
.imind-king:before {
	content: "\ea06";
}
.imind-kiss:before {
	content: "\ea07";
}
.imind-knee:before {
	content: "\ea08";
}
.imind-knife-2:before {
	content: "\ea09";
}
.imind-knife:before {
	content: "\ea0a";
}
.imind-knight:before {
	content: "\ea0b";
}
.imind-koala:before {
	content: "\ea0c";
}
.imind-korea:before {
	content: "\ea0d";
}
.imind-lamp:before {
	content: "\ea0e";
}
.imind-landscape-2:before {
	content: "\ea0f";
}
.imind-landscape:before {
	content: "\ea10";
}
.imind-lantern:before {
	content: "\ea11";
}
.imind-laptop-2:before {
	content: "\ea12";
}
.imind-laptop-3:before {
	content: "\ea13";
}
.imind-laptop-phone:before {
	content: "\ea14";
}
.imind-laptop-secure:before {
	content: "\ea15";
}
.imind-laptop-tablet:before {
	content: "\ea16";
}
.imind-laptop:before {
	content: "\ea17";
}
.imind-laser:before {
	content: "\ea18";
}
.imind-last-fm:before {
	content: "\ea19";
}
.imind-last:before {
	content: "\ea1a";
}
.imind-laughing:before {
	content: "\ea1b";
}
.imind-layer-1635:before {
	content: "\ea1c";
}
.imind-layer-1646:before {
	content: "\ea1d";
}
.imind-layer-backward:before {
	content: "\ea1e";
}
.imind-layer-forward:before {
	content: "\ea1f";
}
.imind-leafs-2:before {
	content: "\ea20";
}
.imind-leafs:before {
	content: "\ea21";
}
.imind-leaning-tower:before {
	content: "\ea22";
}
.imind-left--right:before {
	content: "\ea23";
}
.imind-left--right3:before {
	content: "\ea24";
}
.imind-left-2:before {
	content: "\ea25";
}
.imind-left-3:before {
	content: "\ea26";
}
.imind-left-4:before {
	content: "\ea27";
}
.imind-left-toright:before {
	content: "\ea28";
}
.imind-left:before {
	content: "\ea29";
}
.imind-leg-2:before {
	content: "\ea2a";
}
.imind-leg:before {
	content: "\ea2b";
}
.imind-lego:before {
	content: "\ea2c";
}
.imind-lemon:before {
	content: "\ea2d";
}
.imind-len-2:before {
	content: "\ea2e";
}
.imind-len-3:before {
	content: "\ea2f";
}
.imind-len:before {
	content: "\ea30";
}
.imind-leo-2:before {
	content: "\ea31";
}
.imind-leo:before {
	content: "\ea32";
}
.imind-leopard:before {
	content: "\ea33";
}
.imind-lesbian:before {
	content: "\ea34";
}
.imind-lesbians:before {
	content: "\ea35";
}
.imind-letter-close:before {
	content: "\ea36";
}
.imind-letter-open:before {
	content: "\ea37";
}
.imind-letter-sent:before {
	content: "\ea38";
}
.imind-libra-2:before {
	content: "\ea39";
}
.imind-libra:before {
	content: "\ea3a";
}
.imind-library-2:before {
	content: "\ea3b";
}
.imind-library:before {
	content: "\ea3c";
}
.imind-life-jacket:before {
	content: "\ea3d";
}
.imind-life-safer:before {
	content: "\ea3e";
}
.imind-light-bulb:before {
	content: "\ea3f";
}
.imind-light-bulb2:before {
	content: "\ea40";
}
.imind-light-bulbleaf:before {
	content: "\ea41";
}
.imind-lighthouse:before {
	content: "\ea42";
}
.imind-like-2:before {
	content: "\ea43";
}
.imind-like:before {
	content: "\ea44";
}
.imind-line-chart:before {
	content: "\ea45";
}
.imind-line-chart2:before {
	content: "\ea46";
}
.imind-line-chart3:before {
	content: "\ea47";
}
.imind-line-chart4:before {
	content: "\ea48";
}
.imind-line-spacing:before {
	content: "\ea49";
}
.imind-line-spacingtext:before {
	content: "\ea4a";
}
.imind-link-2:before {
	content: "\ea4b";
}
.imind-link:before {
	content: "\ea4c";
}
.imind-linkedin-2:before {
	content: "\ea4d";
}
.imind-linkedin:before {
	content: "\ea4e";
}
.imind-linux:before {
	content: "\ea4f";
}
.imind-lion:before {
	content: "\ea50";
}
.imind-livejournal:before {
	content: "\ea51";
}
.imind-loading-2:before {
	content: "\ea52";
}
.imind-loading-3:before {
	content: "\ea53";
}
.imind-loading-window:before {
	content: "\ea54";
}
.imind-loading:before {
	content: "\ea55";
}
.imind-location-2:before {
	content: "\ea56";
}
.imind-location:before {
	content: "\ea57";
}
.imind-lock-2:before {
	content: "\ea58";
}
.imind-lock-3:before {
	content: "\ea59";
}
.imind-lock-user:before {
	content: "\ea5a";
}
.imind-lock-window:before {
	content: "\ea5b";
}
.imind-lock:before {
	content: "\ea5c";
}
.imind-lollipop-2:before {
	content: "\ea5d";
}
.imind-lollipop-3:before {
	content: "\ea5e";
}
.imind-lollipop:before {
	content: "\ea5f";
}
.imind-loop:before {
	content: "\ea60";
}
.imind-loud:before {
	content: "\ea61";
}
.imind-loudspeaker:before {
	content: "\ea62";
}
.imind-love-2:before {
	content: "\ea63";
}
.imind-love-user:before {
	content: "\ea64";
}
.imind-love-window:before {
	content: "\ea65";
}
.imind-love:before {
	content: "\ea66";
}
.imind-lowercase-text:before {
	content: "\ea67";
}
.imind-luggafe-front:before {
	content: "\ea68";
}
.imind-luggage-2:before {
	content: "\ea69";
}
.imind-macro:before {
	content: "\ea6a";
}
.imind-magic-wand:before {
	content: "\ea6b";
}
.imind-magnet:before {
	content: "\ea6c";
}
.imind-magnifi-glass-:before {
	content: "\ea6d";
}
.imind-magnifi-glass:before {
	content: "\ea6e";
}
.imind-magnifi-glass2:before {
	content: "\ea6f";
}
.imind-mail-2:before {
	content: "\ea70";
}
.imind-mail-3:before {
	content: "\ea71";
}
.imind-mail-add:before {
	content: "\ea72";
}
.imind-mail-attachement:before {
	content: "\ea73";
}
.imind-mail-block:before {
	content: "\ea74";
}
.imind-mail-delete:before {
	content: "\ea75";
}
.imind-mail-favorite:before {
	content: "\ea76";
}
.imind-mail-forward:before {
	content: "\ea77";
}
.imind-mail-gallery:before {
	content: "\ea78";
}
.imind-mail-inbox:before {
	content: "\ea79";
}
.imind-mail-link:before {
	content: "\ea7a";
}
.imind-mail-lock:before {
	content: "\ea7b";
}
.imind-mail-love:before {
	content: "\ea7c";
}
.imind-mail-money:before {
	content: "\ea7d";
}
.imind-mail-open:before {
	content: "\ea7e";
}
.imind-mail-outbox:before {
	content: "\ea7f";
}
.imind-mail-password:before {
	content: "\ea80";
}
.imind-mail-photo:before {
	content: "\ea81";
}
.imind-mail-read:before {
	content: "\ea82";
}
.imind-mail-removex:before {
	content: "\ea83";
}
.imind-mail-reply:before {
	content: "\ea84";
}
.imind-mail-replyall:before {
	content: "\ea85";
}
.imind-mail-search:before {
	content: "\ea86";
}
.imind-mail-send:before {
	content: "\ea87";
}
.imind-mail-settings:before {
	content: "\ea88";
}
.imind-mail-unread:before {
	content: "\ea89";
}
.imind-mail-video:before {
	content: "\ea8a";
}
.imind-mail-withatsign:before {
	content: "\ea8b";
}
.imind-mail-withcursors:before {
	content: "\ea8c";
}
.imind-mail:before {
	content: "\ea8d";
}
.imind-mailbox-empty:before {
	content: "\ea8e";
}
.imind-mailbox-full:before {
	content: "\ea8f";
}
.imind-male-2:before {
	content: "\ea90";
}
.imind-male-sign:before {
	content: "\ea91";
}
.imind-male:before {
	content: "\ea92";
}
.imind-malefemale:before {
	content: "\ea93";
}
.imind-man-sign:before {
	content: "\ea94";
}
.imind-management:before {
	content: "\ea95";
}
.imind-mans-underwear:before {
	content: "\ea96";
}
.imind-mans-underwear2:before {
	content: "\ea97";
}
.imind-map-marker:before {
	content: "\ea98";
}
.imind-map-marker2:before {
	content: "\ea99";
}
.imind-map-marker3:before {
	content: "\ea9a";
}
.imind-map:before {
	content: "\ea9b";
}
.imind-map2:before {
	content: "\ea9c";
}
.imind-marker-2:before {
	content: "\ea9d";
}
.imind-marker-3:before {
	content: "\ea9e";
}
.imind-marker:before {
	content: "\ea9f";
}
.imind-martini-glass:before {
	content: "\eaa0";
}
.imind-mask:before {
	content: "\eaa1";
}
.imind-master-card:before {
	content: "\eaa2";
}
.imind-maximize-window:before {
	content: "\eaa3";
}
.imind-maximize:before {
	content: "\eaa4";
}
.imind-medal-2:before {
	content: "\eaa5";
}
.imind-medal-3:before {
	content: "\eaa6";
}
.imind-medal:before {
	content: "\eaa7";
}
.imind-medical-sign:before {
	content: "\eaa8";
}
.imind-medicine-2:before {
	content: "\eaa9";
}
.imind-medicine-3:before {
	content: "\eaaa";
}
.imind-medicine:before {
	content: "\eaab";
}
.imind-megaphone:before {
	content: "\eaac";
}
.imind-memory-card:before {
	content: "\eaad";
}
.imind-memory-card2:before {
	content: "\eaae";
}
.imind-memory-card3:before {
	content: "\eaaf";
}
.imind-men:before {
	content: "\eab0";
}
.imind-menorah:before {
	content: "\eab1";
}
.imind-mens:before {
	content: "\eab2";
}
.imind-metacafe:before {
	content: "\eab3";
}
.imind-mexico:before {
	content: "\eab4";
}
.imind-mic:before {
	content: "\eab5";
}
.imind-microphone-2:before {
	content: "\eab6";
}
.imind-microphone-3:before {
	content: "\eab7";
}
.imind-microphone-4:before {
	content: "\eab8";
}
.imind-microphone-5:before {
	content: "\eab9";
}
.imind-microphone-6:before {
	content: "\eaba";
}
.imind-microphone-7:before {
	content: "\eabb";
}
.imind-microphone:before {
	content: "\eabc";
}
.imind-microscope:before {
	content: "\eabd";
}
.imind-milk-bottle:before {
	content: "\eabe";
}
.imind-mine:before {
	content: "\eabf";
}
.imind-minimize-maximize-close-window:before {
	content: "\eac0";
}
.imind-minimize-window:before {
	content: "\eac1";
}
.imind-minimize:before {
	content: "\eac2";
}
.imind-mirror:before {
	content: "\eac3";
}
.imind-mixer:before {
	content: "\eac4";
}
.imind-mixx:before {
	content: "\eac5";
}
.imind-money-2:before {
	content: "\eac6";
}
.imind-money-bag:before {
	content: "\eac7";
}
.imind-money-smiley:before {
	content: "\eac8";
}
.imind-money:before {
	content: "\eac9";
}
.imind-monitor-2:before {
	content: "\eaca";
}
.imind-monitor-3:before {
	content: "\eacb";
}
.imind-monitor-4:before {
	content: "\eacc";
}
.imind-monitor-5:before {
	content: "\eacd";
}
.imind-monitor-analytics:before {
	content: "\eace";
}
.imind-monitor-laptop:before {
	content: "\eacf";
}
.imind-monitor-phone:before {
	content: "\ead0";
}
.imind-monitor-tablet:before {
	content: "\ead1";
}
.imind-monitor-vertical:before {
	content: "\ead2";
}
.imind-monitor:before {
	content: "\ead3";
}
.imind-monitoring:before {
	content: "\ead4";
}
.imind-monkey:before {
	content: "\ead5";
}
.imind-monster:before {
	content: "\ead6";
}
.imind-morocco:before {
	content: "\ead7";
}
.imind-motorcycle:before {
	content: "\ead8";
}
.imind-mouse-2:before {
	content: "\ead9";
}
.imind-mouse-3:before {
	content: "\eada";
}
.imind-mouse-4:before {
	content: "\eadb";
}
.imind-mouse-pointer:before {
	content: "\eadc";
}
.imind-mouse:before {
	content: "\eadd";
}
.imind-moustache-smiley:before {
	content: "\eade";
}
.imind-movie-ticket:before {
	content: "\eadf";
}
.imind-movie:before {
	content: "\eae0";
}
.imind-mp3-file:before {
	content: "\eae1";
}
.imind-museum:before {
	content: "\eae2";
}
.imind-mushroom:before {
	content: "\eae3";
}
.imind-music-note:before {
	content: "\eae4";
}
.imind-music-note2:before {
	content: "\eae5";
}
.imind-music-note3:before {
	content: "\eae6";
}
.imind-music-note4:before {
	content: "\eae7";
}
.imind-music-player:before {
	content: "\eae8";
}
.imind-mustache-2:before {
	content: "\eae9";
}
.imind-mustache-3:before {
	content: "\eaea";
}
.imind-mustache-4:before {
	content: "\eaeb";
}
.imind-mustache-5:before {
	content: "\eaec";
}
.imind-mustache-6:before {
	content: "\eaed";
}
.imind-mustache-7:before {
	content: "\eaee";
}
.imind-mustache-8:before {
	content: "\eaef";
}
.imind-mustache:before {
	content: "\eaf0";
}
.imind-mute:before {
	content: "\eaf1";
}
.imind-myspace:before {
	content: "\eaf2";
}
.imind-navigat-start:before {
	content: "\eaf3";
}
.imind-navigate-end:before {
	content: "\eaf4";
}
.imind-navigation-leftwindow:before {
	content: "\eaf5";
}
.imind-navigation-rightwindow:before {
	content: "\eaf6";
}
.imind-nepal:before {
	content: "\eaf7";
}
.imind-netscape:before {
	content: "\eaf8";
}
.imind-network-window:before {
	content: "\eaf9";
}
.imind-network:before {
	content: "\eafa";
}
.imind-neutron:before {
	content: "\eafb";
}
.imind-new-mail:before {
	content: "\eafc";
}
.imind-new-tab:before {
	content: "\eafd";
}
.imind-newspaper-2:before {
	content: "\eafe";
}
.imind-newspaper:before {
	content: "\eaff";
}
.imind-newsvine:before {
	content: "\eb00";
}
.imind-next2:before {
	content: "\eb01";
}
.imind-next-3:before {
	content: "\eb02";
}
.imind-next-music:before {
	content: "\eb03";
}
.imind-next:before {
	content: "\eb04";
}
.imind-no-battery:before {
	content: "\eb05";
}
.imind-no-drop:before {
	content: "\eb06";
}
.imind-no-flash:before {
	content: "\eb07";
}
.imind-no-smoking:before {
	content: "\eb08";
}
.imind-noose:before {
	content: "\eb09";
}
.imind-normal-text:before {
	content: "\eb0a";
}
.imind-note:before {
	content: "\eb0b";
}
.imind-notepad-2:before {
	content: "\eb0c";
}
.imind-notepad:before {
	content: "\eb0d";
}
.imind-nuclear:before {
	content: "\eb0e";
}
.imind-numbering-list:before {
	content: "\eb0f";
}
.imind-nurse:before {
	content: "\eb10";
}
.imind-office-lamp:before {
	content: "\eb11";
}
.imind-office:before {
	content: "\eb12";
}
.imind-oil:before {
	content: "\eb13";
}
.imind-old-camera:before {
	content: "\eb14";
}
.imind-old-cassette:before {
	content: "\eb15";
}
.imind-old-clock:before {
	content: "\eb16";
}
.imind-old-radio:before {
	content: "\eb17";
}
.imind-old-sticky:before {
	content: "\eb18";
}
.imind-old-sticky2:before {
	content: "\eb19";
}
.imind-old-telephone:before {
	content: "\eb1a";
}
.imind-old-tv:before {
	content: "\eb1b";
}
.imind-on-air:before {
	content: "\eb1c";
}
.imind-on-off-2:before {
	content: "\eb1d";
}
.imind-on-off-3:before {
	content: "\eb1e";
}
.imind-on-off:before {
	content: "\eb1f";
}
.imind-one-finger:before {
	content: "\eb20";
}
.imind-one-fingertouch:before {
	content: "\eb21";
}
.imind-one-window:before {
	content: "\eb22";
}
.imind-open-banana:before {
	content: "\eb23";
}
.imind-open-book:before {
	content: "\eb24";
}
.imind-opera-house:before {
	content: "\eb25";
}
.imind-opera:before {
	content: "\eb26";
}
.imind-optimization:before {
	content: "\eb27";
}
.imind-orientation-2:before {
	content: "\eb28";
}
.imind-orientation-3:before {
	content: "\eb29";
}
.imind-orientation:before {
	content: "\eb2a";
}
.imind-orkut:before {
	content: "\eb2b";
}
.imind-ornament:before {
	content: "\eb2c";
}
.imind-over-time:before {
	content: "\eb2d";
}
.imind-over-time2:before {
	content: "\eb2e";
}
.imind-owl:before {
	content: "\eb2f";
}
.imind-pac-man:before {
	content: "\eb30";
}
.imind-paint-brush:before {
	content: "\eb31";
}
.imind-paint-bucket:before {
	content: "\eb32";
}
.imind-paintbrush:before {
	content: "\eb33";
}
.imind-palette:before {
	content: "\eb34";
}
.imind-palm-tree:before {
	content: "\eb35";
}
.imind-panda:before {
	content: "\eb36";
}
.imind-panorama:before {
	content: "\eb37";
}
.imind-pantheon:before {
	content: "\eb38";
}
.imind-pantone:before {
	content: "\eb39";
}
.imind-pants:before {
	content: "\eb3a";
}
.imind-paper-plane:before {
	content: "\eb3b";
}
.imind-paper:before {
	content: "\eb3c";
}
.imind-parasailing:before {
	content: "\eb3d";
}
.imind-parrot:before {
	content: "\eb3e";
}
.imind-password-2shopping:before {
	content: "\eb3f";
}
.imind-password-field:before {
	content: "\eb40";
}
.imind-password-shopping:before {
	content: "\eb41";
}
.imind-password:before {
	content: "\eb42";
}
.imind-pause-2:before {
	content: "\eb43";
}
.imind-pause:before {
	content: "\eb44";
}
.imind-paw:before {
	content: "\eb45";
}
.imind-pawn:before {
	content: "\eb46";
}
.imind-paypal:before {
	content: "\eb47";
}
.imind-pen-2:before {
	content: "\eb48";
}
.imind-pen-3:before {
	content: "\eb49";
}
.imind-pen-4:before {
	content: "\eb4a";
}
.imind-pen-5:before {
	content: "\eb4b";
}
.imind-pen-6:before {
	content: "\eb4c";
}
.imind-pen:before {
	content: "\eb4d";
}
.imind-pencil-ruler:before {
	content: "\eb4e";
}
.imind-pencil:before {
	content: "\eb4f";
}
.imind-penguin:before {
	content: "\eb50";
}
.imind-pentagon:before {
	content: "\eb51";
}
.imind-people-oncloud:before {
	content: "\eb52";
}
.imind-pepper-withfire:before {
	content: "\eb53";
}
.imind-pepper:before {
	content: "\eb54";
}
.imind-petrol:before {
	content: "\eb55";
}
.imind-petronas-tower:before {
	content: "\eb56";
}
.imind-philipines:before {
	content: "\eb57";
}
.imind-phone-2:before {
	content: "\eb58";
}
.imind-phone-3:before {
	content: "\eb59";
}
.imind-phone-3g:before {
	content: "\eb5a";
}
.imind-phone-4g:before {
	content: "\eb5b";
}
.imind-phone-simcard:before {
	content: "\eb5c";
}
.imind-phone-sms:before {
	content: "\eb5d";
}
.imind-phone-wifi:before {
	content: "\eb5e";
}
.imind-phone:before {
	content: "\eb5f";
}
.imind-photo-2:before {
	content: "\eb60";
}
.imind-photo-3:before {
	content: "\eb61";
}
.imind-photo-album:before {
	content: "\eb62";
}
.imind-photo-album2:before {
	content: "\eb63";
}
.imind-photo-album3:before {
	content: "\eb64";
}
.imind-photo:before {
	content: "\eb65";
}
.imind-photos:before {
	content: "\eb66";
}
.imind-physics:before {
	content: "\eb67";
}
.imind-pi:before {
	content: "\eb68";
}
.imind-piano:before {
	content: "\eb69";
}
.imind-picasa:before {
	content: "\eb6a";
}
.imind-pie-chart:before {
	content: "\eb6b";
}
.imind-pie-chart2:before {
	content: "\eb6c";
}
.imind-pie-chart3:before {
	content: "\eb6d";
}
.imind-pilates-2:before {
	content: "\eb6e";
}
.imind-pilates-3:before {
	content: "\eb6f";
}
.imind-pilates:before {
	content: "\eb70";
}
.imind-pilot:before {
	content: "\eb71";
}
.imind-pinch:before {
	content: "\eb72";
}
.imind-ping-pong:before {
	content: "\eb73";
}
.imind-pinterest:before {
	content: "\eb74";
}
.imind-pipe:before {
	content: "\eb75";
}
.imind-pipette:before {
	content: "\eb76";
}
.imind-piramids:before {
	content: "\eb77";
}
.imind-pisces-2:before {
	content: "\eb78";
}
.imind-pisces:before {
	content: "\eb79";
}
.imind-pizza-slice:before {
	content: "\eb7a";
}
.imind-pizza:before {
	content: "\eb7b";
}
.imind-plane-2:before {
	content: "\eb7c";
}
.imind-plane:before {
	content: "\eb7d";
}
.imind-plant:before {
	content: "\eb7e";
}
.imind-plasmid:before {
	content: "\eb7f";
}
.imind-plaster:before {
	content: "\eb80";
}
.imind-plastic-cupphone:before {
	content: "\eb81";
}
.imind-plastic-cupphone2:before {
	content: "\eb82";
}
.imind-plate:before {
	content: "\eb83";
}
.imind-plates:before {
	content: "\eb84";
}
.imind-plaxo:before {
	content: "\eb85";
}
.imind-play-music:before {
	content: "\eb86";
}
.imind-plug-in:before {
	content: "\eb87";
}
.imind-plug-in2:before {
	content: "\eb88";
}
.imind-plurk:before {
	content: "\eb89";
}
.imind-pointer:before {
	content: "\eb8a";
}
.imind-poland:before {
	content: "\eb8b";
}
.imind-police-man:before {
	content: "\eb8c";
}
.imind-police-station:before {
	content: "\eb8d";
}
.imind-police-woman:before {
	content: "\eb8e";
}
.imind-police:before {
	content: "\eb8f";
}
.imind-polo-shirt:before {
	content: "\eb90";
}
.imind-portrait:before {
	content: "\eb91";
}
.imind-portugal:before {
	content: "\eb92";
}
.imind-post-mail:before {
	content: "\eb93";
}
.imind-post-mail2:before {
	content: "\eb94";
}
.imind-post-office:before {
	content: "\eb95";
}
.imind-post-sign:before {
	content: "\eb96";
}
.imind-post-sign2ways:before {
	content: "\eb97";
}
.imind-posterous:before {
	content: "\eb98";
}
.imind-pound-sign:before {
	content: "\eb99";
}
.imind-pound-sign2:before {
	content: "\eb9a";
}
.imind-pound:before {
	content: "\eb9b";
}
.imind-power-2:before {
	content: "\eb9c";
}
.imind-power-3:before {
	content: "\eb9d";
}
.imind-power-cable:before {
	content: "\eb9e";
}
.imind-power-station:before {
	content: "\eb9f";
}
.imind-power:before {
	content: "\eba0";
}
.imind-prater:before {
	content: "\eba1";
}
.imind-present:before {
	content: "\eba2";
}
.imind-presents:before {
	content: "\eba3";
}
.imind-press:before {
	content: "\eba4";
}
.imind-preview:before {
	content: "\eba5";
}
.imind-previous:before {
	content: "\eba6";
}
.imind-pricing:before {
	content: "\eba7";
}
.imind-printer:before {
	content: "\eba8";
}
.imind-professor:before {
	content: "\eba9";
}
.imind-profile:before {
	content: "\ebaa";
}
.imind-project:before {
	content: "\ebab";
}
.imind-projector-2:before {
	content: "\ebac";
}
.imind-projector:before {
	content: "\ebad";
}
.imind-pulse:before {
	content: "\ebae";
}
.imind-pumpkin:before {
	content: "\ebaf";
}
.imind-punk:before {
	content: "\ebb0";
}
.imind-punker:before {
	content: "\ebb1";
}
.imind-puzzle:before {
	content: "\ebb2";
}
.imind-qik:before {
	content: "\ebb3";
}
.imind-qr-code:before {
	content: "\ebb4";
}
.imind-queen-2:before {
	content: "\ebb5";
}
.imind-queen:before {
	content: "\ebb6";
}
.imind-quill-2:before {
	content: "\ebb7";
}
.imind-quill-3:before {
	content: "\ebb8";
}
.imind-quill:before {
	content: "\ebb9";
}
.imind-quotes-2:before {
	content: "\ebba";
}
.imind-quotes:before {
	content: "\ebbb";
}
.imind-radio:before {
	content: "\ebbc";
}
.imind-radioactive:before {
	content: "\ebbd";
}
.imind-rafting:before {
	content: "\ebbe";
}
.imind-rain-drop:before {
	content: "\ebbf";
}
.imind-rainbow-2:before {
	content: "\ebc0";
}
.imind-rainbow:before {
	content: "\ebc1";
}
.imind-ram:before {
	content: "\ebc2";
}
.imind-razzor-blade:before {
	content: "\ebc3";
}
.imind-receipt-2:before {
	content: "\ebc4";
}
.imind-receipt-3:before {
	content: "\ebc5";
}
.imind-receipt-4:before {
	content: "\ebc6";
}
.imind-receipt:before {
	content: "\ebc7";
}
.imind-record2:before {
	content: "\ebc8";
}
.imind-record-3:before {
	content: "\ebc9";
}
.imind-record-music:before {
	content: "\ebca";
}
.imind-record:before {
	content: "\ebcb";
}
.imind-recycling-2:before {
	content: "\ebcc";
}
.imind-recycling:before {
	content: "\ebcd";
}
.imind-reddit:before {
	content: "\ebce";
}
.imind-redhat:before {
	content: "\ebcf";
}
.imind-redirect:before {
	content: "\ebd0";
}
.imind-redo:before {
	content: "\ebd1";
}
.imind-reel:before {
	content: "\ebd2";
}
.imind-refinery:before {
	content: "\ebd3";
}
.imind-refresh-window:before {
	content: "\ebd4";
}
.imind-refresh:before {
	content: "\ebd5";
}
.imind-reload-2:before {
	content: "\ebd6";
}
.imind-reload-3:before {
	content: "\ebd7";
}
.imind-reload:before {
	content: "\ebd8";
}
.imind-remote-controll:before {
	content: "\ebd9";
}
.imind-remote-controll2:before {
	content: "\ebda";
}
.imind-remove-bag:before {
	content: "\ebdb";
}
.imind-remove-basket:before {
	content: "\ebdc";
}
.imind-remove-cart:before {
	content: "\ebdd";
}
.imind-remove-file:before {
	content: "\ebde";
}
.imind-remove-user:before {
	content: "\ebdf";
}
.imind-remove-window:before {
	content: "\ebe0";
}
.imind-remove:before {
	content: "\ebe1";
}
.imind-rename:before {
	content: "\ebe2";
}
.imind-repair:before {
	content: "\ebe3";
}
.imind-repeat-2:before {
	content: "\ebe4";
}
.imind-repeat-3:before {
	content: "\ebe5";
}
.imind-repeat-4:before {
	content: "\ebe6";
}
.imind-repeat-5:before {
	content: "\ebe7";
}
.imind-repeat-6:before {
	content: "\ebe8";
}
.imind-repeat-7:before {
	content: "\ebe9";
}
.imind-repeat:before {
	content: "\ebea";
}
.imind-reset:before {
	content: "\ebeb";
}
.imind-resize:before {
	content: "\ebec";
}
.imind-restore-window:before {
	content: "\ebed";
}
.imind-retouching:before {
	content: "\ebee";
}
.imind-retro-camera:before {
	content: "\ebef";
}
.imind-retro:before {
	content: "\ebf0";
}
.imind-retweet:before {
	content: "\ebf1";
}
.imind-reverbnation:before {
	content: "\ebf2";
}
.imind-rewind:before {
	content: "\ebf3";
}
.imind-rgb:before {
	content: "\ebf4";
}
.imind-ribbon-2:before {
	content: "\ebf5";
}
.imind-ribbon-3:before {
	content: "\ebf6";
}
.imind-ribbon:before {
	content: "\ebf7";
}
.imind-right-2:before {
	content: "\ebf8";
}
.imind-right-3:before {
	content: "\ebf9";
}
.imind-right-4:before {
	content: "\ebfa";
}
.imind-right-toleft:before {
	content: "\ebfb";
}
.imind-right:before {
	content: "\ebfc";
}
.imind-road-2:before {
	content: "\ebfd";
}
.imind-road-3:before {
	content: "\ebfe";
}
.imind-road:before {
	content: "\ebff";
}
.imind-robot-2:before {
	content: "\ec00";
}
.imind-robot:before {
	content: "\ec01";
}
.imind-rock-androll:before {
	content: "\ec02";
}
.imind-rocket:before {
	content: "\ec03";
}
.imind-roller:before {
	content: "\ec04";
}
.imind-roof:before {
	content: "\ec05";
}
.imind-rook:before {
	content: "\ec06";
}
.imind-rotate-gesture:before {
	content: "\ec07";
}
.imind-rotate-gesture2:before {
	content: "\ec08";
}
.imind-rotate-gesture3:before {
	content: "\ec09";
}
.imind-rotation-390:before {
	content: "\ec0a";
}
.imind-rotation:before {
	content: "\ec0b";
}
.imind-router-2:before {
	content: "\ec0c";
}
.imind-router:before {
	content: "\ec0d";
}
.imind-rss:before {
	content: "\ec0e";
}
.imind-ruler-2:before {
	content: "\ec0f";
}
.imind-ruler:before {
	content: "\ec10";
}
.imind-running-shoes:before {
	content: "\ec11";
}
.imind-running:before {
	content: "\ec12";
}
.imind-safari:before {
	content: "\ec13";
}
.imind-safe-box:before {
	content: "\ec14";
}
.imind-safe-box2:before {
	content: "\ec15";
}
.imind-safety-pinclose:before {
	content: "\ec16";
}
.imind-safety-pinopen:before {
	content: "\ec17";
}
.imind-sagittarus-2:before {
	content: "\ec18";
}
.imind-sagittarus:before {
	content: "\ec19";
}
.imind-sailing-ship:before {
	content: "\ec1a";
}
.imind-sand-watch:before {
	content: "\ec1b";
}
.imind-sand-watch2:before {
	content: "\ec1c";
}
.imind-santa-claus:before {
	content: "\ec1d";
}
.imind-santa-claus2:before {
	content: "\ec1e";
}
.imind-santa-onsled:before {
	content: "\ec1f";
}
.imind-satelite-2:before {
	content: "\ec20";
}
.imind-satelite:before {
	content: "\ec21";
}
.imind-save-window:before {
	content: "\ec22";
}
.imind-save:before {
	content: "\ec23";
}
.imind-saw:before {
	content: "\ec24";
}
.imind-saxophone:before {
	content: "\ec25";
}
.imind-scale:before {
	content: "\ec26";
}
.imind-scarf:before {
	content: "\ec27";
}
.imind-scissor:before {
	content: "\ec28";
}
.imind-scooter-front:before {
	content: "\ec29";
}
.imind-scooter:before {
	content: "\ec2a";
}
.imind-scorpio-2:before {
	content: "\ec2b";
}
.imind-scorpio:before {
	content: "\ec2c";
}
.imind-scotland:before {
	content: "\ec2d";
}
.imind-screwdriver:before {
	content: "\ec2e";
}
.imind-scroll-fast:before {
	content: "\ec2f";
}
.imind-scroll:before {
	content: "\ec30";
}
.imind-scroller-2:before {
	content: "\ec31";
}
.imind-scroller:before {
	content: "\ec32";
}
.imind-sea-dog:before {
	content: "\ec33";
}
.imind-search-oncloud:before {
	content: "\ec34";
}
.imind-search-people:before {
	content: "\ec35";
}
.imind-secound:before {
	content: "\ec36";
}
.imind-secound2:before {
	content: "\ec37";
}
.imind-security-block:before {
	content: "\ec38";
}
.imind-security-bug:before {
	content: "\ec39";
}
.imind-security-camera:before {
	content: "\ec3a";
}
.imind-security-check:before {
	content: "\ec3b";
}
.imind-security-settings:before {
	content: "\ec3c";
}
.imind-security-smiley:before {
	content: "\ec3d";
}
.imind-securiy-remove:before {
	content: "\ec3e";
}
.imind-seed:before {
	content: "\ec3f";
}
.imind-selfie:before {
	content: "\ec40";
}
.imind-serbia:before {
	content: "\ec41";
}
.imind-server-2:before {
	content: "\ec42";
}
.imind-server:before {
	content: "\ec43";
}
.imind-servers:before {
	content: "\ec44";
}
.imind-settings-window:before {
	content: "\ec45";
}
.imind-sewing-machine:before {
	content: "\ec46";
}
.imind-sexual:before {
	content: "\ec47";
}
.imind-share-oncloud:before {
	content: "\ec48";
}
.imind-share-window:before {
	content: "\ec49";
}
.imind-share:before {
	content: "\ec4a";
}
.imind-sharethis:before {
	content: "\ec4b";
}
.imind-shark:before {
	content: "\ec4c";
}
.imind-sheep:before {
	content: "\ec4d";
}
.imind-sheriff-badge:before {
	content: "\ec4e";
}
.imind-shield:before {
	content: "\ec4f";
}
.imind-ship-2:before {
	content: "\ec50";
}
.imind-ship:before {
	content: "\ec51";
}
.imind-shirt:before {
	content: "\ec52";
}
.imind-shoes-2:before {
	content: "\ec53";
}
.imind-shoes-3:before {
	content: "\ec54";
}
.imind-shoes:before {
	content: "\ec55";
}
.imind-shop-2:before {
	content: "\ec56";
}
.imind-shop-3:before {
	content: "\ec57";
}
.imind-shop-4:before {
	content: "\ec58";
}
.imind-shop:before {
	content: "\ec59";
}
.imind-shopping-bag:before {
	content: "\ec5a";
}
.imind-shopping-basket:before {
	content: "\ec5b";
}
.imind-shopping-cart:before {
	content: "\ec5c";
}
.imind-short-pants:before {
	content: "\ec5d";
}
.imind-shoutwire:before {
	content: "\ec5e";
}
.imind-shovel:before {
	content: "\ec5f";
}
.imind-shuffle-2:before {
	content: "\ec60";
}
.imind-shuffle-3:before {
	content: "\ec61";
}
.imind-shuffle-4:before {
	content: "\ec62";
}
.imind-shuffle:before {
	content: "\ec63";
}
.imind-shutter:before {
	content: "\ec64";
}
.imind-sidebar-window:before {
	content: "\ec65";
}
.imind-signal:before {
	content: "\ec66";
}
.imind-singapore:before {
	content: "\ec67";
}
.imind-skate-shoes:before {
	content: "\ec68";
}
.imind-skateboard-2:before {
	content: "\ec69";
}
.imind-skateboard:before {
	content: "\ec6a";
}
.imind-skeleton:before {
	content: "\ec6b";
}
.imind-ski:before {
	content: "\ec6c";
}
.imind-skirt:before {
	content: "\ec6d";
}
.imind-skrill:before {
	content: "\ec6e";
}
.imind-skull:before {
	content: "\ec6f";
}
.imind-skydiving:before {
	content: "\ec70";
}
.imind-skype:before {
	content: "\ec71";
}
.imind-sled-withgifts:before {
	content: "\ec72";
}
.imind-sled:before {
	content: "\ec73";
}
.imind-sleeping:before {
	content: "\ec74";
}
.imind-sleet:before {
	content: "\ec75";
}
.imind-slippers:before {
	content: "\ec76";
}
.imind-smart:before {
	content: "\ec77";
}
.imind-smartphone-2:before {
	content: "\ec78";
}
.imind-smartphone-3:before {
	content: "\ec79";
}
.imind-smartphone-4:before {
	content: "\ec7a";
}
.imind-smartphone-secure:before {
	content: "\ec7b";
}
.imind-smartphone:before {
	content: "\ec7c";
}
.imind-smile:before {
	content: "\ec7d";
}
.imind-smoking-area:before {
	content: "\ec7e";
}
.imind-smoking-pipe:before {
	content: "\ec7f";
}
.imind-snake:before {
	content: "\ec80";
}
.imind-snorkel:before {
	content: "\ec81";
}
.imind-snow-2:before {
	content: "\ec82";
}
.imind-snow-dome:before {
	content: "\ec83";
}
.imind-snow-storm:before {
	content: "\ec84";
}
.imind-snow:before {
	content: "\ec85";
}
.imind-snowflake-2:before {
	content: "\ec86";
}
.imind-snowflake-3:before {
	content: "\ec87";
}
.imind-snowflake-4:before {
	content: "\ec88";
}
.imind-snowflake:before {
	content: "\ec89";
}
.imind-snowman:before {
	content: "\ec8a";
}
.imind-soccer-ball:before {
	content: "\ec8b";
}
.imind-soccer-shoes:before {
	content: "\ec8c";
}
.imind-socks:before {
	content: "\ec8d";
}
.imind-solar:before {
	content: "\ec8e";
}
.imind-sound-wave:before {
	content: "\ec8f";
}
.imind-sound:before {
	content: "\ec90";
}
.imind-soundcloud:before {
	content: "\ec91";
}
.imind-soup:before {
	content: "\ec92";
}
.imind-south-africa:before {
	content: "\ec93";
}
.imind-space-needle:before {
	content: "\ec94";
}
.imind-spain:before {
	content: "\ec95";
}
.imind-spam-mail:before {
	content: "\ec96";
}
.imind-speach-bubble:before {
	content: "\ec97";
}
.imind-speach-bubble2:before {
	content: "\ec98";
}
.imind-speach-bubble3:before {
	content: "\ec99";
}
.imind-speach-bubble4:before {
	content: "\ec9a";
}
.imind-speach-bubble5:before {
	content: "\ec9b";
}
.imind-speach-bubble6:before {
	content: "\ec9c";
}
.imind-speach-bubble7:before {
	content: "\ec9d";
}
.imind-speach-bubble8:before {
	content: "\ec9e";
}
.imind-speach-bubble9:before {
	content: "\ec9f";
}
.imind-speach-bubble10:before {
	content: "\eca0";
}
.imind-speach-bubble11:before {
	content: "\eca1";
}
.imind-speach-bubble12:before {
	content: "\eca2";
}
.imind-speach-bubble13:before {
	content: "\eca3";
}
.imind-speach-bubbleasking:before {
	content: "\eca4";
}
.imind-speach-bubblecomic:before {
	content: "\eca5";
}
.imind-speach-bubblecomic2:before {
	content: "\eca6";
}
.imind-speach-bubblecomic3:before {
	content: "\eca7";
}
.imind-speach-bubblecomic4:before {
	content: "\eca8";
}
.imind-speach-bubbledialog:before {
	content: "\eca9";
}
.imind-speach-bubbles:before {
	content: "\ecaa";
}
.imind-speak-2:before {
	content: "\ecab";
}
.imind-speak:before {
	content: "\ecac";
}
.imind-speaker-2:before {
	content: "\ecad";
}
.imind-speaker:before {
	content: "\ecae";
}
.imind-spell-check:before {
	content: "\ecaf";
}
.imind-spell-checkabc:before {
	content: "\ecb0";
}
.imind-spermium:before {
	content: "\ecb1";
}
.imind-spider:before {
	content: "\ecb2";
}
.imind-spiderweb:before {
	content: "\ecb3";
}
.imind-split-foursquarewindow:before {
	content: "\ecb4";
}
.imind-split-horizontal:before {
	content: "\ecb5";
}
.imind-split-horizontal2window:before {
	content: "\ecb6";
}
.imind-split-vertical:before {
	content: "\ecb7";
}
.imind-split-vertical2:before {
	content: "\ecb8";
}
.imind-split-window:before {
	content: "\ecb9";
}
.imind-spoder:before {
	content: "\ecba";
}
.imind-spoon:before {
	content: "\ecbb";
}
.imind-sport-mode:before {
	content: "\ecbc";
}
.imind-sports-clothings1:before {
	content: "\ecbd";
}
.imind-sports-clothings2:before {
	content: "\ecbe";
}
.imind-sports-shirt:before {
	content: "\ecbf";
}
.imind-spot:before {
	content: "\ecc0";
}
.imind-spray:before {
	content: "\ecc1";
}
.imind-spread:before {
	content: "\ecc2";
}
.imind-spring:before {
	content: "\ecc3";
}
.imind-spurl:before {
	content: "\ecc4";
}
.imind-spy:before {
	content: "\ecc5";
}
.imind-squirrel:before {
	content: "\ecc6";
}
.imind-ssl:before {
	content: "\ecc7";
}
.imind-st-basilscathedral:before {
	content: "\ecc8";
}
.imind-st-paulscathedral:before {
	content: "\ecc9";
}
.imind-stamp-2:before {
	content: "\ecca";
}
.imind-stamp:before {
	content: "\eccb";
}
.imind-stapler:before {
	content: "\eccc";
}
.imind-star-track:before {
	content: "\eccd";
}
.imind-star:before {
	content: "\ecce";
}
.imind-starfish:before {
	content: "\eccf";
}
.imind-start2:before {
	content: "\ecd0";
}
.imind-start-3:before {
	content: "\ecd1";
}
.imind-start-ways:before {
	content: "\ecd2";
}
.imind-start:before {
	content: "\ecd3";
}
.imind-statistic:before {
	content: "\ecd4";
}
.imind-stethoscope:before {
	content: "\ecd5";
}
.imind-stop--2:before {
	content: "\ecd6";
}
.imind-stop-music:before {
	content: "\ecd7";
}
.imind-stop:before {
	content: "\ecd8";
}
.imind-stopwatch-2:before {
	content: "\ecd9";
}
.imind-stopwatch:before {
	content: "\ecda";
}
.imind-storm:before {
	content: "\ecdb";
}
.imind-street-view:before {
	content: "\ecdc";
}
.imind-street-view2:before {
	content: "\ecdd";
}
.imind-strikethrough-text:before {
	content: "\ecde";
}
.imind-stroller:before {
	content: "\ecdf";
}
.imind-structure:before {
	content: "\ece0";
}
.imind-student-female:before {
	content: "\ece1";
}
.imind-student-hat:before {
	content: "\ece2";
}
.imind-student-hat2:before {
	content: "\ece3";
}
.imind-student-male:before {
	content: "\ece4";
}
.imind-student-malefemale:before {
	content: "\ece5";
}
.imind-students:before {
	content: "\ece6";
}
.imind-studio-flash:before {
	content: "\ece7";
}
.imind-studio-lightbox:before {
	content: "\ece8";
}
.imind-stumbleupon:before {
	content: "\ece9";
}
.imind-suit:before {
	content: "\ecea";
}
.imind-suitcase:before {
	content: "\eceb";
}
.imind-sum-2:before {
	content: "\ecec";
}
.imind-sum:before {
	content: "\eced";
}
.imind-summer:before {
	content: "\ecee";
}
.imind-sun-cloudyrain:before {
	content: "\ecef";
}
.imind-sun:before {
	content: "\ecf0";
}
.imind-sunglasses-2:before {
	content: "\ecf1";
}
.imind-sunglasses-3:before {
	content: "\ecf2";
}
.imind-sunglasses-smiley:before {
	content: "\ecf3";
}
.imind-sunglasses-smiley2:before {
	content: "\ecf4";
}
.imind-sunglasses-w:before {
	content: "\ecf5";
}
.imind-sunglasses-w2:before {
	content: "\ecf6";
}
.imind-sunglasses-w3:before {
	content: "\ecf7";
}
.imind-sunglasses:before {
	content: "\ecf8";
}
.imind-sunrise:before {
	content: "\ecf9";
}
.imind-sunset:before {
	content: "\ecfa";
}
.imind-superman:before {
	content: "\ecfb";
}
.imind-support:before {
	content: "\ecfc";
}
.imind-surprise:before {
	content: "\ecfd";
}
.imind-sushi:before {
	content: "\ecfe";
}
.imind-sweden:before {
	content: "\ecff";
}
.imind-swimming-short:before {
	content: "\ed00";
}
.imind-swimming:before {
	content: "\ed01";
}
.imind-swimmwear:before {
	content: "\ed02";
}
.imind-switch:before {
	content: "\ed03";
}
.imind-switzerland:before {
	content: "\ed04";
}
.imind-sync-cloud:before {
	content: "\ed05";
}
.imind-sync:before {
	content: "\ed06";
}
.imind-synchronize-2:before {
	content: "\ed07";
}
.imind-synchronize:before {
	content: "\ed08";
}
.imind-t-shirt:before {
	content: "\ed09";
}
.imind-tablet-2:before {
	content: "\ed0a";
}
.imind-tablet-3:before {
	content: "\ed0b";
}
.imind-tablet-orientation:before {
	content: "\ed0c";
}
.imind-tablet-phone:before {
	content: "\ed0d";
}
.imind-tablet-secure:before {
	content: "\ed0e";
}
.imind-tablet-vertical:before {
	content: "\ed0f";
}
.imind-tablet:before {
	content: "\ed10";
}
.imind-tactic:before {
	content: "\ed11";
}
.imind-tag-2:before {
	content: "\ed12";
}
.imind-tag-3:before {
	content: "\ed13";
}
.imind-tag-4:before {
	content: "\ed14";
}
.imind-tag-5:before {
	content: "\ed15";
}
.imind-tag:before {
	content: "\ed16";
}
.imind-taj-mahal:before {
	content: "\ed17";
}
.imind-talk-man:before {
	content: "\ed18";
}
.imind-tap:before {
	content: "\ed19";
}
.imind-target-market:before {
	content: "\ed1a";
}
.imind-target:before {
	content: "\ed1b";
}
.imind-taurus-2:before {
	content: "\ed1c";
}
.imind-taurus:before {
	content: "\ed1d";
}
.imind-taxi-2:before {
	content: "\ed1e";
}
.imind-taxi-sign:before {
	content: "\ed1f";
}
.imind-taxi:before {
	content: "\ed20";
}
.imind-teacher:before {
	content: "\ed21";
}
.imind-teapot:before {
	content: "\ed22";
}
.imind-technorati:before {
	content: "\ed23";
}
.imind-teddy-bear:before {
	content: "\ed24";
}
.imind-tee-mug:before {
	content: "\ed25";
}
.imind-telephone-2:before {
	content: "\ed26";
}
.imind-telephone:before {
	content: "\ed27";
}
.imind-telescope:before {
	content: "\ed28";
}
.imind-temperature-2:before {
	content: "\ed29";
}
.imind-temperature-3:before {
	content: "\ed2a";
}
.imind-temperature:before {
	content: "\ed2b";
}
.imind-temple:before {
	content: "\ed2c";
}
.imind-tennis-ball:before {
	content: "\ed2d";
}
.imind-tennis:before {
	content: "\ed2e";
}
.imind-tent:before {
	content: "\ed2f";
}
.imind-test-tube:before {
	content: "\ed30";
}
.imind-test-tube2:before {
	content: "\ed31";
}
.imind-testimonal:before {
	content: "\ed32";
}
.imind-text-box:before {
	content: "\ed33";
}
.imind-text-effect:before {
	content: "\ed34";
}
.imind-text-highlightcolor:before {
	content: "\ed35";
}
.imind-text-paragraph:before {
	content: "\ed36";
}
.imind-thailand:before {
	content: "\ed37";
}
.imind-the-whitehouse:before {
	content: "\ed38";
}
.imind-this-sideup:before {
	content: "\ed39";
}
.imind-thread:before {
	content: "\ed3a";
}
.imind-three-arrowfork:before {
	content: "\ed3b";
}
.imind-three-fingers:before {
	content: "\ed3c";
}
.imind-three-fingersdrag:before {
	content: "\ed3d";
}
.imind-three-fingersdrag2:before {
	content: "\ed3e";
}
.imind-three-fingerstouch:before {
	content: "\ed3f";
}
.imind-thumb:before {
	content: "\ed40";
}
.imind-thumbs-downsmiley:before {
	content: "\ed41";
}
.imind-thumbs-upsmiley:before {
	content: "\ed42";
}
.imind-thunder:before {
	content: "\ed43";
}
.imind-thunderstorm:before {
	content: "\ed44";
}
.imind-ticket:before {
	content: "\ed45";
}
.imind-tie-2:before {
	content: "\ed46";
}
.imind-tie-3:before {
	content: "\ed47";
}
.imind-tie-4:before {
	content: "\ed48";
}
.imind-tie:before {
	content: "\ed49";
}
.imind-tiger:before {
	content: "\ed4a";
}
.imind-time-backup:before {
	content: "\ed4b";
}
.imind-time-bomb:before {
	content: "\ed4c";
}
.imind-time-clock:before {
	content: "\ed4d";
}
.imind-time-fire:before {
	content: "\ed4e";
}
.imind-time-machine:before {
	content: "\ed4f";
}
.imind-time-window:before {
	content: "\ed50";
}
.imind-timer-2:before {
	content: "\ed51";
}
.imind-timer:before {
	content: "\ed52";
}
.imind-to-bottom:before {
	content: "\ed53";
}
.imind-to-bottom2:before {
	content: "\ed54";
}
.imind-to-left:before {
	content: "\ed55";
}
.imind-to-right:before {
	content: "\ed56";
}
.imind-to-top:before {
	content: "\ed57";
}
.imind-to-top2:before {
	content: "\ed58";
}
.imind-token-:before {
	content: "\ed59";
}
.imind-tomato:before {
	content: "\ed5a";
}
.imind-tongue:before {
	content: "\ed5b";
}
.imind-tooth-2:before {
	content: "\ed5c";
}
.imind-tooth:before {
	content: "\ed5d";
}
.imind-top-tobottom:before {
	content: "\ed5e";
}
.imind-touch-window:before {
	content: "\ed5f";
}
.imind-tourch:before {
	content: "\ed60";
}
.imind-tower-2:before {
	content: "\ed61";
}
.imind-tower-bridge:before {
	content: "\ed62";
}
.imind-tower:before {
	content: "\ed63";
}
.imind-trace:before {
	content: "\ed64";
}
.imind-tractor:before {
	content: "\ed65";
}
.imind-traffic-light:before {
	content: "\ed66";
}
.imind-traffic-light2:before {
	content: "\ed67";
}
.imind-train-2:before {
	content: "\ed68";
}
.imind-train:before {
	content: "\ed69";
}
.imind-tram:before {
	content: "\ed6a";
}
.imind-transform-2:before {
	content: "\ed6b";
}
.imind-transform-3:before {
	content: "\ed6c";
}
.imind-transform-4:before {
	content: "\ed6d";
}
.imind-transform:before {
	content: "\ed6e";
}
.imind-trash-withmen:before {
	content: "\ed6f";
}
.imind-tree-2:before {
	content: "\ed70";
}
.imind-tree-3:before {
	content: "\ed71";
}
.imind-tree-4:before {
	content: "\ed72";
}
.imind-tree-5:before {
	content: "\ed73";
}
.imind-tree:before {
	content: "\ed74";
}
.imind-trekking:before {
	content: "\ed75";
}
.imind-triangle-arrowdown:before {
	content: "\ed76";
}
.imind-triangle-arrowleft:before {
	content: "\ed77";
}
.imind-triangle-arrowright:before {
	content: "\ed78";
}
.imind-triangle-arrowup:before {
	content: "\ed79";
}
.imind-tripod-2:before {
	content: "\ed7a";
}
.imind-tripod-andvideo:before {
	content: "\ed7b";
}
.imind-tripod-withcamera:before {
	content: "\ed7c";
}
.imind-tripod-withgopro:before {
	content: "\ed7d";
}
.imind-trophy-2:before {
	content: "\ed7e";
}
.imind-trophy:before {
	content: "\ed7f";
}
.imind-truck:before {
	content: "\ed80";
}
.imind-trumpet:before {
	content: "\ed81";
}
.imind-tumblr:before {
	content: "\ed82";
}
.imind-turkey:before {
	content: "\ed83";
}
.imind-turn-down:before {
	content: "\ed84";
}
.imind-turn-down2:before {
	content: "\ed85";
}
.imind-turn-downfromleft:before {
	content: "\ed86";
}
.imind-turn-downfromright:before {
	content: "\ed87";
}
.imind-turn-left:before {
	content: "\ed88";
}
.imind-turn-left3:before {
	content: "\ed89";
}
.imind-turn-right:before {
	content: "\ed8a";
}
.imind-turn-right3:before {
	content: "\ed8b";
}
.imind-turn-up:before {
	content: "\ed8c";
}
.imind-turn-up2:before {
	content: "\ed8d";
}
.imind-turtle:before {
	content: "\ed8e";
}
.imind-tuxedo:before {
	content: "\ed8f";
}
.imind-tv:before {
	content: "\ed90";
}
.imind-twister:before {
	content: "\ed91";
}
.imind-twitter-2:before {
	content: "\ed92";
}
.imind-twitter:before {
	content: "\ed93";
}
.imind-two-fingers:before {
	content: "\ed94";
}
.imind-two-fingersdrag:before {
	content: "\ed95";
}
.imind-two-fingersdrag2:before {
	content: "\ed96";
}
.imind-two-fingersscroll:before {
	content: "\ed97";
}
.imind-two-fingerstouch:before {
	content: "\ed98";
}
.imind-two-windows:before {
	content: "\ed99";
}
.imind-type-pass:before {
	content: "\ed9a";
}
.imind-ukraine:before {
	content: "\ed9b";
}
.imind-umbrela:before {
	content: "\ed9c";
}
.imind-umbrella-2:before {
	content: "\ed9d";
}
.imind-umbrella-3:before {
	content: "\ed9e";
}
.imind-under-linetext:before {
	content: "\ed9f";
}
.imind-undo:before {
	content: "\eda0";
}
.imind-united-kingdom:before {
	content: "\eda1";
}
.imind-united-states:before {
	content: "\eda2";
}
.imind-university-2:before {
	content: "\eda3";
}
.imind-university:before {
	content: "\eda4";
}
.imind-unlike-2:before {
	content: "\eda5";
}
.imind-unlike:before {
	content: "\eda6";
}
.imind-unlock-2:before {
	content: "\eda7";
}
.imind-unlock-3:before {
	content: "\eda8";
}
.imind-unlock:before {
	content: "\eda9";
}
.imind-up--down:before {
	content: "\edaa";
}
.imind-up--down3:before {
	content: "\edab";
}
.imind-up-2:before {
	content: "\edac";
}
.imind-up-3:before {
	content: "\edad";
}
.imind-up-4:before {
	content: "\edae";
}
.imind-up:before {
	content: "\edaf";
}
.imind-upgrade:before {
	content: "\edb0";
}
.imind-upload-2:before {
	content: "\edb1";
}
.imind-upload-tocloud:before {
	content: "\edb2";
}
.imind-upload-window:before {
	content: "\edb3";
}
.imind-upload:before {
	content: "\edb4";
}
.imind-uppercase-text:before {
	content: "\edb5";
}
.imind-upward:before {
	content: "\edb6";
}
.imind-url-window:before {
	content: "\edb7";
}
.imind-usb-2:before {
	content: "\edb8";
}
.imind-usb-cable:before {
	content: "\edb9";
}
.imind-usb:before {
	content: "\edba";
}
.imind-user:before {
	content: "\edbb";
}
.imind-ustream:before {
	content: "\edbc";
}
.imind-vase:before {
	content: "\edbd";
}
.imind-vector-2:before {
	content: "\edbe";
}
.imind-vector-3:before {
	content: "\edbf";
}
.imind-vector-4:before {
	content: "\edc0";
}
.imind-vector-5:before {
	content: "\edc1";
}
.imind-vector:before {
	content: "\edc2";
}
.imind-venn-diagram:before {
	content: "\edc3";
}
.imind-vest-2:before {
	content: "\edc4";
}
.imind-vest:before {
	content: "\edc5";
}
.imind-viddler:before {
	content: "\edc6";
}
.imind-video-2:before {
	content: "\edc7";
}
.imind-video-3:before {
	content: "\edc8";
}
.imind-video-4:before {
	content: "\edc9";
}
.imind-video-5:before {
	content: "\edca";
}
.imind-video-6:before {
	content: "\edcb";
}
.imind-video-gamecontroller:before {
	content: "\edcc";
}
.imind-video-len:before {
	content: "\edcd";
}
.imind-video-len2:before {
	content: "\edce";
}
.imind-video-photographer:before {
	content: "\edcf";
}
.imind-video-tripod:before {
	content: "\edd0";
}
.imind-video:before {
	content: "\edd1";
}
.imind-vietnam:before {
	content: "\edd2";
}
.imind-view-height:before {
	content: "\edd3";
}
.imind-view-width:before {
	content: "\edd4";
}
.imind-vimeo:before {
	content: "\edd5";
}
.imind-virgo-2:before {
	content: "\edd6";
}
.imind-virgo:before {
	content: "\edd7";
}
.imind-virus-2:before {
	content: "\edd8";
}
.imind-virus-3:before {
	content: "\edd9";
}
.imind-virus:before {
	content: "\edda";
}
.imind-visa:before {
	content: "\eddb";
}
.imind-voice:before {
	content: "\eddc";
}
.imind-voicemail:before {
	content: "\eddd";
}
.imind-volleyball:before {
	content: "\edde";
}
.imind-volume-down:before {
	content: "\eddf";
}
.imind-volume-up:before {
	content: "\ede0";
}
.imind-vpn:before {
	content: "\ede1";
}
.imind-wacom-tablet:before {
	content: "\ede2";
}
.imind-waiter:before {
	content: "\ede3";
}
.imind-walkie-talkie:before {
	content: "\ede4";
}
.imind-wallet-2:before {
	content: "\ede5";
}
.imind-wallet-3:before {
	content: "\ede6";
}
.imind-wallet:before {
	content: "\ede7";
}
.imind-warehouse:before {
	content: "\ede8";
}
.imind-warning-window:before {
	content: "\ede9";
}
.imind-watch-2:before {
	content: "\edea";
}
.imind-watch-3:before {
	content: "\edeb";
}
.imind-watch:before {
	content: "\edec";
}
.imind-wave-2:before {
	content: "\eded";
}
.imind-wave:before {
	content: "\edee";
}
.imind-webcam:before {
	content: "\edef";
}
.imind-weight-lift:before {
	content: "\edf0";
}
.imind-wheelbarrow:before {
	content: "\edf1";
}
.imind-wheelchair:before {
	content: "\edf2";
}
.imind-width-window:before {
	content: "\edf3";
}
.imind-wifi-2:before {
	content: "\edf4";
}
.imind-wifi-keyboard:before {
	content: "\edf5";
}
.imind-wifi:before {
	content: "\edf6";
}
.imind-wind-turbine:before {
	content: "\edf7";
}
.imind-windmill:before {
	content: "\edf8";
}
.imind-window-2:before {
	content: "\edf9";
}
.imind-window:before {
	content: "\edfa";
}
.imind-windows-2:before {
	content: "\edfb";
}
.imind-windows-microsoft:before {
	content: "\edfc";
}
.imind-windows:before {
	content: "\edfd";
}
.imind-windsock:before {
	content: "\edfe";
}
.imind-windy:before {
	content: "\edff";
}
.imind-wine-bottle:before {
	content: "\ee00";
}
.imind-wine-glass:before {
	content: "\ee01";
}
.imind-wink:before {
	content: "\ee02";
}
.imind-winter-2:before {
	content: "\ee03";
}
.imind-winter:before {
	content: "\ee04";
}
.imind-wireless:before {
	content: "\ee05";
}
.imind-witch-hat:before {
	content: "\ee06";
}
.imind-witch:before {
	content: "\ee07";
}
.imind-wizard:before {
	content: "\ee08";
}
.imind-wolf:before {
	content: "\ee09";
}
.imind-woman-sign:before {
	content: "\ee0a";
}
.imind-womanman:before {
	content: "\ee0b";
}
.imind-womans-underwear:before {
	content: "\ee0c";
}
.imind-womans-underwear2:before {
	content: "\ee0d";
}
.imind-women:before {
	content: "\ee0e";
}
.imind-wonder-woman:before {
	content: "\ee0f";
}
.imind-wordpress:before {
	content: "\ee10";
}
.imind-worker-clothes:before {
	content: "\ee11";
}
.imind-worker:before {
	content: "\ee12";
}
.imind-wrap-text:before {
	content: "\ee13";
}
.imind-wreath:before {
	content: "\ee14";
}
.imind-wrench:before {
	content: "\ee15";
}
.imind-x-box:before {
	content: "\ee16";
}
.imind-x-ray:before {
	content: "\ee17";
}
.imind-xanga:before {
	content: "\ee18";
}
.imind-xing:before {
	content: "\ee19";
}
.imind-yacht:before {
	content: "\ee1a";
}
.imind-yahoo-buzz:before {
	content: "\ee1b";
}
.imind-yahoo:before {
	content: "\ee1c";
}
.imind-yelp:before {
	content: "\ee1d";
}
.imind-yes:before {
	content: "\ee1e";
}
.imind-ying-yang:before {
	content: "\ee1f";
}
.imind-youtube:before {
	content: "\ee20";
}
.imind-z-a:before {
	content: "\ee21";
}
.imind-zebra:before {
	content: "\ee22";
}
.imind-zombie:before {
	content: "\ee23";
}
.imind-zoom-gesture:before {
	content: "\ee24";
}
.imind-zootool:before {
	content: "\ee25";
}
