/*
    Modal
 */
.modal-ph {
    /* See bootstrap-alt*/
}

.modal__overlay {
    position: absolute; /* 2019.04.10: changed from fixed to absolute for IOS display*/
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    /*
    2019.04.10: changed from scroll to auto.
    2019.04.12: this doesn't work because the menu gets cutoff in full screen mode

    Solution: function to check the height of the modal and set to scroll of the menu is > the screen height.

    */
    overflow-y: visible;
}

/*
    For use in cases where the modal is larger than its container and there is not room on the screen to show the entire modal
*/
/*noinspection CssUnusedSymbol*/
.modal__overlay-scroll {
    overflow-y:auto!important;
}

/*noinspection CssUnusedSymbol*/
.modal__overlay_no_popup {
    /* removed for non-popup   --  position: fixed; */
    position: relative;
    margin-top: 0;  /* adjust the popup relative to the menu container */

    /* fill the container */
    padding-bottom: 56.25%;
    height: 0;


    /* same as modal__overlay */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
}

.modal__container {
    /* fill the container */
    /* position: fixed is needed to get the scrolling to work on small, landscape screens
        https://stackoverflow.com/questions/9280258/prevent-body-scrolling-but-allow-overlay-scrolling

        Later:
            position: absolute;  is the setting that actually works for this
    */
    position: absolute;
    margin-top: 0;  /* adjust the popup relative to the menu container */
    /*  for horizontal centering */
    margin-right: 10%;
    height: auto;

    background-color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    overflow-y: auto;
    box-sizing: border-box;

    border-style: solid;
    border-color: black;
    border-width: 1px;
    max-width: 500px;

    text-align: left;

    /* Next section added for centering the frame but still allowing scroll */
    top: 10%;
    left: 50%;
    margin-left: -25%;
}

/*noinspection CssUnusedSymbol*/
.modal__overlay-no-top-offset {
    top: 0;
}

/* Overrides for more-narrow screens */
@media only screen and (max-width: 450px) {
    .modal__container {
        margin-left: 0;
        margin-right: 0;
        left: unset;
    }
}

.modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    color: black;
    margin-top: 0;
    padding: 0;
}
.modal__header.h2 {
    color: black;
}

.modal__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.25;
    color: black!important;
    box-sizing: border-box;
}

.modal__close {
    background: transparent!important;
    border: 0!important;;
    color: black!important;;
}

.modal__header .modal__close:before { content: "\2715"; }

.modal__content {
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5;
    color: rgba(0,0,0,.8);
}

.modal__footer {
    margin-top: 2px;
    margin-bottom: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: none;
    min-height: 0;
    padding-left: 0;
    padding-right:0;
}


/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes mmslideIn {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}

.modal-inline {
    line-height: initial;

    /* fill the container */
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    z-index: 2147483647!important;
}

/**
  Menu helper
 */
.menu-helper-container {
    display: none; /* default to not display */
}

.list-group-ph {
    margin-bottom: 0px;
}

/* modifier of the bootstrap list-group-item class, for displaying more compactly */
.list-group-item-compact {
    padding: 0.25em 0.75em!important;
}

/* width 80% matches the button width during the video menu */
/*noinspection CssUnusedSymbol*/
.list-group-item-80pct {
    width: 80%;
}
.list-group-item-95pct {
    width: 95%;
}

/* Overrides for more-narrow screens */
@media only screen and (max-width: 400px) {
    a.list-group-item {
        width: 95%;
    }
}


.btn-primary-video-menu {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

/* modifier of the bootstrap list-group class, for limiting the vertical size, displaying more compactly */
.list-group-vertical-limit {
    max-height: 30vh;
}

.btn-primary-video-menu-80pct {
    width: 80%;
}
/* Important that this should not be 100% because that will cause X-overflow on popup, which messes up IOS scrolling */
/*noinspection CssUnusedSymbol*/
.btn-primary-video-menu-100pct {
    width: 95%;
}

/* Buttons, inside the video menu -- dark/default */
.btn-primary-video-menu-color {
    background-color: var(--ph-player-button-background-active)!important;
    color: var(--ph-player-button-foreground)!important;
}

/* Buttons, inside the video menu -- dark/default */
.btn-primary-video-menu-color-light {
    color: var(--ph-player-button-background-active)!important;
    background-color: var(--ph-player-button-foreground)!important;
    border: none!important;
}

/* The button in the Contact screen */
.btn-video-menu-contact {
    background-color: var(--ph-player-button-background-active)!important;
    color: var(--ph-player-button-foreground)!important;
}

/* The text inputs in the Contact screen */
.text-video-menu-contact:focus {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px var(--ph-player-button-background-active)!important;
    border-color: var(--ph-player-button-background-active)!important;
}

.text-video-clip-duration {
    font-size: 9px;
    float: right;
    padding-top: 3px;
}

/* Video menu top level container without iframe */
.video-menu {
    width: 100%;
}

/* Video Menu top level container*/
.video-menu-body-iframe {
    overflow: hidden;
}

/* Container on the video menu, to hold the "% complete" progress */
.video-menu-percent-complete-container {
    height: 15px;
    width: 15px;
    margin-left: 15px;
    margin-top: 2px;
}

.video-menu-clip-text-title {
    vertical-align: top;
    font-size: 15px;
}

.video-menu-clip-text-subtitle {
    font-size: 12px;
    color: #999;
}

/* Inline container */
.inline-container {
    display: inline-block;
}

/* Inline container that also floats right */
.inline-container-right {
    display: table-cell;
    float: right;
    padding-left: 5px;
}

.inline-max {
    width: 100%;
}

.inline-row {
    display: table-row;
}
.inline-cell {
    display: table-cell;
}

.video-menu-clip-selected-indicator-container {
    width: 20px;
    min-width: 20px;
    line-height: 0;
    position: relative;
}

.video-menu-clip-selected-indicator {
    position: absolute;
    top: 3px;
    color: var(--ph-player-button-background-active);
}
.video-menu-clip-selected-indicator.non-white {
    color: black;
}

/* Overrides for more-narrow screens */
@media only screen and (max-width: 400px) {
    .btn-primary-video-menu-80pct {
        width: 95%;
    }
}

.spinner-small {

}

.spinner-medium {

}

.spinner-large {

}

/* Settings for full screen emulation */
.ph-full-screen-body {
    overflow: hidden!important;
}

/* Deals with a behavior on IOS, in which the entire HTML is the viewing area:
    https://stackoverflow.com/questions/12434416/overflowhidden-on-body-is-broken-in-ios6
*/
html.video-menu-background-no-scroll-html {
    /*
        2019.04.13: removed overflow because it causes the screen to reset and it seems to not be required
        for IOS to work correctly

        overflow: hidden!important;
     */
    height: 100%!important;
    margin: 0!important;
    padding: 0!important;
    border: none!important;
}

body.video-menu-background-no-scroll-body {
    overflow: hidden!important;
    position: relative!important;

    box-sizing: border-box!important;
    margin: 0!important;
    height: 100%!important;
}

/*
    Logo
*/
img.video-menu-logo-heading {
    padding: 3px;
    margin-right: 10px;
    width: 15vw;
    max-width: 150px;
    min-width: 100px;
}

/*
    Icon preload (hidden)
*/
.video-menu-icon-preload {
    visibility: hidden;
}