@import url(https://fonts.googleapis.com/css?family=Oxygen:400,300,700);
@import url(https://fonts.googleapis.com/css?family=Libre+Franklin:400,400i,600,600i);



body,
input,
textarea,
.mbr-company .list-group-text,
.mbr-section-text {
  font-family: 'Libre Franklin', serif;
}
.mbr-footer-content li,
.mbr-footer .mbr-contacts li {
  font-family: 'Libre Franklin', serif;
}
.alert,
h1,
h2,
h3,
h4,
h5,
h6,
.mbr-figure .mbr-figure-caption,
.mbr-gallery-title,
.mbr-map [data-state-details],
.mbr-price,
.mbr-section-title {
  font-family: 'Oxygen', sans-serif;
}
.btn {
  font-family: 'Libre Franklin', serif;
}
.mbr-footer-content h1,
.mbr-footer .mbr-contacts h1,
.mbr-footer-content h2,
.mbr-footer .mbr-contacts h2,
.mbr-footer-content h3,
.mbr-footer .mbr-contacts h3,
.mbr-footer-content h4,
.mbr-footer .mbr-contacts h4,
.mbr-footer-content p strong,
.mbr-footer .mbr-contacts p strong,
.mbr-footer-content strong,
.mbr-footer .mbr-contacts strong {
  font-family: 'Oxygen', sans-serif;
}
.btn-sm,
.mbr-section-hero .mbr-section-lead,
.mbr-cards .card-subtitle,
.mbr-testimonial .card-block,
.mbr-section-subtitle {
  font-family: 'Libre Franklin', serif;
  font-style: normal;
}
.mbr-author-name {
  font-family: 'Oxygen', sans-serif;
}
.mbr-author-desc {
  font-family: 'Libre Franklin', serif;
  font-style: normal;
}
.mbr-plan-title {
  font-family: 'Oxygen', sans-serif;
}
.mbr-plan-subtitle,
.mbr-plan-price-desc {
  font-family: 'Libre Franklin', serif;
  font-style: normal;
}
.bg-primary {
  background-color: #5aac98 !important;
}
.bg-success {
  background-color: #1e7c3a !important;
}
.bg-info {
  background-color: #74a3c7 !important;
}
.bg-warning {
  background-color: #ffd785 !important;
}
.bg-danger {
  background-color: #ff9685 !important;
}
.btn-primary {
  background-color: #5aac98;
  border-color: #5aac98;
  color: #ffffff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active {
  color: #ffffff;
  background-color: #3e7b6c;
  border-color: #3e7b6c;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #3e7b6c !important;
  border-color: #3e7b6c !important;
}
.btn-secondary {
  background-color: #a25dae;
  border-color: #a25dae;
  color: #ffffff;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary.active {
  color: #ffffff;
  background-color: #763f7f;
  border-color: #763f7f;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #763f7f !important;
  border-color: #763f7f !important;
}
.btn-info {
  background-color: #74a3c7;
  border-color: #74a3c7;
  color: #ffffff;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info:active,
.btn-info.active {
  color: #ffffff;
  background-color: #447eaa;
  border-color: #447eaa;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #447eaa !important;
  border-color: #447eaa !important;
}
.btn-success {
  background-color: #1e7c3a;
  border-color: #1e7c3a;
  color: #ffffff;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:active,
.btn-success.active {
  color: #ffffff;
  background-color: #0f3e1d;
  border-color: #0f3e1d;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff !important;
  background-color: #0f3e1d !important;
  border-color: #0f3e1d !important;
}
.btn-warning {
  background-color: #ffd785;
  border-color: #ffd785;
  color: #ffffff;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning:active,
.btn-warning.active {
  color: #ffffff;
  background-color: #ffbe38;
  border-color: #ffbe38;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff !important;
  background-color: #ffbe38 !important;
  border-color: #ffbe38 !important;
}
.btn-danger {
  background-color: #ff9685;
  border-color: #ff9685;
  color: #ffffff;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:active,
.btn-danger.active {
  color: #ffffff;
  background-color: #ff5438;
  border-color: #ff5438;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #ff5438 !important;
  border-color: #ff5438 !important;
}
.btn-primary-outline {
  background: none;
  border-color: #366a5e;
  color: #366a5e;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline:active,
.btn-primary-outline.active {
  color: #ffffff;
  background-color: #5aac98;
  border-color: #5aac98;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #ffffff !important;
  background-color: #5aac98 !important;
  border-color: #5aac98 !important;
}
.btn-secondary-outline {
  background: none;
  border-color: #66376e;
  color: #66376e;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline:active,
.btn-secondary-outline.active {
  color: #ffffff;
  background-color: #a25dae;
  border-color: #a25dae;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #ffffff !important;
  background-color: #a25dae !important;
  border-color: #a25dae !important;
}
.btn-info-outline {
  background: none;
  border-color: #3d7198;
  color: #3d7198;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline:active,
.btn-info-outline.active {
  color: #ffffff;
  background-color: #74a3c7;
  border-color: #74a3c7;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #74a3c7 !important;
  border-color: #74a3c7 !important;
}
.btn-success-outline {
  background: none;
  border-color: #0a2a14;
  color: #0a2a14;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline:active,
.btn-success-outline.active {
  color: #ffffff;
  background-color: #1e7c3a;
  border-color: #1e7c3a;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #ffffff !important;
  background-color: #1e7c3a !important;
  border-color: #1e7c3a !important;
}
.btn-warning-outline {
  background: none;
  border-color: #ffb61f;
  color: #ffb61f;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline:active,
.btn-warning-outline.active {
  color: #ffffff;
  background-color: #ffd785;
  border-color: #ffd785;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #ffffff !important;
  background-color: #ffd785 !important;
  border-color: #ffd785 !important;
}
.btn-danger-outline {
  background: none;
  border-color: #ff3e1f;
  color: #ff3e1f;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline:active,
.btn-danger-outline.active {
  color: #ffffff;
  background-color: #ff9685;
  border-color: #ff9685;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #ff9685 !important;
  border-color: #ff9685 !important;
}
.text-primary {
  color: #5aac98 !important;
}
.text-success {
  color: #1e7c3a !important;
}
.text-info {
  color: #74a3c7 !important;
}
.text-warning {
  color: #ffd785 !important;
}
.text-danger {
  color: #ff9685 !important;
}
.alert-success {
  background-color: #1e7c3a;
}
.alert-info {
  background-color: #74a3c7;
}
.alert-warning {
  background-color: #ffd785;
}
.alert-danger {
  background-color: #ff9685;
}
.mbr-company .list-group-item.active .list-group-text {
  color: #5aac98;
}
.mbr-footer p a,
.mbr-footer ul a {
  color: #5aac98;
}
.mbr-footer-content li::before,
.mbr-footer .mbr-contacts li::before {
  background: #5aac98;
}
.mbr-footer-content li a:hover,
.mbr-footer .mbr-contacts li a:hover {
  color: #5aac98;
}
.lead a,
.lead a:hover {
  color: #5aac98;
}
.lead blockquote {
  border-color: #5aac98;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #afd7cd;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #47d271;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #cfdfec;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-small-footer a,
.mbr-gallery-filter li:hover {
  color: #000;
}
.scrollToTop_wraper {
  opacity: 0 !important;
}
/* DirectM */
a {
  color: #74a3c7;
}
a:hover {
  color: #333;
}
/*header1*/
.header1 .mbr-arrow a i {
  color: #ffffff;
}
.header1 .mbr-arrow a:hover i {
  color: #5aac98;
}
/*header2*/
.header1.header2 .mbr-arrow a i {
  color: #222222;
}
.header1.header2 .mbr-arrow a:hover i {
  color: #5aac98;
}
/*features1*/
.features1 .carousel-indicators li.active {
  border: 5px solid #5aac98;
}
/*features12*/
.features12 .card .mbr-iconfont,
.features14 .card .mbr-iconfont,
.features16 .card .mbr-iconfont {
  font-family: 'Oxygen', sans-serif;
}
/*msg-box1*/
.msg-box1 ul li:before {
  background-color: #5aac98;
}
.msg-box1 .round-block {
  background: linear-gradient(45deg, #4c9784, #5aac98, #7cbdad);
}
/*tabs1*/
.tabs1 ul li a.active {
  border-bottom-color: #5aac98 !important;
}
.tabs1 .tab-list-wraper ul li:before {
  background-color: #5aac98;
}
.tabs1 .colorBG {
  background-color: #5aac98;
}
/*content*/
.content2 blockquote {
  border-left: 3px solid #5aac98;
}
.content4 p {
  border-bottom: 3px solid #5aac98;
  border-top: 3px solid #5aac98;
}
/*follow1*/
.follow1 .btn-social {
  color: #5aac98;
  border-color: #5aac98;
}
.follow1 .btn-social:hover {
  color: #7cbdad;
  background: rgba(90, 172, 152, 0.1);
  border-color: #7cbdad;
}
/*share1*/
.share1 .btn-social {
  color: #5aac98;
  border-color: #5aac98;
}
.share1 .btn-social:hover {
  color: #7cbdad;
  background: rgba(90, 172, 152, 0.1);
  border-color: #7cbdad;
}
/* Slider */
.mbr-slider .carousel-indicators .active {
  background-color: #5aac98;
}
/* Gallery */
.mbr-gallery-filter ul li {
  font-family: 'Libre Franklin', serif;
}
.mbr-gallery-filter li {
  color: #74a3c7;
}
.btn-empty:focus {
  color: #5aac98;
}
/* captions */
.mbr-figure-caption {
  background-color: #616161;
  color: #fff;
}
/* tabs1 */
.tabs1 ul li a {
  color: #74a3c7;
}
/*tabs3*/
.tabs3 .nav-tabs .nav-link.active {
  border: 5px solid #5aac98;
}
/* testimonials1*/
.testimonials1 .carousel-indicators li.active {
  border: 5px solid #5aac98;
}
.hamburger-icon {
  background-color: #5aac98 !important;
}
.hamburger-icon:before {
  background-color: #5aac98;
}
.hamburger-icon:after {
  background-color: #5aac98;
}
#menu1-0 .hide-buttons .nav-btn {
  display: none !important;
}
#menu1-0 .navbar-toggler {
  color: #74a3c7;
}
#menu1-0 .close-icon::before,
#menu1-0 .close-icon::after {
  background-color: #74a3c7;
}
#menu1-0 .link,
#menu1-0 .dropdown-item {
  color: #74a3c7;
  font-family: 'Libre Franklin', serif;
}
#menu1-0 .link {
  font-size: 0.8125rem;
}
#menu1-0 .dropdown-item,
#menu1-0 .nav-dropdown-sm .link {
  font-size: 0.880rem;
}
#menu1-0 .link:hover,
#menu1-0 .link:focus {
  color: #5aac98;
}
#menu1-0 .dropdown-item:hover::before,
#menu1-0 .dropdown-item:focus::before {
  background: #5aac98;
}
#menu1-0 .dropdown-item:hover,
#menu1-0 .dropdown-item:focus {
  color: #74a3c7;
}
#menu1-0 .link[aria-expanded="true"],
#menu1-0 .dropdown-menu {
  background: #ffffff;
}
#menu1-0 .nav-dropdown-sm .link:focus,
#menu1-0 .nav-dropdown-sm .link:hover,
#menu1-0 .nav-dropdown-sm .dropdown-item:focus,
#menu1-0 .nav-dropdown-sm .dropdown-item:hover {
  background: #f7f7f7!important;
}
#menu1-0 .navbar,
#menu1-0 .nav-dropdown-sm,
#menu1-0 .nav-dropdown-sm .link[aria-expanded="true"],
#menu1-0 .nav-dropdown-sm .dropdown-menu {
  background: #fff;
}
#menu1-0 .bg-color.transparent .link {
  color: #74a3c7;
  transition: none;
}
#menu1-0 .bg-color.transparent.opened .link {
  transition: color 0.2s ease-in-out;
}
#menu1-0 .bg-color.transparent.opened .link:hover,
#menu1-0 .bg-color.transparent.opened .link:focus {
  color: #5aac98;
}
#menu1-0 .link[aria-expanded="true"],
#menu1-0 .dropdown-item[aria-expanded="true"] {
  color: #5aac98!important;
}
#menu1-0 .navbar-caption {
  font-size: 24px;
}
#footer2-7 .footer-list {
  color: #74a3c7;
}
#footer2-7 .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer2-7 .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#footer2-7 .footer-list {
  color: #74a3c7;
}
#footer2-7 .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer2-7 .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#menu1-b .hide-buttons .nav-btn {
  display: none !important;
}
#menu1-b .navbar-toggler {
  color: #74a3c7;
}
#menu1-b .close-icon::before,
#menu1-b .close-icon::after {
  background-color: #74a3c7;
}
#menu1-b .link,
#menu1-b .dropdown-item {
  color: #74a3c7;
  font-family: 'Libre Franklin', serif;
}
#menu1-b .link {
  font-size: 0.8125rem;
}
#menu1-b .dropdown-item,
#menu1-b .nav-dropdown-sm .link {
  font-size: 0.880rem;
}
#menu1-b .link:hover,
#menu1-b .link:focus {
  color: #5aac98;
}
#menu1-b .dropdown-item:hover::before,
#menu1-b .dropdown-item:focus::before {
  background: #5aac98;
}
#menu1-b .dropdown-item:hover,
#menu1-b .dropdown-item:focus {
  color: #74a3c7;
}
#menu1-b .link[aria-expanded="true"],
#menu1-b .dropdown-menu {
  background: #ffffff;
}
#menu1-b .nav-dropdown-sm .link:focus,
#menu1-b .nav-dropdown-sm .link:hover,
#menu1-b .nav-dropdown-sm .dropdown-item:focus,
#menu1-b .nav-dropdown-sm .dropdown-item:hover {
  background: #f7f7f7!important;
}
#menu1-b .navbar,
#menu1-b .nav-dropdown-sm,
#menu1-b .nav-dropdown-sm .link[aria-expanded="true"],
#menu1-b .nav-dropdown-sm .dropdown-menu {
  background: #fff;
}
#menu1-b .bg-color.transparent .link {
  color: #74a3c7;
  transition: none;
}
#menu1-b .bg-color.transparent.opened .link {
  transition: color 0.2s ease-in-out;
}
#menu1-b .bg-color.transparent.opened .link:hover,
#menu1-b .bg-color.transparent.opened .link:focus {
  color: #5aac98;
}
#menu1-b .link[aria-expanded="true"],
#menu1-b .dropdown-item[aria-expanded="true"] {
  color: #5aac98!important;
}
#menu1-b .navbar-caption {
  font-size: 24px;
}
#form3-m .card {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
#form3-m .card-img a {
  color: #616161;
  border-color: #616161;
  border-width: 2px;
  line-height: 72px;
}
#form3-m .heading {
  background: #447eaa;
}
#footer2-c .footer-list {
  color: #74a3c7;
}
#footer2-c .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer2-c .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#footer2-c .footer-list {
  color: #74a3c7;
}
#footer2-c .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer2-c .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#menu1-d .hide-buttons .nav-btn {
  display: none !important;
}
#menu1-d .navbar-toggler {
  color: #74a3c7;
}
#menu1-d .close-icon::before,
#menu1-d .close-icon::after {
  background-color: #74a3c7;
}
#menu1-d .link,
#menu1-d .dropdown-item {
  color: #74a3c7;
  font-family: 'Libre Franklin', serif;
}
#menu1-d .link {
  font-size: 0.8125rem;
}
#menu1-d .dropdown-item,
#menu1-d .nav-dropdown-sm .link {
  font-size: 0.880rem;
}
#menu1-d .link:hover,
#menu1-d .link:focus {
  color: #5aac98;
}
#menu1-d .dropdown-item:hover::before,
#menu1-d .dropdown-item:focus::before {
  background: #5aac98;
}
#menu1-d .dropdown-item:hover,
#menu1-d .dropdown-item:focus {
  color: #74a3c7;
}
#menu1-d .link[aria-expanded="true"],
#menu1-d .dropdown-menu {
  background: #ffffff;
}
#menu1-d .nav-dropdown-sm .link:focus,
#menu1-d .nav-dropdown-sm .link:hover,
#menu1-d .nav-dropdown-sm .dropdown-item:focus,
#menu1-d .nav-dropdown-sm .dropdown-item:hover {
  background: #f7f7f7!important;
}
#menu1-d .navbar,
#menu1-d .nav-dropdown-sm,
#menu1-d .nav-dropdown-sm .link[aria-expanded="true"],
#menu1-d .nav-dropdown-sm .dropdown-menu {
  background: #fff;
}
#menu1-d .bg-color.transparent .link {
  color: #74a3c7;
  transition: none;
}
#menu1-d .bg-color.transparent.opened .link {
  transition: color 0.2s ease-in-out;
}
#menu1-d .bg-color.transparent.opened .link:hover,
#menu1-d .bg-color.transparent.opened .link:focus {
  color: #5aac98;
}
#menu1-d .link[aria-expanded="true"],
#menu1-d .dropdown-item[aria-expanded="true"] {
  color: #5aac98!important;
}
#menu1-d .navbar-caption {
  font-size: 24px;
}
#footer2-e .footer-list {
  color: #74a3c7;
}
#footer2-e .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer2-e .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#footer2-e .footer-list {
  color: #74a3c7;
}
#footer2-e .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer2-e .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#menu1-n .hide-buttons .nav-btn {
  display: none !important;
}
#menu1-n .navbar-toggler {
  color: #74a3c7;
}
#menu1-n .close-icon::before,
#menu1-n .close-icon::after {
  background-color: #74a3c7;
}
#menu1-n .link,
#menu1-n .dropdown-item {
  color: #74a3c7;
  font-family: 'Libre Franklin', serif;
}
#menu1-n .link {
  font-size: 0.8125rem;
}
#menu1-n .dropdown-item,
#menu1-n .nav-dropdown-sm .link {
  font-size: 0.880rem;
}
#menu1-n .link:hover,
#menu1-n .link:focus {
  color: #5aac98;
}
#menu1-n .dropdown-item:hover::before,
#menu1-n .dropdown-item:focus::before {
  background: #5aac98;
}
#menu1-n .dropdown-item:hover,
#menu1-n .dropdown-item:focus {
  color: #74a3c7;
}
#menu1-n .link[aria-expanded="true"],
#menu1-n .dropdown-menu {
  background: #ffffff;
}
#menu1-n .nav-dropdown-sm .link:focus,
#menu1-n .nav-dropdown-sm .link:hover,
#menu1-n .nav-dropdown-sm .dropdown-item:focus,
#menu1-n .nav-dropdown-sm .dropdown-item:hover {
  background: #f7f7f7!important;
}
#menu1-n .navbar,
#menu1-n .nav-dropdown-sm,
#menu1-n .nav-dropdown-sm .link[aria-expanded="true"],
#menu1-n .nav-dropdown-sm .dropdown-menu {
  background: #fff;
}
#menu1-n .bg-color.transparent .link {
  color: #74a3c7;
  transition: none;
}
#menu1-n .bg-color.transparent.opened .link {
  transition: color 0.2s ease-in-out;
}
#menu1-n .bg-color.transparent.opened .link:hover,
#menu1-n .bg-color.transparent.opened .link:focus {
  color: #5aac98;
}
#menu1-n .link[aria-expanded="true"],
#menu1-n .dropdown-item[aria-expanded="true"] {
  color: #5aac98!important;
}
#menu1-n .navbar-caption {
  font-size: 24px;
}
#footer2-o .footer-list {
  color: #74a3c7;
}
#footer2-o .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer2-o .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#footer2-o .footer-list {
  color: #74a3c7;
}
#footer2-o .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer2-o .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#header5-p .mbr-section-title,
#header5-p .mbr-section-subtitle {
  margin-bottom: 1rem;
  color: #ffffff;
}
#header5-p .mbr-table-cell {
  vertical-align: middle;
}
#menu1-4 .hide-buttons .nav-btn {
  display: none !important;
}
#menu1-4 .navbar-toggler {
  color: #74a3c7;
}
#menu1-4 .close-icon::before,
#menu1-4 .close-icon::after {
  background-color: #74a3c7;
}
#menu1-4 .link,
#menu1-4 .dropdown-item {
  color: #74a3c7;
  font-family: 'Libre Franklin', serif;
}
#menu1-4 .link {
  font-size: 0.8125rem;
}
#menu1-4 .dropdown-item,
#menu1-4 .nav-dropdown-sm .link {
  font-size: 0.880rem;
}
#menu1-4 .link:hover,
#menu1-4 .link:focus {
  color: #5aac98;
}
#menu1-4 .dropdown-item:hover::before,
#menu1-4 .dropdown-item:focus::before {
  background: #5aac98;
}
#menu1-4 .dropdown-item:hover,
#menu1-4 .dropdown-item:focus {
  color: #74a3c7;
}
#menu1-4 .link[aria-expanded="true"],
#menu1-4 .dropdown-menu {
  background: #ffffff;
}
#menu1-4 .nav-dropdown-sm .link:focus,
#menu1-4 .nav-dropdown-sm .link:hover,
#menu1-4 .nav-dropdown-sm .dropdown-item:focus,
#menu1-4 .nav-dropdown-sm .dropdown-item:hover {
  background: #f7f7f7!important;
}
#menu1-4 .navbar,
#menu1-4 .nav-dropdown-sm,
#menu1-4 .nav-dropdown-sm .link[aria-expanded="true"],
#menu1-4 .nav-dropdown-sm .dropdown-menu {
  background: #fff;
}
#menu1-4 .bg-color.transparent .link {
  color: #74a3c7;
  transition: none;
}
#menu1-4 .bg-color.transparent.opened .link {
  transition: color 0.2s ease-in-out;
}
#menu1-4 .bg-color.transparent.opened .link:hover,
#menu1-4 .bg-color.transparent.opened .link:focus {
  color: #5aac98;
}
#menu1-4 .link[aria-expanded="true"],
#menu1-4 .dropdown-item[aria-expanded="true"] {
  color: #5aac98!important;
}
#menu1-4 .navbar-caption {
  font-size: 24px;
}
#footer1-5 .footer-list {
  color: #74a3c7;
}
#footer1-5 .btn-social {
  border-color: #74a3c7;
  color: #74a3c7;
}
#footer1-5 .btn-social:hover {
  background: #74a3c7;
  color: #fff;
}
#features22-6 .card .mbr-iconfont {
  background: #74a3c7;
  border-color: #74a3c7;
}
#features22-6 .card-text {
  text-align: left;
}
