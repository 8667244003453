/**
    Classes used by all environments
 */

/*
* Used to hide an element
 */
.hidden {
    visibility: collapse;
    opacity: 0;
    display:none;
}
