.btn-ph-twitter{
    background-color: #55acee!important;
}

.btn-ph-twitter:hover,
.btn-ph-twitter:focus,
.btn-ph-twitter:active
{
    background-color: #52a9eb!important;
}

.btn-ph-facebook {
    background-color: #3b5998!important;
}
.btn-ph-facebook:hover,
.btn-ph-facebook:focus,
.btn-ph-facebook:active
{
    background-color: #385695!important;
}

.btn-ph-linkedin {
    background-color: #0077B5!important;
}
.btn-ph-linkedin:hover,
.btn-ph-linkedin:focus,
.btn-ph-linkedin:active
{
    background-color: #0074B2!important;
}

.btn-ph-instagram {
    background-color: #fb3958!important;
}
.btn-ph-instagram:hover,
.btn-ph-instagram:focus,
.btn-ph-instagram:active
{
    background-color: #f83655;
}

.btn-ph-social-circle {
    border-width: 1px;
    border-radius: 30px;
    padding-right: 23px;
    padding-left: 23px;
}

.btn-ph-social {
    color: #fff!important;;
    height: 25px!important;;
    width: 25px!important;;
    padding: 1px!important;;
    font-size: 20px!important;;
    overflow: hidden!important;;
    position: relative!important;;
    line-height: normal!important;;
    border: none!important;;
    border-radius: 3px!important;;
    margin: 3px 1px!important;;
    font-family: Arial!important;
}

.btn-ph-social:hover,
.btn-ph-social:focus,
.btn-ph-social:active
{
    color: #fff;
    border: none;
}


