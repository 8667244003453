@font-face {
  font-family: 'simple-line-icons';
  src: url('fonts/simple-line-icons.eot?v=2.4.0');
  /* src: url('fonts/simple-line-icons.eot?v=2.4.0#iefix') format('embedded-opentype'), url('fonts/simple-line-icons.woff2?v=2.4.0') format('woff2'), url('fonts/simple-line-icons.ttf?v=2.4.0') format('truetype'), url('fonts/simple-line-icons.woff?v=2.4.0') format('woff'), url('fonts/simple-line-icons.svg?v=2.4.0#simple-line-icons') format('svg'); */
  src: url('fonts/simple-line-icons.eot?v=2.4.0#iefix') format('embedded-opentype'), url('fonts/simple-line-icons.ttf?v=2.4.0') format('truetype'), url('fonts/simple-line-icons.woff?v=2.4.0') format('woff'), url('fonts/simple-line-icons.svg?v=2.4.0#simple-line-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*
 use the following css code if you want to have a class per icon.
 instead of a list of all class selectors, you can use the generic [class*="icon-"] selector, but it's slower:
*/
.sli-user,
.sli-people,
.sli-user-female,
.sli-user-follow,
.sli-user-following,
.sli-user-unfollow,
.sli-login,
.sli-logout,
.sli-emotsmile,
.sli-phone,
.sli-call-end,
.sli-call-in,
.sli-call-out,
.sli-map,
.sli-location-pin,
.sli-direction,
.sli-directions,
.sli-compass,
.sli-layers,
.sli-menu,
.sli-list,
.sli-options-vertical,
.sli-options,
.sli-arrow-down,
.sli-arrow-left,
.sli-arrow-right,
.sli-arrow-up,
.sli-arrow-up-circle,
.sli-arrow-left-circle,
.sli-arrow-right-circle,
.sli-arrow-down-circle,
.sli-check,
.sli-clock,
.sli-plus,
.sli-minus,
.sli-close,
.sli-event,
.sli-exclamation,
.sli-organization,
.sli-trophy,
.sli-screen-smartphone,
.sli-screen-desktop,
.sli-plane,
.sli-notebook,
.sli-mustache,
.sli-mouse,
.sli-magnet,
.sli-energy,
.sli-disc,
.sli-cursor,
.sli-cursor-move,
.sli-crop,
.sli-chemistry,
.sli-speedometer,
.sli-shield,
.sli-screen-tablet,
.sli-magic-wand,
.sli-hourglass,
.sli-graduation,
.sli-ghost,
.sli-game-controller,
.sli-fire,
.sli-eyeglass,
.sli-envelope-open,
.sli-envelope-letter,
.sli-bell,
.sli-badge,
.sli-anchor,
.sli-wallet,
.sli-vector,
.sli-speech,
.sli-puzzle,
.sli-printer,
.sli-present,
.sli-playlist,
.sli-pin,
.sli-picture,
.sli-handbag,
.sli-globe-alt,
.sli-globe,
.sli-folder-alt,
.sli-folder,
.sli-film,
.sli-feed,
.sli-drop,
.sli-drawer,
.sli-docs,
.sli-doc,
.sli-diamond,
.sli-cup,
.sli-calculator,
.sli-bubbles,
.sli-briefcase,
.sli-book-open,
.sli-basket-loaded,
.sli-basket,
.sli-bag,
.sli-action-undo,
.sli-action-redo,
.sli-wrench,
.sli-umbrella,
.sli-trash,
.sli-tag,
.sli-support,
.sli-frame,
.sli-size-fullscreen,
.sli-size-actual,
.sli-shuffle,
.sli-share-alt,
.sli-share,
.sli-rocket,
.sli-question,
.sli-pie-chart,
.sli-pencil,
.sli-note,
.sli-loop,
.sli-home,
.sli-grid,
.sli-graph,
.sli-microphone,
.sli-music-tone-alt,
.sli-music-tone,
.sli-earphones-alt,
.sli-earphones,
.sli-equalizer,
.sli-like,
.sli-dislike,
.sli-control-start,
.sli-control-rewind,
.sli-control-play,
.sli-control-pause,
.sli-control-forward,
.sli-control-end,
.sli-volume-1,
.sli-volume-2,
.sli-volume-off,
.sli-calendar,
.sli-bulb,
.sli-chart,
.sli-ban,
.sli-bubble,
.sli-camrecorder,
.sli-camera,
.sli-cloud-download,
.sli-cloud-upload,
.sli-envelope,
.sli-eye,
.sli-flag,
.sli-heart,
.sli-info,
.sli-key,
.sli-link,
.sli-lock,
.sli-lock-open,
.sli-magnifier,
.sli-magnifier-add,
.sli-magnifier-remove,
.sli-paper-clip,
.sli-paper-plane,
.sli-power,
.sli-refresh,
.sli-reload,
.sli-settings,
.sli-star,
.sli-symbol-female,
.sli-symbol-male,
.sli-target,
.sli-credit-card,
.sli-paypal,
.sli-social-tumblr,
.sli-social-twitter,
.sli-social-facebook,
.sli-social-instagram,
.sli-social-linkedin,
.sli-social-pinterest,
.sli-social-github,
.sli-social-google,
.sli-social-reddit,
.sli-social-skype,
.sli-social-dribbble,
.sli-social-behance,
.sli-social-foursqare,
.sli-social-soundcloud,
.sli-social-spotify,
.sli-social-stumbleupon,
.sli-social-youtube,
.sli-social-dropbox,
.sli-social-vkontakte,
.sli-social-steam {
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* better font rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sli-user:before {
  content: "\e005";
}
.sli-people:before {
  content: "\e001";
}
.sli-user-female:before {
  content: "\e000";
}
.sli-user-follow:before {
  content: "\e002";
}
.sli-user-following:before {
  content: "\e003";
}
.sli-user-unfollow:before {
  content: "\e004";
}
.sli-login:before {
  content: "\e066";
}
.sli-logout:before {
  content: "\e065";
}
.sli-emotsmile:before {
  content: "\e021";
}
.sli-phone:before {
  content: "\e600";
}
.sli-call-end:before {
  content: "\e048";
}
.sli-call-in:before {
  content: "\e047";
}
.sli-call-out:before {
  content: "\e046";
}
.sli-map:before {
  content: "\e033";
}
.sli-location-pin:before {
  content: "\e096";
}
.sli-direction:before {
  content: "\e042";
}
.sli-directions:before {
  content: "\e041";
}
.sli-compass:before {
  content: "\e045";
}
.sli-layers:before {
  content: "\e034";
}
.sli-menu:before {
  content: "\e601";
}
.sli-list:before {
  content: "\e067";
}
.sli-options-vertical:before {
  content: "\e602";
}
.sli-options:before {
  content: "\e603";
}
.sli-arrow-down:before {
  content: "\e604";
}
.sli-arrow-left:before {
  content: "\e605";
}
.sli-arrow-right:before {
  content: "\e606";
}
.sli-arrow-up:before {
  content: "\e607";
}
.sli-arrow-up-circle:before {
  content: "\e078";
}
.sli-arrow-left-circle:before {
  content: "\e07a";
}
.sli-arrow-right-circle:before {
  content: "\e079";
}
.sli-arrow-down-circle:before {
  content: "\e07b";
}
.sli-check:before {
  content: "\e080";
}
.sli-clock:before {
  content: "\e081";
}
.sli-plus:before {
  content: "\e095";
}
.sli-minus:before {
  content: "\e615";
}
.sli-close:before {
  content: "\e082";
}
.sli-event:before {
  content: "\e619";
}
.sli-exclamation:before {
  content: "\e617";
}
.sli-organization:before {
  content: "\e616";
}
.sli-trophy:before {
  content: "\e006";
}
.sli-screen-smartphone:before {
  content: "\e010";
}
.sli-screen-desktop:before {
  content: "\e011";
}
.sli-plane:before {
  content: "\e012";
}
.sli-notebook:before {
  content: "\e013";
}
.sli-mustache:before {
  content: "\e014";
}
.sli-mouse:before {
  content: "\e015";
}
.sli-magnet:before {
  content: "\e016";
}
.sli-energy:before {
  content: "\e020";
}
.sli-disc:before {
  content: "\e022";
}
.sli-cursor:before {
  content: "\e06e";
}
.sli-cursor-move:before {
  content: "\e023";
}
.sli-crop:before {
  content: "\e024";
}
.sli-chemistry:before {
  content: "\e026";
}
.sli-speedometer:before {
  content: "\e007";
}
.sli-shield:before {
  content: "\e00e";
}
.sli-screen-tablet:before {
  content: "\e00f";
}
.sli-magic-wand:before {
  content: "\e017";
}
.sli-hourglass:before {
  content: "\e018";
}
.sli-graduation:before {
  content: "\e019";
}
.sli-ghost:before {
  content: "\e01a";
}
.sli-game-controller:before {
  content: "\e01b";
}
.sli-fire:before {
  content: "\e01c";
}
.sli-eyeglass:before {
  content: "\e01d";
}
.sli-envelope-open:before {
  content: "\e01e";
}
.sli-envelope-letter:before {
  content: "\e01f";
}
.sli-bell:before {
  content: "\e027";
}
.sli-badge:before {
  content: "\e028";
}
.sli-anchor:before {
  content: "\e029";
}
.sli-wallet:before {
  content: "\e02a";
}
.sli-vector:before {
  content: "\e02b";
}
.sli-speech:before {
  content: "\e02c";
}
.sli-puzzle:before {
  content: "\e02d";
}
.sli-printer:before {
  content: "\e02e";
}
.sli-present:before {
  content: "\e02f";
}
.sli-playlist:before {
  content: "\e030";
}
.sli-pin:before {
  content: "\e031";
}
.sli-picture:before {
  content: "\e032";
}
.sli-handbag:before {
  content: "\e035";
}
.sli-globe-alt:before {
  content: "\e036";
}
.sli-globe:before {
  content: "\e037";
}
.sli-folder-alt:before {
  content: "\e039";
}
.sli-folder:before {
  content: "\e089";
}
.sli-film:before {
  content: "\e03a";
}
.sli-feed:before {
  content: "\e03b";
}
.sli-drop:before {
  content: "\e03e";
}
.sli-drawer:before {
  content: "\e03f";
}
.sli-docs:before {
  content: "\e040";
}
.sli-doc:before {
  content: "\e085";
}
.sli-diamond:before {
  content: "\e043";
}
.sli-cup:before {
  content: "\e044";
}
.sli-calculator:before {
  content: "\e049";
}
.sli-bubbles:before {
  content: "\e04a";
}
.sli-briefcase:before {
  content: "\e04b";
}
.sli-book-open:before {
  content: "\e04c";
}
.sli-basket-loaded:before {
  content: "\e04d";
}
.sli-basket:before {
  content: "\e04e";
}
.sli-bag:before {
  content: "\e04f";
}
.sli-action-undo:before {
  content: "\e050";
}
.sli-action-redo:before {
  content: "\e051";
}
.sli-wrench:before {
  content: "\e052";
}
.sli-umbrella:before {
  content: "\e053";
}
.sli-trash:before {
  content: "\e054";
}
.sli-tag:before {
  content: "\e055";
}
.sli-support:before {
  content: "\e056";
}
.sli-frame:before {
  content: "\e038";
}
.sli-size-fullscreen:before {
  content: "\e057";
}
.sli-size-actual:before {
  content: "\e058";
}
.sli-shuffle:before {
  content: "\e059";
}
.sli-share-alt:before {
  content: "\e05a";
}
.sli-share:before {
  content: "\e05b";
}
.sli-rocket:before {
  content: "\e05c";
}
.sli-question:before {
  content: "\e05d";
}
.sli-pie-chart:before {
  content: "\e05e";
}
.sli-pencil:before {
  content: "\e05f";
}
.sli-note:before {
  content: "\e060";
}
.sli-loop:before {
  content: "\e064";
}
.sli-home:before {
  content: "\e069";
}
.sli-grid:before {
  content: "\e06a";
}
.sli-graph:before {
  content: "\e06b";
}
.sli-microphone:before {
  content: "\e063";
}
.sli-music-tone-alt:before {
  content: "\e061";
}
.sli-music-tone:before {
  content: "\e062";
}
.sli-earphones-alt:before {
  content: "\e03c";
}
.sli-earphones:before {
  content: "\e03d";
}
.sli-equalizer:before {
  content: "\e06c";
}
.sli-like:before {
  content: "\e068";
}
.sli-dislike:before {
  content: "\e06d";
}
.sli-control-start:before {
  content: "\e06f";
}
.sli-control-rewind:before {
  content: "\e070";
}
.sli-control-play:before {
  content: "\e071";
}
.sli-control-pause:before {
  content: "\e072";
}
.sli-control-forward:before {
  content: "\e073";
}
.sli-control-end:before {
  content: "\e074";
}
.sli-volume-1:before {
  content: "\e09f";
}
.sli-volume-2:before {
  content: "\e0a0";
}
.sli-volume-off:before {
  content: "\e0a1";
}
.sli-calendar:before {
  content: "\e075";
}
.sli-bulb:before {
  content: "\e076";
}
.sli-chart:before {
  content: "\e077";
}
.sli-ban:before {
  content: "\e07c";
}
.sli-bubble:before {
  content: "\e07d";
}
.sli-camrecorder:before {
  content: "\e07e";
}
.sli-camera:before {
  content: "\e07f";
}
.sli-cloud-download:before {
  content: "\e083";
}
.sli-cloud-upload:before {
  content: "\e084";
}
.sli-envelope:before {
  content: "\e086";
}
.sli-eye:before {
  content: "\e087";
}
.sli-flag:before {
  content: "\e088";
}
.sli-heart:before {
  content: "\e08a";
}
.sli-info:before {
  content: "\e08b";
}
.sli-key:before {
  content: "\e08c";
}
.sli-link:before {
  content: "\e08d";
}
.sli-lock:before {
  content: "\e08e";
}
.sli-lock-open:before {
  content: "\e08f";
}
.sli-magnifier:before {
  content: "\e090";
}
.sli-magnifier-add:before {
  content: "\e091";
}
.sli-magnifier-remove:before {
  content: "\e092";
}
.sli-paper-clip:before {
  content: "\e093";
}
.sli-paper-plane:before {
  content: "\e094";
}
.sli-power:before {
  content: "\e097";
}
.sli-refresh:before {
  content: "\e098";
}
.sli-reload:before {
  content: "\e099";
}
.sli-settings:before {
  content: "\e09a";
}
.sli-star:before {
  content: "\e09b";
}
.sli-symbol-female:before {
  content: "\e09c";
}
.sli-symbol-male:before {
  content: "\e09d";
}
.sli-target:before {
  content: "\e09e";
}
.sli-credit-card:before {
  content: "\e025";
}
.sli-paypal:before {
  content: "\e608";
}
.sli-social-tumblr:before {
  content: "\e00a";
}
.sli-social-twitter:before {
  content: "\e009";
}
.sli-social-facebook:before {
  content: "\e00b";
}
.sli-social-instagram:before {
  content: "\e609";
}
.sli-social-linkedin:before {
  content: "\e60a";
}
.sli-social-pinterest:before {
  content: "\e60b";
}
.sli-social-github:before {
  content: "\e60c";
}
.sli-social-google:before {
  content: "\e60d";
}
.sli-social-reddit:before {
  content: "\e60e";
}
.sli-social-skype:before {
  content: "\e60f";
}
.sli-social-dribbble:before {
  content: "\e00d";
}
.sli-social-behance:before {
  content: "\e610";
}
.sli-social-foursqare:before {
  content: "\e611";
}
.sli-social-soundcloud:before {
  content: "\e612";
}
.sli-social-spotify:before {
  content: "\e613";
}
.sli-social-stumbleupon:before {
  content: "\e614";
}
.sli-social-youtube:before {
  content: "\e008";
}
.sli-social-dropbox:before {
  content: "\e00c";
}
.sli-social-vkontakte:before {
  content: "\e618";
}
.sli-social-steam:before {
  content: "\e620";
}
