/*
 * Styles for video related controls
 */
@import './video_js_reset.css';
@import '../../subprojects/video.js/dist/video-js.min.css';
/*
    Styles for video iframe
 */
.video_container_landscape {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-opaque_30pct {
    opacity: 0.30;
}

.background-video-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
}
.background-video-wrapper iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.background-video-wrapper2 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
}
.background-video-wrapper2 iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Force to fill the screen, but will not */
.video-full-screen-emulation {
    width: 100%!important;
    height: 100%!important;;
    position: fixed!important;
    top: 0!important;
    left: 0!important;
    right: 0!important;
    bottom: 0!important;

    max-width: unset!important;
    max-height: unset!important;

    background: #000000;

    z-index: 2147483646!important;
}

.video-control-container-menu {
    /* for Play with a menu above the video frame */

    /* fixed size */
    /*
    width: 700px;
    height: 393px;
    */

    /* flexible size*/
    width: 90%;
    height: auto;
    margin: auto;
    max-width: 900px;
    min-width: 300px;
    transition: 200ms;
}


/*
  Source: https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
 */
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoPlayerNoIFRAME{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* Classes to help manage the position of the Vimeo player*/
.video-hide-top {
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    position: relative;
}

.video-hide-wrapper {
    position: relative;
    padding-bottom: 200%;
    transform: translateY(-35.95%);
    border-style: none;
}


/*
  For in-browser video
 */
#video-control-container {
    /*
    width: 640px;
    height: 365px;

    for Record:
      width: 400px;
    height: 300px;

    for play with controls at the bottom of the video frame
    width: 500px;
    height: 280px;

    */

    /* for Play with video controls below the video frame */
    width: 500px;
    height: 320px;
    position: relative;
}


/* this version shows the video controls over the video */
#video-control-controls-inline {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
}

#video-control-controls {
    padding-top: 4px;
    padding-bottom: 5px;

    /* Fine tune adjustment because the video player has extra whitespace at the bottom */
    margin-top: 0;

    margin-left: 0;

    opacity: .7;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -o-transition: opacity .3s;
    /*noinspection CssUnknownProperty*/
    -ms-transition: opacity .3s;
    transition: opacity .3s;
    background-image: linear-gradient(bottom, rgb(3,113,168) 13%, rgb(0,136,204) 100%);
    background-image: -o-linear-gradient(bottom, rgb(3,113,168) 13%, rgb(0,136,204) 100%);
    background-image: -moz-linear-gradient(bottom, rgb(3,113,168) 13%, rgb(0,136,204) 100%);
    background-image: -webkit-linear-gradient(bottom, rgb(3,113,168) 13%, rgb(0,136,204) 100%);
    background-image: -ms-linear-gradient(bottom, rgb(3,113,168) 13%, rgb(0,136,204) 100%);

    background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            color-stop(0.13, rgb(3,113,168)),
            color-stop(1, rgb(0,136,204))
    );
}

#video-control-container:hover #video-control-controls {
    opacity: .9;
}

.btn-video-menu-no-ie {
    color: black!important;
    background: lightgray!important;

}


#player-seek-bar {
    display: inline-block;
    width: 180px;
}

#volume-bar {
    width: 60px;
}

.video-seek-bar {
    display: inline-block;
    width: 50%;
    opacity: 0.5;
}
.video-seek-bar:hover {
    opacity: 0.9;
}

.video-time-text {
    color: white;
    display:inline-block;
    font-size: 10px;

}
.video-text-frame {
    background-color: slategray;
    border-style: none;
    padding-left: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 3px;
    opacity: 0.8;
    border-radius: 0.24em;
}

.video_control_bar_text {
    color: white;
    display:inline-block;
    font-size: 12px;

}

/**
 * Overlay -- simplified video buttons
 */
.video-overlay {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px calc(50% - 50px);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    cursor: pointer;
    transition: opacity 300ms;
}

.video-overlay-opaque-hover {
    background-image: linear-gradient(transparent, #000);
    opacity: 0.95;

}

/* The menu button at the top of the video */
.video-menu-button,
.video-js .video-menu-button {
    background-color: var(--ph-player-button-background-inactive)!important;
    border-style: none!important;;
    color: lightgray!important;;
    padding-left: 3px!important;;
    padding-top: 3px!important;;
    padding-bottom: 3px!important;;
    padding-right: 3px!important;;
    opacity: 0.8!important;;
    border-radius: 0.24em!important;;
}
.video-js .video-menu-button:focus:not(:focus-visible) {
    background-color: slategray;
}
.video-menu-button-right {
    float: right;
}

/* Main video menu*/
.video-main-menu-button-icon {
    font-size: 21px;
}

/* Other buttons in video menu */
.video-menu-button-icon {
    font-size: 25px;
}
.video-menu-button-text {
    float: left;
    font-size: 12px;
    padding-top: 2px;
    padding-right: 3px;
    padding-left: 3px;
}

.video-menu-button:hover {
    color: white;
    outline-color:transparent;
    background-color: var(--ph-player-button-background-active);
    opacity: 0.8;
}

.video-menu-button:focus{
    outline-color: transparent;
    opacity: 0.8;
}


.video-overlay-button {
    transition: opacity 300ms;
    color: #ffffff;
    opacity: 0.6;
}

.video-overlay-button-inner {
    transition: opacity 300ms;
    color: #ffffff;
}

/* the contain or buttons on the bottom of the video layout */
.video-overlay-bottom-button-container {
    height: 40px;
}

/* the contain or buttons on the bottom of the video layout */
.video-overlay-bottom-button {
    height: 92%;
    padding-top: 7px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    opacity: 0.8;
    transition: opacity 300ms;
    background-color: var(--ph-player-button-background-inactive);
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 0;
    margin-right: 0;
    outline-color: transparent;
    border-style:none;
    border-color: var(--ph-player-button-background-active);
    color: #ffffff;
    display: inline-block;
    text-align: center;


}

.video-overlay-bottom-button.auto_hide{
    opacity: 0.0;
}

.video-overlay-bottom-button.auto_hide:hover {
    opacity: 0.0;
}

.video-overlay-bottom-button.hover-emulation {
    opacity: 0.8!important;
}

.video-overlay-bottom-button:hover,
.video-overlay-bottom-button.hover-emulation
{
    opacity: 0.8;
    background-color: var(--ph-player-button-background-active);
    outline-color: transparent;
}

.video-overlay-bottom-button:focus {
    opacity: 0.8;
    outline-color: transparent;
}

.video-overlay-button.auto_hide{
    opacity: 0.0;
}

.video-overlay-button.is-hidden {
    display: none;
    visibility: hidden;
}

/* needs to be last in this section */
.video-overlay-opaque-hover:hover {
    opacity: 0.25;
}

/* needs to be last in this section */
.video-overlay-button:hover {
    opacity: 0.90;
}

/*
  Grid for video controls overlay

  Note:
  - text-align: left is important for keeping buttons aligned to the left side of their DIV
*/
.video-overlay-control-grid {
    display: grid;
    grid-template-columns: [left]40px [left-inside] 1fr [center-left] max(70px, 10vmin) [center-right] 1fr [right-inside] 40px [right];
    grid-template-rows: [top] 40px [top-inside] 1fr [center-top] max(70px, 10vmin) [center-bottom] 1fr [bottom-inside] 40px [bottom] ;
    text-align: left;
}

.video-overlay-control-grid-top-center {
    grid-column-start: left; grid-column-end: right; grid-row-start: top; grid-row-end: top;
}
.video-overlay-control-grid-left {
    grid-column-start: left; grid-column-end: left-inside; grid-row-start: center-top; grid-row-end: center-bottom;
}

.video-overlay-control-grid-center-left {
    grid-column-start: left-inside; grid-column-end: center-left; grid-row-start: center-top; grid-row-end: center-bottom;
}

.video-overlay-control-grid-center {
    grid-column-start: center-left; grid-column-end: center-right; grid-row-start: center-top; grid-row-end: center-bottom;
}

.video-overlay-control-grid-center-right {
    grid-column-start: center-right; grid-column-end: right-inside; grid-row-start: center-top; grid-row-end: center-bottom;
}

.video-overlay-control-grid-right {
    grid-column-start: right-inside; grid-column-end: right; grid-row-start: center-top; grid-row-end: center-bottom;
}
.video-overlay-control-grid-bottom {
    grid-column-start: left; grid-column-end: right; grid-row-start: bottom-inside; grid-row-end: bottom;
}

/* Fast jog */
.video-fast-jog-panel {
    background: var(--ph-player-button-background-inactive);
    opacity: 0.5;
    border-radius: 4px;

}

.video-fast-jog-status {
    font-size: 11px;
    color: var(--ph-player-button-foreground);
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.video-fast-jog-status-right {
    float:right;
}

.video-fast-jog-status-left {
    float:left;
}

.video-fast-jog-status-right-time-remaining {
    float:right;
}


.video-fast-jog-status.is-hidden {
    display: none;
}

/*
  Grid for centering horizontally and vertically
*/
.hv-center-grid {
    width: 100%;
    height: 10vmin; /* needs to match the vertical-center of video-overlay-control-grid */
    display: grid;
    grid-template-columns: [left]1fr [center-left] auto [center-right] 1fr [right];
    grid-template-rows: [top] 1fr [center-top] auto [center-bottom] 1fr [bottom] ;
}

.hv-center-item {
    grid-column-start: center-left; grid-column-end: center-right; grid-row-start: center-top; grid-row-end: center-bottom;
}

/*
  Scaling SVG
  Source: https://css-tricks.com/scale-svg/
  Instruction: change the style on the element mapped to 'scaling-svg-container',
               by setting 'padding-bottom: #%;' to a value under 100 to scale-down the SVG
 */
.scaling-svg-container {
    position: relative;
    height: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 100%;
    /* override this inline for aspect ratio other than square */
}

.scaling-svg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

/* HTML embed code */
.html-embed-code-container {
    display: none;
    border: 1px;
    border-color: black;
    border-style: solid;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
}

.html-embed-code-text {
    /*noinspection CssNoGenericFontName*/
    font-family: "Courier New";
    font-size: smaller;
    word-wrap: break-word;
}

/*
    Video.JS overrides
*/
.video-js .vjs-big-play-button {
    background-color: var(--ph-player-controls-accent);
    opacity: 0.8;
    transition: all 0.1s;
    color: white;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
    background-color: var(--ph-player-controls-accent);
    opacity: 1.0;
    color: white;
}

.video-js button,
.video-js .vjs-remaining-time,
.video-js .vjs-volume-panel .vjs-volume-control
{
    color: var(--ph-player-controls-accent);
}

.video-js button {
    background-image: initial!important;
    background-size: initial!important;;
    background-color: initial!important;
}

/* Progress bar, play-progress*/
.video-js .vjs-progress-holder .vjs-play-progress
{
    color: var(--ph-player-controls-accent);
    background-color: var(--ph-player-controls-base);
}

/* Height of the progress-bar */
.video-js .vjs-progress-holder {
    height: 15px;
}

/* Height of the progress-bar */
.video-js .vjs-progress-holder:hover {
    height: 20px;
}

/* Volume panel background */
.video-js .vjs-volume-panel .vjs-volume-control {
    background: none;
}


/* Volume slider background */
.video-js .vjs-volume-level {
    background-color: var(--ph-player-controls-base);
}

/* Waiting spinner */
.vjs-seeking .vjs-loading-spinner:before,
.vjs-waiting .vjs-loading-spinner:before {
    border-top-color: var(--ph-player-controls-accent);
}

.vjs-seeking .vjs-loading-spinner:after,
.vjs-waiting .vjs-loading-spinner:after {
    border-top-color: var(--ph-player-controls-accent);
}


@keyframes vjs-spinner-fade {
    0% {
        border-top-color: var(--ph-player-controls-accent); }
    20% {
        border-top-color: var(--ph-player-controls-accent); }
    35% {
        border-top-color: white; }
    60% {
        border-top-color: var(--ph-player-controls-accent); }
    100% {
        border-top-color: var(--ph-player-controls-accent); } }

@-webkit-keyframes vjs-spinner-fade {
    0% {
        border-top-color: var(--ph-player-controls-accent); }
    20% {
        border-top-color: var(--ph-player-controls-accent); }
    35% {
        border-top-color: var(--ph-player-controls-accent); }
    60% {
        border-top-color: var(--ph-player-controls-accent); }
    100% {
        border-top-color: var(--ph-player-controls-accent); } }

/* Disable focused-control shadow (kept hover) */
.video-js .vjs-control:focus:before,
.video-js .vjs-control:focus {
    text-shadow: none;
}

/* Disable closing the volume panel */
.video-js .vjs-volume-panel {
    transition: width 1s; }
.video-js .vjs-volume-panel .vjs-volume-control,
.video-js .vjs-volume-panel .vjs-volume-control,
.video-js .vjs-volume-panel .vjs-volume-control,
.video-js .vjs-volume-panel .vjs-volume-control:hover,
.video-js .vjs-volume-panel .vjs-volume-control:active,
.video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active {
    visibility: visible;
    opacity: 1;
    position: relative;
    transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s; }
.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal,
.video-js .vjs-volume-panel .vjs-volume-control:hover.vjs-volume-horizontal,
.video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal,
.video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-horizontal,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal {
    width: 5em;
    height: 3em; }
.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical,
.video-js .vjs-volume-panel .vjs-volume-control:hover.vjs-volume-vertical,
.video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-vertical,
.video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-vertical,
.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-vertical {
    left: -3.5em; }
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal,
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal,
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active {
    width: 9em;
    transition: width 0.1s; }

/* Disable the Big play button*/
.video-js .vjs-big-play-button {
    display: none;
}

.video-js .vjs-control-bar {
    /* all: initial; */
    height: 30px;
    padding-top: 2px;
}

/* Control bar transition speed */
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    visibility: visible;
    opacity: 0;
    transition: visibility 0.25s, opacity 0.25s;
}

/* Full screen mode */
@media (orientation: landscape) {
    .video-js.vjs-fullscreen {
        /* Moved rules to ph-fullscreen-width-important and ph-fullscreen-height-important*/
    }}

.ph-fullscreen-width-limit {
    /* Make sure video takes up the width of the screen */
    width: 100vw !important;
}

.video-js.ph-fullscreen-height-limit {

    /* Makes sure the controls will show at the bottom of the screen*/
    height: calc(100% - 56px)!important;
}

/**
    Powered By PitchHub overlay
*/
.video-overlay-powered-by-pitchhub-outer-container {
    background: none;
    opacity: 1.0;
    height: 55px;
    width: 160px;
    padding: 0;
    position: absolute;
    bottom: 30px;
    right: 0;
}
.video-overlay-powered-by-pitchhub-outer-container.auto_hide{
    opacity: 0.0;
}

.video-overlay-powered-by-pitchhub-background-panel {
    background: #eee;
    opacity: 0.9;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
}

.video-overlay-powered-by-pitchhub-text {
    color: var(--ph-gray);
    position: absolute;
    top: 8px;
    right: 47px;
    font-size: 10px;
}

.video-overlay-powered-by-pitchhub-image {
    opacity: 1.0;
    width: 140px;
    padding-left: 0;
    margin: 2px 5px;
    position: absolute;
    left: 0;
    bottom: 0;
}

/**
    Powered By PitchHub - inline
*/
.video-overlay-powered-by-pitchhub-outer-container-inline {
    background: none;
    opacity: 1.0;
    height: 55px;
    width: 160px;
    float: right;
    position: relative;
}

/* "Powered By" overrides for more-narrow screens */
@media only screen and (max-width: 700px) {
    .video-overlay-powered-by-pitchhub-outer-container {
        height: 33px;
        width: 100px;
    }

    .video-overlay-powered-by-pitchhub-background-panel {
    }

    .video-overlay-powered-by-pitchhub-text {
        top: 1px;
        right: 10px;
    }

    .video-overlay-powered-by-pitchhub-image {
        width: 85px;
        margin: 0px 5px;
    }

    .video-overlay-powered-by-pitchhub-outer-container-inline {
        height: 33px;
        width: 100px;
    }
}

.vjs-icon-placeholder {
    font-family: VideoJS!important;
    font-weight: 400!important;
    font-style: normal!important;
}