@import 'app_include_icons.css';
@import 'https://fonts.googleapis.com/css?family=Libre+Franklin:400,400i,600,600i';
@import 'tether/tether.min.css';


/* -- bootstrap and animate are overwritten by the new frontend_2_0
*/
@import 'bootstrap/css/bootstrap.min.css';
@import 'app_include_external_req.css';

@import 'bootstrap-table/bootstrap-table.css';

/* 2018.07.30: There are problems with this file rendering OK in the new frontend_2_0*/
@import 'app_include_formatting.css';

