/* setting the value of this variable to an empty data URL is the only working solution
   to load the Bootswatch web fonts locally and avoid loading them from Google servers
   see https://github.com/thomaspark/bootswatch/issues/55#issuecomment-298093182

$web-font-path: 'data:text/css;base64,';

@import "~bootswatch/flatly/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~bootswatch/flatly/bootswatch";
@import "~font-awesome/scss/font-awesome.scss";
@import "~highlight.js/styles/solarized-light.css";

2018.06.11,bn: disabled these items above
*/

/* pick the Lato fonts individually to avoid importing the entire font family

@import '~lato-font/scss/public-api';
@include lato-include-font('normal');
@include lato-include-font('bold');
2018.06.11,bn: disabled these items above
*/

/* Basic styles
   ------------------------------------------------------------------------- */
p, ul, ol {
  /*font-size: 19px; */
    font-size: .9rem;
  margin-bottom: 0.5em
}

li {
  margin-bottom: 0.5em
}

code {
  background: #ecf0f1;
  color: #2c3e50
}

.text-danger, .text-danger:hover {
  color: #e74c3c
}

i {
  margin-right: 0.25em
}

.table.table-middle-aligned th,
.table.table-middle-aligned td {
  vertical-align: middle;
}

/* Utilities
   ------------------------------------------------------------------------- */
.m-b-0 { margin-bottom: 0 }

/* Page elements
   ------------------------------------------------------------------------- */
body {
  flex-direction: column;
  min-height: 100vh
}

header {
  margin-bottom: 2em
}

header ul.nav li {
  margin-bottom: 0
}

header .locales a {
  padding-right: 10px
}

.body-container {
  flex: 1;
  /* needed to prevent pages with a very small height and browsers not supporting flex */
  min-height: 600px
}

.body-container #main h1, .body-container #main h2 {
  margin-top: 0
}

#sidebar .section {
  margin-bottom: 2em
}

#sidebar p {
  font-size: 15px
}

#sidebar p + p {
  margin: 1.5em 0 0
}

footer {
  background: #ecf0f1;
  margin-top: 2em;
  padding-top: 2em;
  padding-bottom: 2em
}

footer p {
  color: #7b8a8b;
  font-size: 13px;
  margin-bottom: 0.25em
}

footer #footer-resources {
  text-align: right
}

footer #footer-resources i {
  color: #7b8a8b;
  font-size: 28.5px;
  margin-left: 0.5em
}

#sourceCodeModal h3 {
  font-size: 19px;
  margin-top: 0
}

#sourceCodeModal h3 small {
  color: #7b8a8b;
  font-size: 80%
}

#sourceCodeModal pre {
  margin-bottom: 2em;
  padding: 0
}

#confirmationModal .modal-dialog {
  width: 500px
}

#confirmationModal .modal-footer button {
  min-width: 75px
}

/* Misc. elements
   ------------------------------------------------------------------------- */
.section.rss a {
  color: #f39c12;
  font-size: 21px;
}

/* Forms
   ------------------------------------------------------------------------- */
.form-group.has-error .form-control {
  border-color: #e74c3c
}

.form-control:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}

.form-group.has-error .control-label {
  color: #e74c3c
}

.form-group.has-error .help-block {
  background-color: #e74c3c;
  color: #fff;
  font-size: 15px;
  padding: 1em
}

.form-group.has-error .help-block ul,
.form-group.has-error .help-block li {
  margin-bottom: 0
}
.form-group.has-error .help-block li + li {
  margin-top: 0.5em;
}

textarea {
  max-width: 100%
}

/* Control the style of the 'read-only' form fields, so they do not look like a form */
.form-control[readonly] {
  background-color: transparent;
  border-color: #e0e0e0;
  font-weight: bold;

  /* disable focus based on mouse events */
  pointer-events: none;
  /* tabindex and tab-index do not seem to work */
  tabindex: -1;
  tab-index: -1;
  user-select: none;
}

.form-control:focus[readonly] {
  border-color: revert;
  outline: 0;
  outline: revert;
  box-shadow: none;
}

.form_control_nondefault_alert {
  background: #D3F0FB;
}

.form_heading
{
  display: block;
  -webkit-margin-before: .13em;
  -webkit-margin-after: .33em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.1;
  color: inherit;
  font-family: 'Oxygen', sans-serif;
}

.subform_heading
{
  padding-bottom: 10px;
  -webkit-margin-before: 0.0em;
  -webkit-margin-after: 0.0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.1;
  color: inherit;
  font-family: 'Oxygen', sans-serif;
  width: auto;
}

.sub_subform_heading
{
  padding-bottom: 10px;
  -webkit-margin-before: 0.0em;
  -webkit-margin-after: 0.0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.1;
  color: inherit;
  font-family: 'Oxygen', sans-serif;
  width: auto;
}

/* adjustments to the form template ( bootstrap_4_horizontal_layout.html.twig) */
.form-control-label {

  /* 2018.03.08,bn: the labels are sometimes hitting the controls, currently set for 16.67%, changed to:
      fit-content: some are above, some are not
      revert: same problem
      auto: some are above, some are not
      available: same problem
      content-box: same problem
      max-content: same as auto

      width: 16.66667%; - original
      width: 25.0%; - new row for label
  */
  width:30.0%;
  max-width: 100px;

  /*
      Without this, labels get pushed left-and-up
  */
  padding-left: .9375rem;
  padding-top: .01rem;

}

.col-sm-10 {
  width: 70%;
}

/*
* for forms that can display the labels in a wide format
 */
.form-control-label-wide {
  width: initial;
  max-width: initial;
  min-width: 300px;
}



/* Styles added by Ben
   ------------------------------------------------------------------------- */


/* applies to any object (e.g. <p>, <div>*/
.error {
  color: #FF0000;
}

/* applies to any object (e.g. <p>, <div>*/
.warning {
  color: #faf2cc;
}

/* Error DIV - red light background with red text & black border */
div.error {
  border-radius: 5px;
  color: #e03b30;
  background-color: #f4dfde;
  border-style: solid;
  border-color: #e03b30;
  border-width: 1px;
  width:auto;
  padding: 2px;
  display: inline-block; /* this forces the width to be based on the content*/

  font-size: 11px;
}

/* warning DIV - gray light background with red text & black border */
div.warning {
  border-radius: 5px;
  color: #000000;
  background: #CCCCCC;
  border-style: solid;
  border-color: #000000;
  border-width: 1px;
  width:auto;
  padding: 2px;
  display: inline-block; /* this forces the width to be based on the content*/
}

/* Info DIV - blue light background with blue text & black border */
div.info {
  border-radius: 5px;
  color: #3035e0;
  background-color: #c1e2f4;
  border-style: solid;
  border-color: #3035e0;
  border-width: 1px;
  width:auto;
  padding: 2px;
  display: inline-block; /* this forces the width to be based on the content*/
  font-size: 11px;
  line-height: initial;
}

div.info_alert {
  display: inline-block;
  padding-left: 4px;
}

.info_text {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  padding-bottom: 3px;
  line-height: initial;
}

/* Override for form text boxes  -- less-tall and less-rounded */
.form-control {
  min-height: 2.0em;
  border-radius: 0.5em;
  border-color: #c0c0c0;
  font-size: 1.0em;

  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

/* Background for forms */
div.ph_form_edit {
  vertical-align: top;
  border-radius: 5px;
  background: var(--ph-form-edit-background);
  border-style: solid;
  border-color: var(--ph-table-border);
  border-width: 1px;
  margin: 10px;
  padding: 5px;
  display: inline-block;
}

/* Background for forms */
div.ph_form_white {
  background: white;
}

/* Background for forms */
div.ph_form_show {
  vertical-align: top;
  border-radius: 5px;
  background: var(--ph-form-show-background);
  border-style: solid;
  border-color: var(--ph-table-border);
  border-width: 1px;
  margin: 10px;
  padding: 5px;
  display: inline-block;
}

div.ph_form_width_wide {
  min-width: 600px;
  width: 100%;
  max-width: available;
}

div.ph_form_width_med {
  min-width: 425px;
  width: 50%;
  max-width: available;
}

div.ph_form_width_small {
  min-width: 300px;
  width: 20%;
  max-width: available;
}

div.ph_form_width_auto {
  min-width: 600px;
  width: 90%;
  max-width: available;
}

.btn-primary {
  background-color: var(--ph-blue);
  border-color: var(--ph-blue);
}


/* button for non-autogenerated forms */
button.ph_form {
  margin:8px;
  border-radius: 5px;
  background-color: var(--ph-blue);
  color: #FFFFFF;
}

/* auto generated save button for Forms */
.btn-secondary {
  background-color: var(--ph-blue);
  border-color: var(--ph-blue);
  color: #ffffff;
  margin: 8px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 13px;
  margin: 2px;
  text-indent: 0px;
}
.btn-secondary:hover {
  color: #fff;
  background-color: var(--ph-green);
  border-color: var(--ph-green);
  box-shadow: none; }

.btn-secondary:focus {
  color: #fff;
  background-color: var(--ph-green);
  border-color: var(--ph-green);
}
.btn-secondary:active {
  color: #fff;
  background-color: var(--ph-green);
  border-color: var(--ph-green);
  background-image: none;
  box-shadow: none;

}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff !important;
  background-color: #94ada8 !important;
  border-color: #94ada8 !important;
  opacity: 0.85; }

.btn-small {
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 13px;
  margin: 2px;
  text-indent: 0px;
}

.btn-narrow {
  padding-left: 5px;
  padding-right: 5px;
}

/* auto generated save button for Forms */
.btn-cancel {
  background-color: var(--ph-gray);
  border-color: var(--ph-gray);
  color: #ffffff;
  margin: 8px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 13px;
  margin: 2px;
  text-indent: 0px;
}

.btn-cancel:hover {
  background-color: darkgray;
}
.btn-cancel:focus {
  background-color: darkgray;
}
.btn-cancel:active {
  background-color: darkgray;
}


.btn-small {
  margin: 1px;
  padding-left: 8px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;

}

/* Top-menu navigation button -- make it match the PitchHub blue*/
.menu1 .nav-btn .btn {
  text-transform: initial;
  background-color: #0A96B8;
}

#menu1-0 .link {
  font-size: 0.90rem;
}

menu1-0 .dropdown-item,
#menu1-0 .nav-dropdown-sm .link {
  font-size: 0.900rem;
}

#menu1-0 .navbar-toggler {
  color: #7493a7;
}
#menu1-0 .close-icon::before,
#menu1-0 .close-icon::after {
  background-color: #7493a7;
}
#menu1-0 .link,
#menu1-0 .dropdown-item {
  color: #7493a7;
  font-family: 'Libre Franklin', serif;
  font-weight: normal;
}
/*
    the following are for using <div> instead of <table>
*/
div.phTable {
  display: table;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  padding: 0px;
  border-color: var(--ph-table-border);
  background: #fefefe;

}

div.phTableRow {
  display: table-row;
  padding: 2px;
}

div.phTableHeadGroup {
  display: table-header-group;
}

div.phTableRow {
  display: table-row;
}


div.phTableHeading {
  display: table-cell;
  font-weight: bold;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  background: var(--ph-blue); /* this matches the PitchHub blue */
  color: #ffffff;
  border-bottom-style: solid;
  border-bottom-color: var(--ph-table-border); /* needs to match ph_table*/
  border-width: 1px;
}

div.phTableBodyGroup {
  display: table-row-group;
}

div.phTableRow_even {
  display: table-row;
  padding: 2px;
  /*background: #ffffff;*/
}

div.phTableRow_odd {
  display: table-row;
  padding: 2px;
  /*background: #ffffff;*/
}

div.phTableRow_selectable:hover {
  background: #f0f0f0;
}

.phTableRow_selected {
  background: #F0F0F8;
  border-color: darkblue;
  border-width: 2px;
}

div.phTableCell {
  display: table-cell;
  padding: 3px 10px;

  border-top-style: dotted;
  border-top-color: #e0e0e0; /* needs to match ph_table*/
  border-top-width: 1px;

}

div.phTableCell-narrow {
  padding: 3px 3px;
}


/*
    the following are for Flash messages
*/
div.FlashMessageArea
{
  background-color: #ffe8a9;
  padding: 10px;
  display: inline-block;
  color: black;
  border-style: solid;
  border-width: 2px;
  border-color: #df8500;
  border-radius: 5px;
}
div.phFlashMessageTable{
  display: table;
  border-style: solid;
  border-width: 1px;
  padding: 0px;
  border-color: var(--ph-table-border);
  background: #ffa050;
}

div.phFlashMessageRow {
  display: table-row;
  padding: 2px;

}

div.phFlashMessageHeaderGroup {
  display: table-header-group;
}

div.phFlashMessageHeading {
  display: table-cell;
  font-weight: bold;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  background: white;
  color: orange;
  border-bottom-style: solid;
  border-bottom-color: var(--ph-table-border); /* needs to match ph_table*/
  border-width: 1px;
}

div.phFlashMessageBodyGroup {
  display: table-row-group;
}

div.phFlashMessageRow_even {
  display: table-row;
  padding: 2px;
  background: #ffc500;
}

div.phFlashMessageRow_odd {
  display: table-row;
  padding: 2px;
  background: white;
}

div.phFlashMessageCell {
  display: table-cell;
  padding: 3px 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: #000;
}


/*
    the following are for Messages
*/
div.MessageArea
{
  background-color: white;
  padding: 5px;
  margin-left: 15px;
  display: inline-block;
  color: black;
  border-style: solid;
  border-width: 1px;
  border-color: #0085df;
  border-radius: 5px;
}
div.phMessageTable{
  display: table;
  border-style: solid;
  border-width: 1px;
  padding: 0px;
  border-color: var(--ph-table-border);
  background: #50a0ff;
}

div.phMessageRow {
  display: table-row;
  padding: 2px;

}

div.phMessageHeaderGroup {
  display: table-header-group;
}

div.phMessageHeading {
  display: table-cell;
  font-weight: bold;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  background: white;
  color: black;
  border-bottom-style: solid;
  border-bottom-color: var(--ph-table-border); /* needs to match ph_table*/
  border-width: 1px;
}

div.phMessageBodyGroup {
  display: table-row-group;
}

div.phMessageRow_even {
  display: table-row;
  padding: 2px;
  background: #00c5ff;
}

div.phMessageRow_odd {
  display: table-row;
  padding: 2px;
  background: white;
}

div.phMessageCell {
  display: table-cell;
  padding: 3px 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: #000;
}

/*
    the selected option styling is apparently not consistent across browsers:
            https://stackoverflow.com/questions/7208786/how-to-style-the-option-of-a-html-select/7208814#7208814
*/
/*
option[selected="selected"] {
    background-color: blue;
    border-color: red;
    color: green;
    border-width: thin;
    border-color: black;
}
*/

textarea
{
  resize: vertical;
  width: 100%;
  max-height: 500px
}

select
{
  width: max-content;
  max-width: 300px;
}
/*
    Colors for icons
*/
.icon-color-white
{
  color: rgb(255, 255, 255);
}
.icon-color-top-menu
{
  color: #74A3C7;
}

/*
    Legend is used for the date/time control
 */
legend {
  font-size: 14px;
}

/*
    Styles for the Article Timeline
 */

/* New article*/
div.article {
  vertical-align: top;
  border-radius: 5px;
  border-style: solid;
  border-color: var(--ph-table-border);
  border-width: 1px;
  margin: 2px;
  padding: 5px;
  display: block;
}

/* Needs to be below any other 'article' style that sets border */
div.article_selected {
  background: #daeded;
  border-width: 2px;
  border-color: #00acd6;
}

div.article_new {
  background: #ffffff;
}

div.article_private {
  background: #ffffff;
  margin: 2px;
}

div.article_protected {
  background: #d0d0d0;
}

div.article_inherit {
  margin: 2px;
}


div.article_table {
  display: table;
  width:100%;

  /* Needed for borders to work on rows */
  border-collapse: collapse;
}

div.dotted_bottom {
  border-bottom-style: dotted;
  border-bottom-color: #d5d5d5;
}

div.solid_bottom {
  border-bottom-style: solid;
  border-bottom-color: #d5d5d5;
  border-bottom-width: 0.5px;

  border-top-style: solid;
  border-top-color: #d5d5d5;
  border-top-width: 0.5px;
}

div.article_row_group{
  display: table-row-group;
}

div.article_row{
  display: table-row;
}

div.article_cell {
  display: table-cell;
}


div.article_heading {
  -webkit-box-direction: normal;

}

div.article_heading_name {
  font-size:13px;
  font-weight: 700;
  /*font-family: Helvetica, Segoe UI, Arial, sans-serif;*/
  color: #666;
}

.article_heading_timestamp {
  font-size:11px;
  font-weight: 700;
  color: #666;
}

.article_heading_organization {
  font-size:11px;
  color: #999;
}

.article_heading_action_open {
  font-size:11px;
  color: #d33724;
  font-weight: bold;
}

.article_heading_action_closed {
  font-size:11px;
  color: green;
}

.article_heading_action_none {
  font-size:11px;
  color: #999;
}

.article_heading_age {
  font-size:11px;
  color: #999;
  text-align: right;
}

.article_heading_visibility {
  font-size:11px;
  color: #999;
  text-align: center;
}

.article_heading_inline {
  display:inline-block;
  padding-left: 2px;
  padding-right: 10px;
}

.article_message {
  font-size:14px;
  color: black;
  margin-bottom: 4px;
}

.article_actions {
  font-size:13px;
  color: black;
  margin-bottom: 4px;
}

.article_actions_inline {
  display:inline-block;
  padding-left: 2px;
  padding-right: 10px;
}

/*
    Actions
 */
.actions {
  font-size:13px;
  color: black;
  margin-bottom: 4px;
}


/*
    Styles for a compact, inline dialog
 */
.form-compact-heading {
  padding-bottom: 0px;
  -webkit-margin-before: 0.0em;
  -webkit-margin-after: 0.0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.1;
  color: inherit;
  font-family: 'Oxygen', sans-serif;
  width: auto;
  margin-bottom: 5px;
}

div.form-compact-row {
  margin-bottom: 5px;
  line-height: 20px;
  font-size: 13px;
}

.form-compact-button  {
  font-size: 13px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/*
        Tabs
 */
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  border-radius: 5px
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 3px 10px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #bbc;
}

/*
    Non-edited field list
 */
.fieldListHeading {
  font-size: 11px;
  color: #999;
  margin-top: 5px;
  margin-bottom: 0px;
}

.fieldListContents {
  font-size: 14px;
  color: #555;
  padding-left: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/*
     A generic, no-format table, with no headers
 */
.phClearTable{
  display: table;
  border-style: none;
  padding-left: 10px;
}


div.phClearTableRowGroup {
  display: table-row-group;
}

div.phClearTableRow {
  display: table-row;
  padding: 1px;

}

div.phClearTableCell {
  display: table-cell;
  padding: 1px 10px;
  float: left
}

div.phClearGoup{
  vertical-align: top;
  border-style: solid;
  border-color: black;
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

/*
    Summary text
 */
.summaryText {
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px;
  font-family: Arial; /* Remove this, just testing */
  vertical-align: middle;
}

/*
    Full size download division
 */
div.downloadButton {
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  display: inline-block;
}

div.downloadButton:hover {
  background: #ccc;
}

a.URLNoUnderline:hover {
  text-underline-style: none;
  text-decoration: none;
  color: orange;

}
a.URLNoUnderline:focus {
  text-underline-style: none;
  text-decoration: none;
}a.URLNoUnderline:active {
   text-underline-style: none;
   text-decoration: none;
 }

a.URLNoUnderlineWarning:hover {
  text-underline-style: none;
  text-decoration: none;
  color: red;
}
a.URLNoUnderlineWarning:focus {
  text-underline-style: none;
  text-decoration: none;
}a.URLNoUnderlineWarning:active {
   text-underline-style: none;
   text-decoration: none;
 }

.RTF {
  font-family: 'Libre Franklin', serif;
}

body {
  font-family: 'Arial';

}

/*
   Sidenav
 */

/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

/*
  Columns
 */

/* Row for columns that will float left */
.column_row:after {
  content: "";
  display: table;
  clear: both;
}


/* smaller column */
.column_small {
  float: left;
  min-width: 200px;
  max-width: 300px;
  margin-right: 7px;
  margin-left: 7px;
}

/* Larger column */
.column_large {
  float: left;
  padding:0px;
  min-width: 750px;
  margin-right: 7px;
  margin-left: 7px;
}

.column_max_width_med {
  max-width: 500px;
}

/*
    Inline block
 */
.inline_block {
  display: inline-block;
}

/*
    One-off formatting
 */
.italics {
  font-style: italic;
}

/*
    display: none
    for use with things that are default-off
 */
.display_none {
  display: none;
}

.single_space {
  line-height: 1.0em;
}

.padding_small {
  padding: 2px;
}

.margin_small {
  margin: 2px;
}

.background_none {
  background:none;
}

.background_light_gray {
  background-color: #eee;
}

/*
    Bootstrap-table overrides
 */
.bootstrap-table .table:not(.table-condensed),
.bootstrap-table .table:not(.table-condensed) > tbody > tr > th,
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > th,
.bootstrap-table .table:not(.table-condensed) > thead > tr > td,
.bootstrap-table .table:not(.table-condensed) > tbody > tr > td,
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > td {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

/* the same color with .active */
.fixed-table-container tbody .selected td {
  background-color: rgba(82, 168, 236, 0.8);
}

.active_table_background {
  background: #f5f5f5;
}

/* the same color with .active */
.fixed-table-container tbody .selected td {
  background-color: #db8b0b;
}

.fixed-table-container thead th {
  background-color:  var(--ph-blue);
  color: white;
}

.bootstrap-table .table {
  border-radius: 5px;
}


/*
* Used to hide columns in a table, specifically when using the bootstrap-table
 */
.hidden_column {
  display:none;
  visibility:hidden;
}


.center {
  text-align: center;
  width: 100%;
}

/*
* Icon status
*/
.ok {
  color: green;
}

.not_ok {
  color: red;
}

.info_ok {
  color: blue;
}

/*
 * Borders
 */
.black-border {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  padding: 0px;
}


/*
 * Requirements
 */

button.requirement  {
  text-align: center;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1px 1px;
  transition: 0.3s;
  width: 100%;
}

/* Change background color of buttons on hover */
button.requirement:hover {
  background-color: #cccccc;
}

/* Create an active/current requirement class */
button.requirement:active {
  background-color: green;
}

/* Create an active/current requirement class */
button.requirement:focus {
  background-color: #aaaaaa;
}

/*
    Headings
 */

h1 {
  font-size: 26px;
}

h2 {
  font-size: 22px;
}
h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 15px;
}

/**
    Progress
 */

#progress p
{
  display: block;
  width: 240px;
  height: 30px;
  padding: 2px 5px;
  margin: 2px 0;
  border: 1px inset #446;
  border-radius: 5px;
  background: #eee url("progress.png") 100% 0 repeat-y;
}

#progress p.success
{
  background: #0c0 none 0 0 no-repeat;
}

#progress p.failed
{
  background: #c00 none 0 0 no-repeat;
}

/**
    File drop
 */
#filedrag
{
  font-weight: bold;
  text-align: center;
  padding: 1em 0;
  margin: 1em 0;
  color: green;
  border: 2px dashed #555;
  border-radius: 7px;
  cursor: default;
}

#filedrag.hover
{
  color: blue;
  border-color: green;
  border-style: solid;
  box-shadow: inset 0 3px 4px #888;
}

/*
    Video status
 */

#video_status_description_div p
{
  font-size: 4em;
  margin: 5px;
  line-height: 1em;
}

#video_status_description_div p.uploading
{
  color: orange;
}

#video_status_description_div p.transcoding
{
  color: orange;
}

#video_status_description_div p.available
{
  color: green;
}

#video_sub_status_div p
{
  font-size: 1em;
  margin: 5px;
  color: grey;
  padding: 0px;
}

/*
  Added for new front end
 */
.pricing-flex{
  max-width: 300px;

}

/**
  Detailed comments
 */
.detailed-comments-text {
  display:inline-block;
  max-width: 400px;
  border: 1px blue;
}

/* detailed comments title when not in edit mode */
.detailed-comments-title-no-edit {
  border-style: none;
  background-color: initial;
}

/* matching form-control:focus and form-control */
.detailed-comments-title-no-edit:hover {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  background-color: inherit;

}

/* detailed comments in edit */
.detailed-comments-title-input {
    width: 400px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 0.5em;
}

.detailed-comments-comment-input {
  width: 300px;
}

.detailed-comments-comment-list-text {
  font-size: 12px;
}

.detailed-comments-list {
  width: available;
  height: 500px;
  overflow-y: auto;
}

.detailed-comments-item-width {
  min-width: 300px
}

.detailed-comments-list-timeline-width {
  min-width: 70px
}

.detailed-comments-list-action-width {
  min-width: 70px
}

.detailed-comments-align-right {
  text-align: right;
}

.detailed-comments-saved {
    color: var(--ph-blue);
    text-align: right;
    width: available;
}

.detailed-comments-unsaved {
    color: red;
    text-align: right;
    width: available;
}

.detailed-comments-timestamp-changed {
  color: orange;
}

.status-ok {
  color: green;
}

.status-failed {
  color: red;
}

.status-none {
}

